<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('usageInstructions.title') }}</li>
        </Breadcrumb>
        <div class="container mb">
            <div class="row">
                <div class="col-12">
                    <h1 class="mb h1">
                        {{ $t('usageInstructions.title') }}
                    </h1>
                    <p class="mb">
                        {{ $t('usageInstructions.welcomeMessage') }}
                    </p>
                    <h3 class="h3">
                        {{ $t('usageInstructions.noGuarantee') }}
                    </h3>
                    <p>{{ $t('usageInstructions.noGuaranteeContent') }}</p>
                    <h3 class="h3">
                        {{ $t('usageInstructions.copyright') }}
                    </h3>
                    <p class="mb">
                        {{ $t('usageInstructions.copyrightContent') }}
                    </p>
                    <p class="mb">
                        {{ $t('usageInstructions.trademarks') }}
                    </p>
                    <p class="mb">
                        {{ $t('usageInstructions.copyrightNotice') }}
                    </p>
                    <h3 class="h3">
                        {{ $t('usageInstructions.secureTransmission') }}
                    </h3>
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        class="mb"
                        v-html="$t('usageInstructions.secureTransmissionContent')"
                    />
                    <h3 class="h3">
                        {{ $t('usageInstructions.legalValidity') }}
                    </h3>
                    <p class="mb">
                        {{ $t('usageInstructions.legalValidityContent') }}
                    </p>
                </div>
            </div>
        </div>
        <Contact />
    </div>
</template>

<script setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Contact from '@/components/Teaser/Contact.vue'

const id = 'portal.mc.usage-instructions'
</script>
