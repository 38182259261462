
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'about-creditreform' }">
                    {{ $t('menu.servicemenu.about.title') }}
                </router-link>
            </li>
            <li>{{ $t('reasons.title') }}</li>
        </Breadcrumb>

        <div class="container mt-4">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.1') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.1') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/cloud.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.2') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.2') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/map.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.3') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.3') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/handshake.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.4') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.4') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/man.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.5') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.5') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/chair.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.6') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.6') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/medal.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.7') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.7') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/star.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.8') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.8') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/euro.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.9') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.9') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/shield.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="row card-body mb">
                            <h3 class="card-title col-sm-12">
                                {{ $t('reasons.forCrefo.10') }}
                            </h3>
                            <div class="col-sm-8">
                                <p class="card-text">
                                    {{ $t('reasons.texts.10') }}
                                </p>
                            </div>
                            <img
                                src="@/assets/img/icons/vcard.svg"
                                alt="Custom Icon"
                                class="col-xs-4"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Contact />
    </div>
</template>

<script setup lang="ts">
import Contact from '@/components/Teaser/Contact.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.reasons-for-creditreform'

</script>

<style scoped>

</style>
