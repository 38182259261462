<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'report' }">
                    {{ $t('report.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('reportPerson.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="content-spacer">
            <div
                class="bg-brand-light-grey box-spacing"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ $t('reportPerson.title') }}</h3>
                            <p>{{ $t('reportPerson.text1') }}</p>
                            <h4>
                                <b>{{ $t('reportPerson.text2') }}<a
                                    href="https://consumer.boniversum.com/sso"
                                    title="Boniversum"
                                    target="_blank"
                                >&nbsp;{{ $t('reportPerson.linkText') }}</a>.</b>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container content-spacer">
                <div class="row">
                    <div class="col-12">
                        <h3>{{ $t('reportPerson.search') }}</h3>
                    </div>
                </div>
            </div>

            <div
                class="bg-brand-light-grey box-spacing"
            >
                <OrderForm />
            </div>

            <div class="content-spacer">
                <div
                    class="bg-brand-light-grey box-spacing"
                >
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div v-html="$t('reportPerson.dsgvo')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-spacer">
                <div
                    class="bg-brand-light-grey box-spacing"
                >
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="box-shadow-xy bg-white small-box-spacing mb mt">
                                    <h3>{{ $t('reportPerson.footerBoxes.1.title') }}</h3>
                                    <p class="mb-small">
                                        {{ $t('reportPerson.footerBoxes.1.description') }}
                                    </p>
                                    <router-link :to="{ name: 'contact' }">
                                        {{ $t('reportPerson.footerBoxes.1.linkText') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="box-shadow-xy bg-white small-box-spacing mb mt">
                                    <h3>{{ $t('reportPerson.footerBoxes.2.title') }}</h3>
                                    <p class="mb-small">
                                        {{ $t('reportPerson.footerBoxes.2.description') }}
                                    </p>
                                    <router-link :to="{ name: 'help-consumer' }">
                                        {{ $t('reportPerson.footerBoxes.2.linkText') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="box-shadow-xy bg-white small-box-spacing mb mt">
                                    <h3>{{ $t('reportPerson.footerBoxes.3.title') }}</h3>
                                    <p class="mb-small">
                                        {{ $t('reportPerson.footerBoxes.3.description') }}
                                    </p>
                                    <a
                                        href="https://consumer.boniversum.com/sso"
                                        target="_blank"
                                    >
                                        {{ $t('reportPerson.footerBoxes.3.linkText') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import OrderForm from './ReportPerson/OrderForm.vue'

const id = 'portal.mc.report-person'

</script>
