<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.new-navigation'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('NewNavigation.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c7213"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-7213"
                    >
                        <div
                            id="toc7213"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-8669"
                                        class="toc__"
                                        href="#c7215"
                                        @click.prevent="scrollTo('#c7215')"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7217"
                                                href="#c7217"
                                                @click.prevent="scrollTo('#c7217')"
                                            >
                                                {{ $t('NewNavigation.sections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7219"
                                                href="#c7219"
                                                @click.prevent="scrollTo('#c7219')"
                                            >
                                                {{ $t('NewNavigation.sections.2.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7221"
                                                href="#c7221"
                                                @click.prevent="scrollTo('#c7221')"
                                            >
                                                {{ $t('NewNavigation.sections.3.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7223"
                                                href="#c7223"
                                                @click.prevent="scrollTo('#c7223')"
                                            >
                                                {{ $t('NewNavigation.sections.4.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7227"
                                                href="#c7227"
                                                @click.prevent="scrollTo('#c7227')"
                                            >
                                                {{ $t('NewNavigation.sections.4.subsections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7229"
                                                href="#c7229"
                                                @click.prevent="scrollTo('#c7229')"
                                            >
                                                {{ $t('NewNavigation.sections.4.subsections.2.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7231"
                                                href="#c7231"
                                                @click.prevent="scrollTo('#c7231')"
                                            >
                                                {{ $t('NewNavigation.sections.4.subsections.3.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7233"
                                                href="#c7233"
                                                @click.prevent="scrollTo('#c7233')"
                                            >
                                                {{ $t('NewNavigation.sections.4.subsections.4.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7235"
                                                href="#c7235"
                                                @click.prevent="scrollTo('#c7235')"
                                            >
                                                {{ $t('NewNavigation.sections.4.subsections.5.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7225"
                                                href="#c7225"
                                                @click.prevent="scrollTo('#c7225')"
                                            >
                                                {{ $t('NewNavigation.sections.5.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7237"
                                                href="#c7237"
                                                @click.prevent="scrollTo('#c7237')"
                                            >
                                                {{ $t('NewNavigation.sections.6.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7239"
                                                href="#c7239"
                                                @click.prevent="scrollTo('#c7239')"
                                            >
                                                {{ $t('NewNavigation.sections.7.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-7231"
                                        class="toc__"
                                        href="#c7231"
                                        @click.prevent="scrollTo('#c7231')"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c7215"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-7215"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h1 />
                                        <div
                                            id="c7217"
                                            class="ce-box   box"
                                            data-qa="ce-7217"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3>{{ $t('NewNavigation.sections.1.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <i18n-t
                                                        tag="p"
                                                        keypath="NewNavigation.sections.1.text"
                                                    >
                                                        <b>{{ $t('NewNavigation.sections.1.b') }}</b>
                                                    </i18n-t>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7219"
                                            class="ce-box   box"
                                            data-qa="ce-7219"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('NewNavigation.sections.2.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext" />
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/newNavigation/NN_Dashboard.png"
                                                                            width="914"
                                                                            height="469"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7221"
                                            class="ce-box   box"
                                            data-qa="ce-7221"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('NewNavigation.sections.3.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('NewNavigation.sections.3.text') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7223"
                                            class="ce-box   box"
                                            data-qa="ce-7223"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3>{{ $t('NewNavigation.sections.4.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <i18n-t
                                                        tag="p"
                                                        keypath="NewNavigation.sections.4.text"
                                                    >
                                                        <b>{{ $t('NewNavigation.sections.4.b') }}</b>
                                                    </i18n-t>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7227"
                                            class="ce-box   box"
                                            data-qa="ce-7227"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h4>{{ $t('NewNavigation.sections.4.subsections.1.title') }}</h4>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <i18n-t
                                                            tag="li"
                                                            keypath="NewNavigation.sections.4.subsections.1.text"
                                                        >
                                                            <b>{{ $t('NewNavigation.sections.4.subsections.1.b') }}</b>
                                                        </i18n-t>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7229"
                                            class="ce-box   box"
                                            data-qa="ce-7229"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h4>{{ $t('NewNavigation.sections.4.subsections.2.title') }}</h4>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('NewNavigation.sections.4.subsections.2.text') }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7231"
                                            class="ce-box   box"
                                            data-qa="ce-7231"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h4>{{ $t('NewNavigation.sections.4.subsections.3.title') }}</h4>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('NewNavigation.sections.4.subsections.3.text') }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7233"
                                            class="ce-box   box"
                                            data-qa="ce-7233"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h4>{{ $t('NewNavigation.sections.4.subsections.4.title') }}</h4>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('NewNavigation.sections.4.subsections.4.text') }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7235"
                                            class="ce-box   box"
                                            data-qa="ce-7235"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h4>{{ $t('NewNavigation.sections.4.subsections.5.title') }}</h4>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <i18n-t
                                                            tag="li"
                                                            keypath="NewNavigation.sections.4.subsections.5.text"
                                                        >
                                                            <i>{{ $t('NewNavigation.sections.4.subsections.5.i') }}</i>
                                                            <b>{{ $t('NewNavigation.sections.4.subsections.5.b') }}</b>
                                                        </i18n-t>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7225"
                                            class="ce-box   box"
                                            data-qa="ce-7225"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('NewNavigation.sections.5.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('NewNavigation.sections.5.ps.1') }}</p>
                                                    <i18n-t
                                                        tag="p"
                                                        keypath="NewNavigation.sections.5.ps.2"
                                                    >
                                                        <b>{{ $t('NewNavigation.sections.5.bs.1') }}</b>
                                                        <b>{{ $t('NewNavigation.sections.5.bs.2') }}</b>
                                                        <b>{{ $t('NewNavigation.sections.5.bs.3') }}</b>
                                                        <b>{{ $t('NewNavigation.sections.5.bs.4') }}</b>
                                                        <b>{{ $t('NewNavigation.sections.5.bs.5') }}</b>
                                                    </i18n-t>
                                                </div>
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/newNavigation/NN_Aufklappmenu.png"
                                                                            width="495"
                                                                            height="381"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7237"
                                            class="ce-box   box"
                                            data-qa="ce-7237"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3><b>{{ $t('NewNavigation.sections.6.title') }}</b></h3>
                                                </div>
                                                <div class="ce-textpic__bodytext" />
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/newNavigation/AN_Dashboard.png"
                                                                            width="900"
                                                                            height="436"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c7239"
                                            class="ce-box   box"
                                            data-qa="ce-7239"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('NewNavigation.sections.7.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext" />
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/newNavigation/NN_mobile_Ansicht.png"
                                                                            width="300"
                                                                            height="425"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding-bottom: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic {
    display: block;
    overflow: hidden;
    &.ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;
            .ce-textpic__media {
                padding: 20px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .ce-textpic__bodytext .list-unordered {
            margin-bottom: 0;
        }
    }
}
</style>
