<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.member-navigation'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>  {{ $t('memberNavigation.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c6770"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-6770"
                    >
                        <div
                            id="toc6770"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-6788"
                                        class="toc__"
                                        href="/hilfe/navigation-fuer-verbundene-unternehmen/#c6788"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6774"
                                                href="/hilfe/navigation-fuer-verbundene-unternehmen/#c6774"
                                                @click.prevent="scrollTo('#c6774')"
                                            >
                                                {{ $t('memberNavigation.sections.1.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6776"
                                                href="/hilfe/navigation-fuer-verbundene-unternehmen/#c6776"
                                                @click.prevent="scrollTo('#c6776')"
                                            >
                                                {{ $t('memberNavigation.sections.2.title') }}

                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6778"
                                                href="/hilfe/navigation-fuer-verbundene-unternehmen/#c6778"
                                                @click.prevent="scrollTo('#c6778')"
                                            >
                                                {{ $t('memberNavigation.sections.3.title') }}

                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6780"
                                                href="/hilfe/navigation-fuer-verbundene-unternehmen/#c6780"
                                                @click.prevent="scrollTo('#c6780')"
                                            >
                                                {{ $t('memberNavigation.sections.4.title') }}

                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6782"
                                                href="/hilfe/navigation-fuer-verbundene-unternehmen/#c6782"
                                                @click.prevent="scrollTo('#c6782')"
                                            >
                                                {{ $t('memberNavigation.sections.5.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-6770"
                                        class="toc__"
                                        href="/hilfe/navigation-fuer-verbundene-unternehmen/#c6770"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c6788"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-6788"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />

                                        <div
                                            id="c6774"
                                            class="ce-box   box"
                                            data-qa="ce-6774"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/memberNavigation/csm_Unternehmensnavigation_e5b06f374e.png"
                                                                    width="180"
                                                                    height="180"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <div class="ce-header">
                                                        <h3>{{ $t('memberNavigation.sections.1.title') }}</h3>
                                                    </div>

                                                    <div v-html="$t('memberNavigation.sections.1.text')" />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c6776"
                                            class="ce-box   box"
                                            data-qa="ce-6776"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('memberNavigation.sections.2.title') }}</h3>
                                                </div>

                                                <div v-html="$t('memberNavigation.sections.2.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c6778"
                                            class="ce-box   box"
                                            data-qa="ce-6778"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('memberNavigation.sections.3.title') }}</h3>
                                                </div>
                                                <div v-html="$t('memberNavigation.sections.3.text')" />

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/memberNavigation/Struktur-Navigation.png"
                                                                            width="774"
                                                                            height="641"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c6780"
                                            class="ce-box   box"
                                            data-qa="ce-6780"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('memberNavigation.sections.4.title') }}</h3>
                                                </div>

                                                <div v-html="$t('memberNavigation.sections.4.text')" />

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/memberNavigation/Fachliches_Recht.png"
                                                                            width="502"
                                                                            height="271"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c6782"
                                            class="ce-box   box"
                                            data-qa="ce-6782"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('memberNavigation.sections.5.title') }}</h3>
                                                </div>

                                                <div v-html="$t('memberNavigation.sections.5.text')" />
                                                <h3>
                                                    <router-link
                                                        :to="{ name: 'contact' }"
                                                        title="Kontakt aufnehmen"
                                                        class="internal-link"
                                                    >
                                                        {{ $t('memberNavigation.sections.6.linkText') }}
                                                    </router-link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic {
    display: block;
    overflow: hidden;
    &.ce-textpic--right {
        &.ce-textpic--intext .ce-textpic__media {
            max-width: 100%;
        }
        &.ce-textpic--nowrap {
            .ce-textpic__media {
                padding: 0 20px 20px;
                max-width: 100%;
            }
            .ce-textpic__bodytext {
                display: table;
            }
        }
        .ce-textpic__gallery {
            float: right;
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
            .ce-textpic__media {
                float: right;
                padding-left: 20px;
                padding-bottom: 20px;
                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
            }
        }
    }
    &.ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;
            .ce-textpic__media {
                padding: 20px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>
