<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import Accordion from '@/components/Accordion/Accordion.vue'
import Tab from '@/components/Tabs/Tab.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import { getFileUrlHelpConsumer } from '@/helper/file'

const id = 'portal.mc.help.consumer'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('Consumer.breadcrumb') }}</li>
        </Breadcrumb>

        <div
            id="c4027"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-4027"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c4033"
                            class="ce-box   box"
                            data-qa="ce-4033"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('Consumer.sections.1.text') }}</p>
                                </div>
                            </div>
                        </div>
                        <Accordion id="accordion4029">
                            <AccordionItem
                                :item-key="1"
                                :title="$t('Consumer.sections.2.accordion.items.1.title')"
                            >
                                <i18n-t
                                    tag="p"
                                    keypath="Consumer.sections.2.accordion.items.1.content.ps.1"
                                >
                                    <b>{{ $t('Consumer.sections.2.accordion.items.1.content.bs.1') }}</b>
                                    <b>{{ $t('Consumer.sections.2.accordion.items.1.content.bs.2') }}</b>
                                </i18n-t>
                                <i18n-t
                                    tag="p"
                                    keypath="Consumer.sections.2.accordion.items.1.content.ps.2"
                                >
                                    <i>{{ $t('Consumer.sections.2.accordion.items.1.content.i') }}</i>
                                    <b>{{ $t('Consumer.sections.2.accordion.items.1.content.bs.3') }}</b>
                                    <b>{{ $t('Consumer.sections.2.accordion.items.1.content.bs.4') }}</b>
                                </i18n-t>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="2"
                                :title="$t('Consumer.sections.2.accordion.items.2.title')"
                            >
                                <i18n-t
                                    tag="p"
                                    keypath="Consumer.sections.2.accordion.items.2.content.ps.1"
                                >
                                    <b>{{ $t('Consumer.sections.2.accordion.items.2.content.bs.1') }}</b>
                                </i18n-t>
                                <ul class="list-unordered">
                                    <li>{{ $t('Consumer.sections.2.accordion.items.2.content.ul.lis.1') }}</li>
                                    <li>{{ $t('Consumer.sections.2.accordion.items.2.content.ul.lis.2') }}</li>
                                    <li>{{ $t('Consumer.sections.2.accordion.items.2.content.ul.lis.3') }}</li>
                                </ul>
                                <i18n-t
                                    tag="p"
                                    keypath="Consumer.sections.2.accordion.items.2.content.ps.2"
                                >
                                    <b>{{ $t('Consumer.sections.2.accordion.items.2.content.bs.2') }}</b>
                                </i18n-t>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="3"
                                :title="$t('Consumer.sections.2.accordion.items.3.title')"
                            >
                                <i18n-t
                                    tag="p"
                                    keypath="Consumer.sections.2.accordion.items.3.content.ps.1"
                                >
                                    <b>
                                        <a 
                                            :href="getFileUrlHelpConsumer('2020-12_Das_berechtigte_Interesse.pdf')"
                                            target="_blank"
                                        >
                                            {{ $t('Consumer.sections.2.accordion.items.3.content.link') }}
                                        </a>
                                    </b>
                                </i18n-t>
                                <p>{{ $t('Consumer.sections.2.accordion.items.3.content.ps.2') }}</p>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="4"
                                :title="$t('Consumer.sections.2.accordion.items.4.title')"
                            >
                                <p>{{ $t('Consumer.sections.2.accordion.items.4.content.ps.1') }}</p>
                                <i18n-t
                                    tag="p"
                                    keypath="Consumer.sections.2.accordion.items.4.content.ps.2"
                                >
                                    <a
                                        href="/portal/mc/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/"
                                        onclick="singleSpaNavigate(event)"
                                        class="internal-link"
                                    >
                                        {{ $t('Consumer.sections.2.accordion.items.4.content.link') }}
                                    </a>
                                    <b>{{ $t('Consumer.sections.2.accordion.items.4.content.b') }}</b>
                                </i18n-t>
                            </AccordionItem>
                        </Accordion>
                        <div
                            id="c4049"
                            class=" clearfix grid-box grid-box--tabContainer box content-spacer"
                            data-qa="grid-box-4049"
                        >
                            <div class="ce-header">
                                <h3><b>{{ $t('Consumer.sections.3.title') }}</b></h3>
                            </div>
                            <Tabs
                                :id="id + '.anchor-links'"
                                selected-tab="tab-0"
                            >
                                <Tab
                                    id="tab-0"
                                    :title="$t('Consumer.sections.3.tabs.1.anchor')"
                                >
                                    <div
                                        id="tab4055"
                                        class="ce-tabs__content__pane"
                                    >
                                        <div
                                            id="c4053"
                                            class="ce-box   box"
                                            data-qa="ce-4053"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.1') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.2') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.3') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.4') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.5') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.6') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.7') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.1.ul.lis.8') }}</li>
                                                    </ul>
                                                    <h4>
                                                        <a
                                                            :href="getFileUrlHelpConsumer('2024-11-26_CONSUMER-Kompakt_Musterauskunft.pdf')"
                                                            target="_blank"
                                                            title="Initiates file download"
                                                            class="download"
                                                        >
                                                            {{ $t('Consumer.sections.3.tabs.1.link') }}
                                                        </a>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    id="tab-1"
                                    :title="$t('Consumer.sections.3.tabs.2.anchor')"
                                >
                                    <div
                                        id="tab4057"
                                        class="ce-tabs__content__pane"
                                    >
                                        <div
                                            id="c4059"
                                            class="ce-box box"
                                            data-qa="ce-4059"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('Consumer.sections.3.tabs.2.ul.lis.1') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.2.ul.lis.2') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.2.ul.lis.3') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.2.ul.lis.4') }}</li>
                                                    </ul>
                                                    <h4>
                                                        <a
                                                            :href="getFileUrlHelpConsumer('2024-11-26_CONSUMER-Plus_Musterauskunft.pdf')"
                                                            target="_blank"
                                                            title="Initiates file download"
                                                            class="download"
                                                        >
                                                            {{ $t('Consumer.sections.3.tabs.2.link') }}
                                                        </a>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    id="tab-2"
                                    :title="$t('Consumer.sections.3.tabs.3.anchor')"
                                >
                                    <div
                                        id="tab4061"
                                        class="ce-tabs__content__pane"
                                    >
                                        <div
                                            id="c4063"
                                            class="ce-box box"
                                            data-qa="ce-4063"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('Consumer.sections.3.tabs.3.ul.lis.1') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.3.ul.lis.2') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.3.ul.lis.3') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.3.ul.lis.4') }}</li>
                                                        <li>{{ $t('Consumer.sections.3.tabs.3.ul.lis.5') }}</li>
                                                    </ul>
                                                    <h4>
                                                        <a
                                                            :href="getFileUrlHelpConsumer('2024-11-26_CONSUMER-Check_Musterauskunft.pdf')"
                                                            target="_blank"
                                                            title="Initiates file download"
                                                            class="download"
                                                        >
                                                            {{ $t('Consumer.sections.3.tabs.3.link') }}
                                                        </a>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c4069"
            class=" clearfix grid-box grid-box--3cols box box--pearl"
            data-qa="grid-box-4069"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div
                            id="c4077"
                            class="ce-box box box--white"
                            data-qa="ce-4077"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('Consumer.sections.4.boxes.1.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('Consumer.sections.4.boxes.1.text') }}</p>
                                    <p>
                                        <a
                                            href="/portal/mc/ueber-creditreform/datenquellen/"
                                            onclick="singleSpaNavigate(event)"
                                            class="internal-link"
                                        >
                                            {{ $t('Consumer.sections.4.boxes.1.link') }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div
                            id="c4073"
                            class="ce-box  box box--white"
                            data-qa="ce-4073"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('Consumer.sections.4.boxes.2.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('Consumer.sections.4.boxes.2.text') }}</p>
                                    <p>
                                        <a
                                            href="/portal/inkasso-cti"
                                            onclick="singleSpaNavigate(event)"
                                            class="internal-link"
                                        ><br>{{ $t('Consumer.sections.4.boxes.2.link') }}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div
                            id="c4075"
                            class="ce-box  box box--white"
                            data-qa="ce-4075"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('Consumer.sections.4.boxes.3.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('Consumer.sections.4.boxes.3.text') }}</p>
                                    <p>
                                        <a
                                            href="http://www.boniversum.de"
                                            title="Boniversum"
                                            target="_blank"
                                            class="external-link-new-window"
                                        >
                                            {{ $t('Consumer.sections.4.boxes.3.link') }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
}
.grid-box {
    display: block;
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--right {
    img {
        max-width: 100%;
        height: auto;
    }
}

.box--pearl {
    background-color: #edece5;
}
.box--white {
    background-color: #fff;
}

b {
    color: var(--color-c-s3);
}

</style>
