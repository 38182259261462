<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.inbox'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('help.5') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c8678"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-8678"
                    >
                        <div
                            id="toc8678"
                            class="toc fixedsticky"
                            data-bind="descendantsComplete: function() {window.bindWillow('Sticky');}"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-8687"
                                        class="toc__"
                                        href="/hilfe/posteingang/#c8687"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-8681"
                                                href="/hilfe/posteingang/#c8681"
                                                @click.prevent="scrollTo('#c8681')"
                                            >
                                                {{ $t('helpInbox.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-8682"
                                                href="/hilfe/posteingang/#c8682"
                                            />
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-8678"
                                        class="toc__"
                                        href="/hilfe/posteingang/#c8678"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c8687"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-8687"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c8681"
                                            class="ce-box   box"
                                            data-qa="ce-8681"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                                <div class="ce-textpic__bodytext">
                                                    <div class="ce-header">
                                                        <h3>{{ $t('helpInbox.title') }}</h3>
                                                    </div>
                                                    <i18n-t
                                                        keypath="helpInbox.ps.1.text"
                                                        tag="p"
                                                    >
                                                        <i>{{ $t('helpInbox.ps.1.i') }}</i>
                                                    </i18n-t>
                                                    <p>{{ $t('helpInbox.ps.2') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c8682"
                                            class="ce-box   box"
                                            data-qa="ce-8682"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="2"
                                                    data-ce-images="2"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/inbox/Posteingang_1.png"
                                                                            width="966"
                                                                            height="833"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>

                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/inbox/Posteingang_2.png"
                                                                            width="911"
                                                                            height="397"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p><b>{{ $t('helpInbox.enumeration.titles.1') }}</b></p>
                                                    <p>{{ $t('helpInbox.enumeration.texts.1') }}</p>

                                                    <p><b>{{ $t('helpInbox.enumeration.titles.2') }}</b></p>
                                                    <p>{{ $t('helpInbox.enumeration.texts.2') }}</p>

                                                    <p><b>{{ $t('helpInbox.enumeration.titles.3') }}</b></p>
                                                    <p>
                                                        {{ $t('helpInbox.enumeration.texts.3.text') }}
                                                        <ul class="list-unordered">
                                                            <li>{{ $t('helpInbox.enumeration.texts.3.ul.lis.1') }}</li>
                                                            <li>{{ $t('helpInbox.enumeration.texts.3.ul.lis.2') }}</li>
                                                            <li>{{ $t('helpInbox.enumeration.texts.3.ul.lis.3') }}</li>
                                                            <li>{{ $t('helpInbox.enumeration.texts.3.ul.lis.4') }}</li>
                                                        </ul>
                                                    </p>

                                                    <p><b>{{ $t('helpInbox.enumeration.titles.4') }}</b></p>
                                                    <p>{{ $t('helpInbox.enumeration.texts.4') }}</p>

                                                    <p><b>{{ $t('helpInbox.enumeration.titles.5') }}</b></p>
                                                    <p>{{ $t('helpInbox.enumeration.texts.5') }}</p>

                                                    <p><b>{{ $t('helpInbox.enumeration.titles.6') }}</b></p>
                                                    <p>{{ $t('helpInbox.enumeration.texts.6') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--center {
    .ce-textpic__gallery {
        text-align: center;
    }
    .ce-textpic__media {
        padding: 20px;
        iframe {
            max-width: 100%;
            height: auto;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right.ce-textpic--nowrap {
    .ce-textpic__bodytext {
        display: table;
    }
    .ce-textpic__media {
        padding: 0 20px 20px;
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
}
b {
    color: var(--color-c-s3);
}
</style>
