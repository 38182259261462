<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import Hero from '@/components/Hero.vue'
import Tabs from '@/components//Tabs/Tabs.vue'
import Tab from '@/components//Tabs/Tab.vue'
import Contact from '@/components/Teaser/Contact.vue'
import { ref } from 'vue'
import Accordion from '@/components/Accordion/Accordion.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'

import heroImage from '@/assets/img/myCrefo/pinnwand_kork_klein_transpa.jpeg'
import heroImageMobile from '@/assets/img/myCrefo/pinnwand_kork_klein_transpa.jpeg'

const id = 'portal.mc.about-creditreform.my-crefo'

let isMobile = ref(window.innerWidth < 768)

window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768
})

</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'about-creditreform' }">
                    {{ $t('menu.servicemenu.about.title') }}
                </router-link>
            </li>
            <li>{{ $t('MyCrefo.breadcrumbTitle') }}</li>
        </Breadcrumb>

        <Hero
            :image="heroImage"
            :mobile-image="heroImageMobile"
        >
            <div class="stage__text stage__text--bottom-left">
                <h2 class="stage__subHeadline stage__subHeadline--bottom-left">
                    {{ $t('MyCrefo.hero') }}
                </h2>
            </div>
        </Hero>
        <div
            id="c959"
            class=" clearfix grid-box grid-box--2cols7525"
            data-qa="grid-box-959"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <div
                            id="c938"
                            data-qa="ce-938"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('MyCrefo.communication.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>
                                        <em class="blue-recursive-text">{{ $t('MyCrefo.communication.text1.quote') }}</em>
                                    </p>
                                    <p>
                                        {{ $t('MyCrefo.communication.text1.text') }}
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!isMobile"
                        class="col-12 col-md-4 textpic-box"
                    >
                        <div
                            id="c1024"
                            data-qa="ce-1024"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--above">
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/myCrefo/platzhalter.png"
                                                    width="600"
                                                    height="400"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-8">
                        <div
                            id="c958"
                            data-qa="ce-958"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                <div class="ce-textpic__bodytext">
                                    <p>
                                        <em class="blue-recursive-text">{{ $t('MyCrefo.communication.text2.quote') }}</em>
                                    </p>
                                    <p class="communication-text">
                                        {{ $t('MyCrefo.communication.text2.text') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 textpic-box">
                        <div
                            id="c1023"
                            data-qa="ce-1023"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--below">
                                <div class="ce-textpic__bodytext" />

                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/myCrefo/success.svg"
                                                    width="512"
                                                    height="512"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <Tabs
                        :id="id + '.anchor-links'"
                        selected-tab="tab-0"
                    >
                        <Tab
                            id="tab-0"
                            :title="$t('MyCrefo.tabs.topicality.title')"
                        >
                            <p>{{ $t('MyCrefo.tabs.topicality.content') }}</p>
                        </Tab>
                        <Tab
                            id="tab-1"
                            :title="$t('MyCrefo.tabs.transparency.title')"
                        >
                            <p>{{ $t('MyCrefo.tabs.transparency.content') }}</p>
                        </Tab>
                        <Tab
                            id="tab-2"
                            :title="$t('MyCrefo.tabs.participation.title')"
                        >
                            <p>{{ $t('MyCrefo.tabs.participation.content') }}</p>
                        </Tab>
                        <Tab
                            id="tab-3"
                            :title="$t('MyCrefo.tabs.correction.title')"
                        >
                            <p>{{ $t('MyCrefo.tabs.correction.content') }}</p>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>

        <div
            id="c933"
            class=" clearfix grid-box grid-box--stageLeft"
            data-qa="grid-box-933"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-xs-12 col-lg-12">
                        <div
                            id="c948"
                            data-qa="ce-948"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-textpic__bodytext">
                                    <h3>{{ $t('MyCrefo.tips.title') }}</h3>
                                    <p>{{ $t('MyCrefo.tips.subtitle') }}</p>
                                </div>
                            </div>
                        </div>
                        <Accordion>
                            <AccordionItem
                                :item-key="1"
                                :title="$t('MyCrefo.tips.accordion.demands.title')"
                            >
                                <ul class="list-unordered">
                                    <li>{{ $t('MyCrefo.tips.accordion.demands.lis.li1') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.demands.lis.li2') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.demands.lis.li3') }}</li>
                                </ul>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="2"
                                :title="$t('MyCrefo.tips.accordion.downPayments.title')"
                            >
                                <ul class="list-unordered">
                                    <li>{{ $t('MyCrefo.tips.accordion.downPayments.lis.li1') }}</li>
                                </ul>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="3"
                                :title="$t('MyCrefo.tips.accordion.liquidity.title')"
                            >
                                <ul class="list-unordered">
                                    <li>{{ $t('MyCrefo.tips.accordion.liquidity.lis.li1') }}</li>
                                </ul>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="4"
                                :title="$t('MyCrefo.tips.accordion.equity.title')"
                            >
                                <ul class="list-unordered">
                                    <li>{{ $t('MyCrefo.tips.accordion.equity.lis.li1') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.equity.lis.li2') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.equity.lis.li3') }}</li>
                                </ul>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="5"
                                :title="$t('MyCrefo.tips.accordion.liabilities.title')"
                            >
                                <ul class="list-unordered">
                                    <li>{{ $t('MyCrefo.tips.accordion.liabilities.lis.li1') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.liabilities.lis.li2') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.liabilities.lis.li3') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.liabilities.lis.li4') }}</li>
                                    <li>{{ $t('MyCrefo.tips.accordion.liabilities.lis.li5') }}</li>
                                </ul>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
        <Contact />
    </div>
</template>

<style scoped lang="less">
@break-mobile: ~"screen and (max-width: 767px)";

.grid-box {
    margin: 3rem 0 2rem;
    display: block;
}

.textpic-box {
    margin: auto 0;
}

.ce-textpic__gallery {
    float: none;
    max-width: 100%;
    .ce-textpic__media {
        float: none;
        display: block;
        padding: 20px 0;
        max-width: 100%;
    }
}

.blue-recursive-text {
    color: #61ace3;
}

.stage__text--bottom-left {
    bottom: 1em;
    text-align: left;
}

.stage__text {
    position: absolute;
    @media @break-mobile {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 3rem;
    top: inherit;
    left: inherit;
    bottom: inherit;
    right: inherit;
}
}

.stage__subHeadline--bottom-left {
    text-align: left;
}

.stage__subHeadline {
    font-size: 26.5px;
    margin-bottom: 0.5em;
}

.communication-text {
    white-space: pre-wrap;
}

.list-unordered {
    margin: 0 !important;
}
</style>
