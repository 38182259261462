import { getFileUrlUDS } from '@/helper/image'

type International = {
    title: string
    subtitle: string
    features: string[]
}

export interface Product {
    type: string
    isDal: boolean
    title: string
    subtitle: string
    description: string
    sampleFile: string
    features: string[]
    isInternational?: boolean
    international?: International
}

export interface TransformedProduct extends Product {
    isInDAL?: boolean
    ordeableHasMonitoring?: boolean
    receivableHasMonitoring?: boolean
    ordeableHasMonitoringPlus?: boolean
    receivableHasMonitoringPlus?: boolean
    orderableHasMonitoringRequired?: boolean
    receivableHasMonitoringRequired?: boolean
    hasToBeOrdered?: boolean
    isSignalProduct?: boolean
    valueordeableHasMonitoring?: boolean
    hasMonitoring?: boolean
    hasMonitoringPlus?: boolean
    hasMonitoringRequired?: boolean
    prohibited?: boolean
    permissionErrorLinkText?: string
    permissionErrorMessage?: string
    recommended?: boolean
}

export const reportProductsDal: Array<Product> = [
    {
        type: 'PREMIUM_REPORT',
        isDal: true,
        title: 'reportProducts.PREMIUM_REPORT.title',
        subtitle: 'reportProducts.PREMIUM_REPORT.subtitle',
        description: 'reportProducts.PREMIUM_REPORT.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-24_MU_OE_DE_Premiumauskunft.pdf'),
        features: [
            '68',
            '65',
            '66',
            '67',
            '69',
            '63'
        ]
    },
    {
        type: 'COMMERCIAL_REPORT',
        isDal: true,
        title: 'reportProducts.COMMERCIAL_REPORT.title',
        subtitle: 'reportProducts.COMMERCIAL_REPORT.subtitle',
        description: 'reportProducts.COMMERCIAL_REPORT.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Wirtschaftsauskunft.pdf'),
        features: [
            '64',
            '25',
            '10',
            '28',
            '53',
            '63',
        ]
    },
    {
        type: 'COMPACT_REPORT',
        isDal: true,
        title: 'reportProducts.COMPACT_REPORT.title',
        subtitle: 'reportProducts.COMPACT_REPORT.subtitle',
        description: 'reportProducts.COMPACT_REPORT.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Kompaktauskunft.pdf'),
        features: [
            '16',
            '19',
            '22',
            '53',
            '43',
        ],
        isInternational: true,
        international: {
            title: '',
            subtitle: '',
            features: [
                '53',
                '83',
                '85',
                '87',
                '89'
            ]
        }
    },
    {
        type: 'BRIEF_REPORT',
        isDal: true,
        title: 'reportProducts.BRIEF_REPORT.title',
        subtitle: 'reportProducts.BRIEF_REPORT.subtitle',
        description: 'reportProducts.BRIEF_REPORT.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Kurzauskunft.pdf'),
        features: [
            '3',
            '43',
            '10',
            '44'
        ]
    },
    {
        type: 'TRAFFIC_LIGHT_REPORT',
        isDal: true,
        title: 'reportProducts.TRAFFIC_LIGHT_REPORT.title',
        subtitle: 'reportProducts.TRAFFIC_LIGHT_REPORT.subtitle',
        description: 'reportProducts.TRAFFIC_LIGHT_REPORT.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Ampelauskunft.pdf'),
        features: [
            '1',
            '2',
            '40',
        ]
    },
    {
        type: 'RISIKO_CHECK',
        isDal: true,
        title: 'reportProducts.RISIKO_CHECK.title',
        subtitle: 'reportProducts.RISIKO_CHECK.subtitle',
        description: 'reportProducts.RISIKO_CHECK.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2022-10-13_MU_OE_DE_RisikoCheck.pdf'),
        features: [
            '370',
            '2',
            '40',
        ]
    },
    {
        type: 'ESG_BASIS_REPORT',
        isDal: true,
        title: 'reportProducts.ESG_BASIS_REPORT.title',
        subtitle: 'reportProducts.ESG_BASIS_REPORT.subtitle',
        description: 'reportProducts.ESG_BASIS_REPORT.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/ESG-Basis-Score-Musterauskunft-20230428.pdf'),
        features: [
            '2',
            '366',
            '368',
            '367',
            '369',
        ]
    },
    {
        type: 'COMPANY_REPORT_PREMIUM',
        isDal: true,
        title: 'reportProducts.COMPANY_REPORT_PREMIUM.title',
        subtitle: 'reportProducts.COMPANY_REPORT_PREMIUM.subtitle',
        description: 'reportProducts.COMPANY_REPORT_PREMIUM.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Unternehmensinfo_Premium.pdf'),
        features: [
            '40',
            '19',
            '63',
            '153',
            '157',
            '161',
        ]
    },
    {
        type: 'COMPANY_REPORT_COMPACT',
        isDal: true,
        title: 'reportProducts.COMPANY_REPORT_COMPACT.title',
        subtitle: 'reportProducts.COMPANY_REPORT_COMPACT.subtitle',
        description: 'reportProducts.COMPANY_REPORT_COMPACT.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Unternehmensinfo_Kompakt.pdf'),
        features: [
            '40',
            '13',
            '153',
            '157',
            '161',
        ]
    },
    {
        type: 'FAMILYTREE_PLUS',
        isDal: true,
        title: 'reportProducts.FAMILYTREE_PLUS.title',
        subtitle: 'reportProducts.FAMILYTREE_PLUS.subtitle',
        description: 'reportProducts.FAMILYTREE_PLUS.description',
        sampleFile: getFileUrlUDS('musterauskuenfte/2024-07-24_MU_OE_DE_Verflechtungsinfo_Plus_MC.pdf'),
        features: [
            '125',
            '135',
            '133',
            '137',
        ],
        isInternational: true,
    },
    // {
    //     type: 'CREFO_IDENT',
    // },
    {
        type: 'REPORT_WITHOUT_SUPPLEMENT',
        isDal: true,
        title: 'reportProducts.REPORT_WITHOUT_SUPPLEMENT.title',
        subtitle: 'reportProducts.REPORT_WITHOUT_SUPPLEMENT.subtitle',
        description: 'reportProducts.REPORT_WITHOUT_SUPPLEMENT.description',
        sampleFile: '',
        features: [
            '64',
            '10',
            '25',
            '28',
            '53',
        ]
    },
    {
        type: 'COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT',
        isDal: true,
        title: 'reportProducts.COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT.title',
        subtitle: 'reportProducts.COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT.subtitle',
        description: 'reportProducts.COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT.description',
        sampleFile: '',
        features: [
            '64',
            '25',
            '10',
            '28',
            '53',
            '372',
        ]
    },
    {
        type: 'COMPANY_REPORT_PREMIUM_MONITORING_WITHOUT_REPORT',
        isDal: true,
        title: 'reportProducts.COMPANY_REPORT_PREMIUM_MONITORING_WITHOUT_REPORT.title',
        subtitle: 'reportProducts.COMPANY_REPORT_PREMIUM_MONITORING_WITHOUT_REPORT.subtitle',
        description: 'reportProducts.COMPANY_REPORT_PREMIUM_MONITORING_WITHOUT_REPORT.description',
        sampleFile: '',
        features: [
            '40',
            '19',
            '372',
            '153',
            '157',
            '161',
        ]
    }
]

export const reportProductsInt: Array<Product> = [
    {
        type: 'COMMERCIAL_REPORT',
        isDal: false,
        title: 'reportProductsInt.COMMERCIAL_REPORT.title',
        subtitle: 'reportProductsInt.COMMERCIAL_REPORT.subtitle',
        description: 'reportProductsInt.COMMERCIAL_REPORT.description',
        sampleFile: getFileUrlUDS('2019-07-03_Muster_Wirtschaftsauskunft_International.pdf'),
        features: [
            '91',
            '93',
            '89',
            '95',
            '97',
        ]
    },
    {
        type: 'COMPACT_REPORT',
        isDal: false,
        title: 'reportProductsInt.COMPACT_REPORT.title',
        subtitle: 'reportProductsInt.COMPACT_REPORT.subtitle',
        description: 'reportProductsInt.COMPACT_REPORT.description',
        sampleFile: getFileUrlUDS('2019-07-03_Muster_Kompaktauskunft_International.pdf'),
        features: [
            '53',
            '83',
            '85',
            '87',
            '89',
        ],
    },
    {
        type: 'BRIEF_REPORT',
        isDal: false,
        title: 'reportProductsInt.BRIEF_REPORT.title',
        subtitle: 'reportProductsInt.BRIEF_REPORT.subtitle',
        description: 'reportProductsInt.BRIEF_REPORT.description',
        sampleFile: getFileUrlUDS('2019-07-03_Muster_Kurzauskunft_International.pdf'),
        features: [
            '2',
            '53',
            '79',
            '81',
        ]
    },
    {
        type: 'COMPANY_REPORT_COMPACT',
        isDal: false,
        title: 'reportProductsInt.COMPANY_REPORT_COMPACT.title',
        subtitle: 'reportProductsInt.COMPANY_REPORT_COMPACT.subtitle',
        description: 'reportProductsInt.COMPANY_REPORT_COMPACT.description',
        sampleFile: getFileUrlUDS('Muster_Unternehmensinfo_Kompakt_International_DE.pdf'),
        features: [
            '40',
            '153',
            '13',
            '157',
        ]
    },
    {
        type: 'COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT',
        isDal: false,
        title: 'reportProducts.COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT.title',
        subtitle: 'reportProducts.COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT.subtitle',
        description: 'reportProducts.COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT.description',
        sampleFile: '',
        features: [
            '64',
            '25',
            '10',
            '28',
            '53',
            '372',
        ]
    },
]

