<!-- eslint-disable vue/no-v-html -->

<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
        class="wkv-confirmation"
    >
        <Breadcrumb>
            <li>{{ $t("wkv.breadcrumb") }}</li>
        </Breadcrumb>
        <Hero
            :image="heroImage"
            :mobile-image="heroImageMobile"
        >
            <div class="row mb-large">
                <div class="col-12 col-md-12 col-xl-12">
                    <div class="new-badge ">
                        NEU
                    </div>
                </div>
                <div class="col-12 col-md-12 col-xl-12">
                    <img
                        src="@/assets/img/wkv/wkv_order_crefoeva_logo.svg"
                        width="370"
                    >
                </div>
                <div class="col-12 col-md-12 col-xl-12">
                    <img
                        src="@/assets/img/wkv/wkv_order_claim.svg"
                        width="460"
                    >
                </div>
            </div>
        </Hero>
        <LoadingRing
            v-if="showLoadingSpinner"
            class="content-spacer"
        />
        <div
            v-if="showConfirmationSection"
        >
            <div
                class="container content-spacer"
            >
                <div class="row text-align--center">
                    <div class="col-12">
                        <h3 data-qa="wkv-order-confirmation-headline">
                            {{ $t('wkv.confirmation.headline') }}
                        </h3>
                        <h4 data-qa="wkv-order-confirmation-subheadline">
                            {{ $t('wkv.confirmation.text') }}
                        </h4>
                    </div>
                </div>
                <div class="row hidden-xs hidden-sm">
                    <div class="col-md-8">
                        <div class="h3">
                            {{ $t('wkv.insurance.complete.headline.0') }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="h3">
                            {{ $t('wkv.insurance.complete.headline.1') }}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-md-4">
                        <div class="col-xs-12 visible-xs visible-sm">
                            <div class="h3">
                                {{ $t('wkv.insurance.complete.headline.0') }}
                            </div>
                        </div>

                        <div class="box-shadow-xy small-box-spacing mb">
                            <div class="col-xs-12">
                                <span>{{ $t('wkv.order.customer') }}</span>
                            </div>
                            <div class="col-xs-12">
                                <span
                                    class="h3 m0"
                                    data-qa="wkv-userdata-givenname"
                                >{{ givenName }}</span>
                            </div>
                            <div class="col-xs-12">
                                <span>{{ $t('wkv.order.insurance.id') }}</span>
                            </div>
                            <div class="col-xs-12 vspace-xs--1 vspace-md--1">
                                <span
                                    class="h3 m0"
                                    data-qa="wkv-confirmation-insurance-number"
                                >{{ wkvOrderId }}</span>
                            </div>
                            <div class="col-xs-12 text-left text-sm-left text-xs-left">
                                <span>{{ $t('wkv.order.insurance.coverage') }}</span>
                            </div>
                            <div class="col-xs-12 vspace-xs--1 vspace-md--1">
                                <span
                                    class="h3 m0"
                                    data-qa="wkv-confirmation-insurance-amount"
                                >{{ coverageAmountText }}</span>
                            </div>
                            <div class="col-xs-12 text-left text-sm-left text-xs-left">
                                <span>{{ $t('wkv.order.insurance.time') }}</span>
                            </div>
                            <div class="col-xs-12 vspace-xs--1 vspace-md--1">
                                <span
                                    class="h3 m0"
                                    data-qa="wkv-confirmation-insurance-time"
                                >{{ coverageTimeText }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="box-shadow-xy small-box-spacing text-semibold">
                            <div class="col-xs-12">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        {{ $t('wkv.order.insurance.price') }}
                                    </span>
                                    <span data-qa="wkv-order-insurance-price-net">
                                        {{ wkvInsurancePriceNet }}
                                    </span>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <span class="hidden-xs">
                                        {{ $t('wkv.order.insurance.tax') }}
                                    </span>
                                    <span
                                        class="visible-xs"
                                        v-html="$t('wkv.order.insurance.tax.mobile')"
                                    />
                                    <span data-qa="wkv-order-insurance-tax">
                                        {{ wkvInsuranceTax }}
                                    </span>
                                </div>
                                <div class="col-xs-12 ">
                                    <hr class="mt mb">
                                </div>
                                <div class="d-flex justify-content-between">
                                    <span>
                                        {{ $t('wkv.order.insurance.totalprice') }}
                                    </span>
                                    <span
                                        data-qa="wkv-order-insurance-price"
                                        class="h3 m0"
                                    >
                                        {{ wkvInsurancePrice }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="col-xs-12 visible-xs visible-sm">
                            <div class="h3">
                                {{ $t('wkv.insurance.complete.headline.1') }}
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="box-shadow-xy small-box-spacing">
                                <DownloadLink
                                    :url="wkvInsuranceInvoiceDownload"
                                    data-qa="wkv-insurance-complete-invoice-downloadButton"
                                    classes="d-flex align-items-center download-button"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-arrow-down-to-line"
                                        size="2x"
                                    />
                                    <div class="text-bold">
                                        <div>{{ $t('wkv.insurance.complete.download.text.2') }}</div>
                                        <div>{{ $t('wkv.insurance.complete.download.text.1') }}</div>
                                    </div>
                                </DownloadLink>
                            </div>
                        </div>
                        <div class="col-xs-12 mt">
                            <div class="box-shadow-xy small-box-spacing">
                                <div class="wkv-insurance-box__details__download--bg-soft-blue">
                                    <DownloadLink
                                        :url="wkvInsuranceConfirmationDownload"
                                        data-qa="wkv-insurance-complete-confirmation-downloadButton"
                                        classes="d-flex align-items-center download-button"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-arrow-down-to-line"
                                            size="2x"
                                        />
                                        <div class="text-bold">
                                            <div>{{ $t('wkv.insurance.complete.download.text.0') }}</div>
                                            <div>{{ $t('wkv.insurance.complete.download.text.1') }}</div>
                                        </div>
                                    </DownloadLink>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 mt">
                            <span>{{ $t('wkv.insurance.complete.mail.info') }}</span>
                        </div>
                    </div>
                </div>
                <div class="row content-spacer">
                    <div class="col-12 text-align--center">
                        <a
                            class="btn btn-secondary btn-icon-prev"
                            data-qa="wkv-insurance-complete-back-button"
                            :href="`${adsLinks[$i18n.locale]}?transactionId=${transactionId}`"
                        >
                            <span>
                                {{ $t('wkv.order.back') }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <div
                v-if="showFeedbackSection"
                id="wkv__calculator__feedback"
                class="bg-brand-light-grey big-box-spacing mt"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-align--center">
                            <div
                                class="h3"
                                data-qa="wkv-order-feedback-headline"
                            >
                                {{ $t('wkv.review.headline') }}
                            </div>
                        </div>
                        <div class="col-12 text-align--center">
                            <div
                                class="h4"
                                data-qa="wkv-order-feedback-subheadline"
                            >
                                {{ $t('wkv.review.subheadline') }}
                            </div>
                        </div>

                        <div class="col-12 col-md-8 offset-md-2 hidden-xs">
                            <div class="row">
                                <div class="col-4">
                                    {{ $t('wkv.review.negative') }}
                                </div>
                                <div class="col-4 text-align--center">
                                    <StarRating v-model="stars" />
                                </div>
                                <div class="col-4 text-align-right">
                                    {{ $t('wkv.review.positive') }}
                                </div>
                            </div>
                        </div>

                        <div class="col-12 visible-xs">
                            <div class="row">
                                <div class="col-12 text-align--center mb">
                                    <StarRating v-model="stars" />
                                </div>
                                <div class="col-6">
                                    {{ $t('wkv.review.negative') }}
                                </div>
                                <div class="col-6">
                                    {{ $t('wkv.review.positive') }}
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-8 offset-md-2 mt">
                            <span class="">
                                {{ $t('wkv.review.improvement') }}

                            </span>
                            <Textarea
                                v-model="feedbacktext"
                                label=""
                                name="wkv-feedbacktext"
                                data-qa="wkv-feedbacktext"
                                placeholder="Ihre Nachricht..."
                            />

                            <div class="col-12 text-align--center">
                                <button
                                    class="btn btn-default"
                                    data-qa="wkv-review-send-button"
                                    name="wkv_reminder_button"
                                    :disabled="(feedbacktext === '' && stars === 0) || isSaving"
                                    @click="onSave"
                                >
                                    {{ $t('wkv.review.submit') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="showFeedbackSectionComplete"
                id="wkv__calculator__feedback--complete"
                class="bg-brand-light-grey big-box-spacing mt"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-align--center">
                            <div
                                class="h3"
                                data-qa="wkv-review-complete-headline"
                            >
                                {{ $t('wkv.review.complete.headline') }}
                            </div>
                        </div>
                        <div class="col-12 col-md-8 offset-md-2 text-align--center mt">
                            <div
                                class="h4"
                                data-qa="wkv-review-complete-subheadline"
                            >
                                {{ $t('wkv.review.complete.subheadline') }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt">
                        <div class="col-12 text-align--center">
                            <a
                                :href="`${adsLinks[$i18n.locale]}?transactionId=${transactionId}`"
                                data-qa="wkv-review-complete-back-button"
                                class="btn btn-secondary btn-icon-prev"
                            >
                                <span>
                                    {{ $t('wkv.order.back') }}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            v-if="errorAlert"
            :id="errorAlert.id"
            :title="errorAlert.title"
            :confirm-text="$t('shared.modals.ok')"
            :error-context="errorAlert.errorContext"
            :icon="errorAlert.icon"
            @on-close="errorAlert = undefined"
            @on-confirm="errorAlert = undefined"
        >
            <p>{{ errorAlert.content }}</p>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Hero from '@/components/Hero.vue'
import heroImage from '@/assets/img/wkv/bestaetigungsseite-desktop.jpg'
import heroImageMobile from '@/assets/img/wkv/bestaetigungsseite-mobil.jpg'
import { useUserStore } from '@/stores/user'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { WKVHelper } from '@/helper/wkv'
import { useRoute } from 'vue-router'
import { getCbraTransaction, getCbraWKVOrder, sendCbraCustomerFeedback } from '@/services'
import { getInsuranceMatrix } from '@/helper/services/WKV'
import { getErrorMessages } from '@/helper/services/error'
import { ModalProps } from '@/types/components/modal'
import Modal from '@/components/Modal.vue'
import Textarea from '@/components/Input/Textarea.vue'
import { scrollTo } from '@/helper/scrollto'
import { CbraTransaction, } from '@/types/cbra/transactions'
import type { CbraWKVOrder } from '@/types/cbra/WKV'
import StarRating from '@/components/StarRating.vue'
import LoadingRing from '@/components/LoadingRing.vue'
import { adsLinks } from '@/data/typo3Links'
import DownloadLink from '@/components/DownloadLink.vue'

const userStore = useUserStore()
const id = 'portal.mc.wkv-confirmation'

const { t } = useI18n()
const route = useRoute()

const errorAlert = ref<ModalProps>()

const showLoadingSpinner =ref(true)
const isSaving = ref(false)
const wkvOrderNo = route.query.orderNo
const transactionId = route.query.transactionId

const showConfirmationSection = ref(false)
const showFeedbackSection = ref(false)
const showFeedbackSectionComplete = ref(false)

const calcMatrix = ref({})

const givenName = ref('')
const wkvOrderId = ref('')

const coverageAmount = ref()
const coverageTime = ref()

const coverageAmountText = computed(() => {
    return coverageAmount.value !== '' ? t('wkv.order.insurance.coverage.' + coverageAmount.value) : ''
})

const coverageTimeText = computed(() => {
    return coverageTime.value !== undefined ? t('wkv.order.insurance.time.' + coverageTime.value) : ''
})

const wkvInsurancePriceNet = computed(() => {
    return WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value , 'netInsurancePremium', calcMatrix.value)
})

const wkvInsurancePrice = computed(() => {
    return WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value, 'grossInsurancePremium', calcMatrix.value)
})

const wkvInsuranceTax = computed(() => {
    return WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value, 'insuranceTax', calcMatrix.value)
})

const wkvInsuranceConfirmationDownload = ref('')
const wkvInsuranceInvoiceDownload = ref('')

// if(this.testWkvPDFError()) {
//     errorService.showModalError({ topic: "WkvService", func: "showPDFError", xhr: { status: 404 } });
// }

const stars = ref(0)
const feedbacktext = ref('')

onMounted(async() => {
    await getWkvData()
})

function showConfirmation(wkvOrders: CbraWKVOrder, transaction: CbraTransaction) {
    givenName.value = transaction.reportTransaction?.businessName || ''
    wkvOrderId.value = wkvOrders.orderRequest.orderNo
    coverageAmount.value = wkvOrders.orderRequest.insuranceSum
    coverageTime.value = wkvOrders.orderRequest.insurancePeriod

    wkvInsuranceConfirmationDownload.value = WKVHelper.getWkvPdf(wkvOrders.orderRequest.orderNo, 'CONFIRMATION')
    wkvInsuranceInvoiceDownload.value = WKVHelper.getWkvPdf(wkvOrders.orderRequest.orderNo, 'INVOICE')

    showConfirmationSection.value = true
    showFeedbackSection.value =true
    showLoadingSpinner.value = false
}

async function onSave() {
    if (stars.value > 0 || feedbacktext.value !== '') {
        isSaving.value = true

        try {
            await sendCbraCustomerFeedback({
                category: 'WKV_ORDER',
                rating: stars.value,
                message: feedbacktext.value
            })
            showFeedbackSection.value = false
            showFeedbackSectionComplete.value = true
            scrollToSection('wkv__calculator__feedback--complete')
        } catch (error) {
            let errorConf = getErrorMessages('wkv.order.notreachable')
            errorAlert.value = {
                id: 'wkv-order-error',
                title: errorConf.headline,
                content: errorConf.description,
                icon: 'warning'
            }
        }
    }
}

async function getWkvData() {
    if (!transactionId || !wkvOrderNo) return
    try {
        const transactionResponse = await getCbraTransaction(transactionId.toString())
        const matrix = await getInsuranceMatrix(transactionResponse.data.reportId)
        calcMatrix.value = matrix
        const orderResponse = await getCbraWKVOrder(wkvOrderNo.toString())
        showConfirmation(orderResponse.data, transactionResponse.data)
    } catch (error) {
        console.error(error)
        let errorConf = getErrorMessages('wkv.order.notreachable')
        errorAlert.value = {
            id: 'wkv-order-error',
            title: errorConf.headline,
            content: errorConf.description,
            icon: 'warning'
        }
    }
}

function scrollToSection(section: string) {
    scrollTo(`#${section}`)
}

</script>

<style scoped lang="less">
    :deep(.new-badge) {
        width: fit-content;
        padding: 5px 10px;
        background-color: #006eb7;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
        font-size: 1.4rem;
    }

    :deep(.download-button) {
        gap: 10px;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
</style>

<style>
.wkv-confirmation .hero__image {
    background-position: top right !important;
}
</style>
