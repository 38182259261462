<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'
import Tab from '@/components/Tabs/Tab.vue'
import Tabs from '@/components/Tabs/Tabs.vue'

const id = 'portal.mc.help.processes-mails'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('ProcessesMails.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c2197"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-2197"
                    >
                        <div
                            id="toc2197"
                            class="toc fixedsticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2208"
                                        class="toc__"
                                        href="/hilfe/vorgaenge-nachrichten/#c2208"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2200"
                                                href="/hilfe/vorgaenge-nachrichten/#c2200"
                                                @click.prevent="scrollTo('#c2200')"
                                            >
                                                {{ $t('ProcessesMails.sections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2207"
                                                href="/hilfe/vorgaenge-nachrichten/#c2207"
                                            />
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2199"
                                                href="/hilfe/vorgaenge-nachrichten/#c2199"
                                                @click.prevent="scrollTo('#c2199')"
                                            >
                                                {{ $t('ProcessesMails.sections.2.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-4205"
                                                href="/hilfe/vorgaenge-nachrichten/#c4205"
                                                @click.prevent="scrollTo('#c4205')"
                                            >
                                                {{ $t('ProcessesMails.sections.3.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2236"
                                                href="/hilfe/vorgaenge-nachrichten/#c2236"
                                                @click.prevent="scrollTo('#c2236')"
                                            >
                                                {{ $t('ProcessesMails.sections.4.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2197"
                                        class="toc__"
                                        href="/hilfe/vorgaenge-nachrichten/#c2197"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c2208"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-2208"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c2200"
                                            class="ce-box   box"
                                            data-qa="ce-2200"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('ProcessesMails.sections.1.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('ProcessesMails.sections.1.text') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Tabs
                                            :id="id + '.anchor-links'"
                                            selected-tab="tab-0"
                                        >
                                            <Tab
                                                id="tab-0"
                                                :title="$t('ProcessesMails.tabs.1.anchor')"
                                            >
                                                <p>{{ $t('ProcessesMails.tabs.1.text') }}</p>
                                                <ul class="list-unordered">
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.1') }}</li>
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.2') }}</li>
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.3') }}</li>
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.4') }}</li>
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.5') }}</li>
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.6') }}</li>
                                                </ul>
                                            </Tab>
                                            <Tab
                                                id="tab-1"
                                                :title="$t('ProcessesMails.tabs.2.anchor')"
                                            >
                                                <p>{{ $t('ProcessesMails.tabs.2.ps.1') }}</p>
                                                <i18n-t
                                                    tag="p"
                                                    keypath="ProcessesMails.tabs.2.ps.2"
                                                >
                                                    <em>{{ $t('ProcessesMails.tabs.2.em') }}</em>
                                                </i18n-t>
                                            </Tab>
                                            <Tab
                                                id="tab-2"
                                                :title="$t('ProcessesMails.tabs.3.anchor')"
                                            >
                                                <i18n-t
                                                    tag="p"
                                                    keypath="ProcessesMails.tabs.3.text"
                                                >
                                                    <em>{{ $t('ProcessesMails.tabs.3.ems.1') }}</em>
                                                    <em>{{ $t('ProcessesMails.tabs.3.ems.2') }}</em>
                                                </i18n-t>
                                                <ul class="list-unordered">
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.1') }}</li>
                                                    <li>{{ $t('ProcessesMails.tabs.1.ul.lis.2') }}</li>
                                                </ul>
                                            </Tab>
                                        </Tabs>
                                        <div
                                            id="c2199"
                                            class="ce-box   box"
                                            data-qa="ce-2199"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('ProcessesMails.sections.2.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('ProcessesMails.sections.2.ps.1') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('ProcessesMails.sections.2.ul.lis.1') }}</li>
                                                        <li>{{ $t('ProcessesMails.sections.2.ul.lis.2') }}</li>
                                                        <li>{{ $t('ProcessesMails.sections.2.ul.lis.3') }}</li>
                                                    </ul>
                                                    <p>{{ $t('ProcessesMails.sections.2.ps.2') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c4205"
                                            class="ce-box   box"
                                            data-qa="ce-4205"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('ProcessesMails.sections.3.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <i18n-t
                                                        tag="p"
                                                        keypath="ProcessesMails.sections.3.ps.1"
                                                    >
                                                        {{ $t('ProcessesMails.sections.3.em') }}
                                                    </i18n-t>
                                                    <i18n-t
                                                        tag="p"
                                                        keypath="ProcessesMails.sections.3.ps.2"
                                                    >
                                                        {{ $t('ProcessesMails.sections.3.em') }}
                                                    </i18n-t>
                                                </div>
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/processesMail/Screenshot_Vorgaenge.png"
                                                                            width="818"
                                                                            height="270"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c2236"
                                            class="ce-box   box"
                                            data-qa="ce-2236"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('ProcessesMails.sections.4.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('ProcessesMails.sections.4.text') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('ProcessesMails.sections.4.ul.lis.1') }}</li>
                                                        <li>{{ $t('ProcessesMails.sections.4.ul.lis.2') }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--center {
    .ce-textpic__gallery {
        text-align: center;
    }
    .ce-textpic__media {
        padding: 20px;
        iframe {
            max-width: 100%;
            height: auto;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

em {
    color: var(--color-c-s1);
}
</style>
