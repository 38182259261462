<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.my-profile-company'
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'help-my-profile' }">
                    {{ $t('helpMyProfile.myProfile') }}
                </router-link>
            </li>
            <li>{{ $t('helpMyProfileCompany.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c6052"
                        class="ce-box box box--sticky-container"
                    >
                        <div
                            id="toc6052"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        class="toc__"
                                        href="#c6080"
                                        @click.prevent="scrollTo('#c6080')"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                href="#"
                                                @click.prevent="scrollTo('#c773')"
                                            >
                                                {{ $t('helpMyProfileCompany.sections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href="#"
                                                @click.prevent="scrollTo('#c740')"
                                            >
                                                {{ $t('helpMyProfileCompany.sections.2.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href="#"
                                                @click.prevent="scrollTo('#c742')"
                                            >
                                                {{ $t('helpMyProfileCompany.sections.3.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href="#"
                                                @click.prevent="scrollTo('#c743')"
                                            >
                                                {{ $t('helpMyProfileCompany.sections.4.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href="#"
                                                @click.prevent="scrollTo('#c744')"
                                            >
                                                {{ $t('helpMyProfileCompany.sections.5.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        class="toc__"
                                        href="#c6052"
                                        @click.prevent="scrollTo('#c6052')"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c739"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-739"
                    >
                        <div
                            xmlns:v="http://typo3.org/ns/FluidTYPO3/Vhs/ViewHelpers"
                            xmlns:f="http://typo3.org/ns/TYPO3/CMS/Fluid/ViewHelpers"
                        >
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h1 />

                                        <div
                                            id="c773"
                                            class="ce-box   box"
                                            data-qa="ce-773"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpMyProfileCompany.sections.1.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpMyProfileCompany.sections.1.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c740"
                                            class="ce-box   box"
                                            data-qa="ce-740"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpMyProfileCompany.sections.2.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpMyProfileCompany.sections.2.text')" />
                                            </div>
                                        </div>

                                        <a id="c742" /><div class="ce-box box">
                                            <header>
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpMyProfileCompany.sections.3.title') }}</h3>
                                                </div>
                                            </header>
                                        </div>
                                        <div v-html="$t('helpMyProfileCompany.sections.3.text')" />

                                        <div
                                            id="c743"
                                            class="ce-box   box"
                                            data-qa="ce-743"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpMyProfileCompany.sections.4.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpMyProfileCompany.sections.4.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c744"
                                            class="ce-box   box"
                                            data-qa="ce-744"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpMyProfileCompany.sections.5.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpMyProfileCompany.sections.5.text')" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding-bottom: 1.5rem;
    position: relative;

    &.box--sticky-container {
        height: 100%;

        @media (max-width: 991px) {
            height: auto !important;
        }
    }
}

.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}

.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;

    .toc__list,
    .toc__subList {
        margin: 0;
        list-style-type: none;
    }

    .toc__list {
        padding: 0;

        .toc__subList {
            padding: 0;
            font-size: .9em;

            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic {
    display: block;
    overflow: hidden;

    &.ce-textpic--right {
        .ce-textpic__gallery {
            float: right;

            .ce-textpic__media {
                float: right;
                padding-left: 20px;
            }
        }
    }

    &.ce-textpic--right {
        &.ce-textpic--intext .ce-textpic__media {
            max-width: 100%;
        }

        .ce-textpic__gallery {
            max-width: 40%;

            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }

            .ce-textpic__media {
                padding-bottom: 20px;

                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
            }
        }
    }

    &.ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;

            .ce-textpic__media {
                padding: 20px;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.services-box {
    margin-bottom: 32px;

    .list-unordered {
        margin-bottom: 16px;
    }
}
</style>
