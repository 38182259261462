<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.help.debt-collection-format-description'

</script>

<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'help-debt-collection' }">
                    {{ $t('DebtCollection.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('debtCollectionFormatDescription.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3>{{ $t('debtCollectionFormatDescription.title') }}</h3>
                </div>
                <div class="col-12">
                    <div v-html="$t('debtCollectionFormatDescription.table')" />
                </div>
            </div>
        </div>
    </div>
</template>
