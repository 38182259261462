<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('report.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>{{ $t('report.header') }}</h1>
                    <p v-html="$t('report.description')" />
                </div>
            </div>
        </div>

        <div class="content-spacer">
            <div
                class="bg-brand-light-grey box-spacing"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="box-shadow-xy bg-white small-box-spacing mb mt">
                                <h3>{{ $t('report.boxes.1.title') }}</h3>
                                <p class="mb-small">
                                    {{ $t('report.boxes.1.description') }}
                                </p>
                                <a :href="reportsLandingpageB2BLinks[$i18n.locale]">
                                    {{ $t('report.boxes.1.linkText') }}
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="box-shadow-xy bg-white small-box-spacing mb mt">
                                <h3>{{ $t('report.boxes.2.title') }}</h3>
                                <p class="mb-small">
                                    {{ $t('report.boxes.2.description') }}
                                </p>
                                <a :href="reportsLandingpageConsumerLinks[$i18n.locale]">
                                    {{ $t('report.boxes.2.linkText') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-spacer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ProductTabs />
                    </div>
                </div>
            </div>
        </div>

        <ReportFooterBoxes />
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import { reportsLandingpageB2BLinks , reportsLandingpageConsumerLinks } from '@/data/typo3Links'
import ProductTabs from '@/components/Report/ProductTabs.vue'
import ReportFooterBoxes from '@/components/Report/ReportFooterBoxes.vue'

const id = 'portal.mc.report'

</script>
