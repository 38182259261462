<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import Accordion from '@/components/Accordion/Accordion.vue'

const id = 'portal.mc.help.premium-report'
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'credit-information' }">
                    {{ $t('help.creditReports.breadcrumbs.creditReport') }}
                </router-link>
            </li>
            <li>{{ $t('help.creditReports.breadcrumbs.premium') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3" />
                <div class="col-md-9">
                    <div
                        id="c498"
                        class=" clearfix grid-box grid-box--accordionContainer box"
                        data-qa="grid-box-498"
                    >
                        <div class="ce-header">
                            <h3>{{ $t('PremiumReport.title') }}</h3>
                        </div>

                        <div
                            id="accordion498"
                            class="ce-accordion panel-group"
                            role="tablist"
                        >
                            <div
                                id="c487"
                                class="ce-box   box"
                                data-qa="ce-487"
                            >
                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                    <div
                                        class="ce-textpic__gallery"
                                        data-ce-columns="1"
                                        data-ce-images="1"
                                    >
                                        <div class="ce-textpic__row">
                                            <div class="ce-textpic__column">
                                                <div class="ce-textpic__media">
                                                    <img
                                                        src="@/assets/img/help/bonitaetsauskunft/Premiumauskunft.png"
                                                        width="665"
                                                        height="349"
                                                        alt=""
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Accordion>
                                <AccordionItem
                                    :item-key="1"
                                    :title="$t('PremiumReport.accordion.1.title')"
                                >
                                    <div v-html="$t('PremiumReport.accordion.1.text')" />
                                </AccordionItem>
                                <AccordionItem
                                    :item-key="2"
                                    :title="$t('PremiumReport.accordion.2.title')"
                                >
                                    <div v-html="$t('PremiumReport.accordion.2.text')" />
                                </AccordionItem>
                                <AccordionItem
                                    :item-key="3"
                                    :title="$t('PremiumReport.accordion.3.title')"
                                >
                                    <div v-html="$t('PremiumReport.accordion.3.text')" />
                                </AccordionItem>
                                <AccordionItem
                                    :item-key="4"
                                    :title="$t('PremiumReport.accordion.4.title')"
                                >
                                    <div v-html="$t('PremiumReport.accordion.4.text')" />
                                </AccordionItem>
                            </Accordion>

                            <div
                                id="c713"
                                class="ce-box   box"
                                data-qa="ce-713"
                            >
                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                    <div class="ce-textpic__bodytext">
                                        <i18n-t
                                            keypath="PremiumReport.backLink.text"
                                            tag="p"
                                        >
                                            <router-link
                                                :to="{ name: 'credit-information' }"
                                                title="Opens internal link in current window"
                                                class="internal-link"
                                            >
                                                {{ $t('PremiumReport.backLink.linkText') }}
                                            </router-link>&nbsp;
                                        </i18n-t>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
}
.grid-box {
    display: block;
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

b, strong {
    color: var(--color-c-s3);
}
</style>
