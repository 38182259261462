<script setup lang="ts">
import { scrollTo } from '@/helper/scrollto'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import Tab from '@/components/Tabs/Tab.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

const { locale } = useI18n()
const isEnglish = computed(() => {
    return locale.value === 'en-US'
})

const id = 'portal.mc.help.watchlist'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('help.watchlist.breadcrumbs.watchlist') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c371"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-371"
                    >
                        <div
                            id="toc371"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul
                                v-if="!isEnglish"
                                class="toc__list"
                            >
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-371"
                                        class="toc__"
                                        href="/hilfe/watchlist/#c371"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-759"
                                        class="toc__"
                                        href="/hilfe/watchlist/#c759"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                href=""
                                                data-qa="toc-listItem-760"
                                                @click.prevent="scrollTo('#c760')"
                                            >
                                                {{ $t('helpWatchlist.sections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href=""
                                                data-qa="toc-listItem-4145"
                                                @click.prevent="scrollTo('#c4145')"
                                            >
                                                {{ $t('helpWatchlist.sections.2.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href=""
                                                data-qa="toc-listItem-4143"
                                                @click.prevent="scrollTo('#c4143')"
                                            >
                                                {{ $t('helpWatchlist.sections.3.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href=""
                                                data-qa="toc-listItem-762"
                                                @click.prevent="scrollTo('#c762')"
                                            >
                                                {{ $t('helpWatchlist.sections.4.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href=""
                                                data-qa="toc-listItem-7325"
                                                @click.prevent="scrollTo('#c7325')"
                                            >
                                                {{ $t('helpWatchlist.sections.5.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href=""
                                                data-qa="toc-listItem-3547"
                                                @click.prevent="scrollTo('#c3547')"
                                            >
                                                {{ $t('helpWatchlist.sections.6.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                href=""
                                                data-qa="toc-listItem-7313"
                                                @click.prevent="scrollTo('#c7313')"
                                            >
                                                {{ $t('helpWatchlist.sections.7.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul
                                v-else
                                class="toc__list"
                            >
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-371"
                                        class="toc__"
                                        href="/en/help/watchlist/#c371"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-759"
                                        class="toc__"
                                        href=""
                                        @click.prevent="scrollTo('#c759')"
                                    >
                                        {{ $t('helpWatchlist.title') }}
                                    </a>
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-760"
                                                href=""
                                                @click.prevent="scrollTo('#c760')"
                                            >
                                                {{ $t('helpWatchlist.sections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-762"
                                                href=""
                                                @click.prevent="scrollTo('#c762')"
                                            >
                                                {{ $t('helpWatchlist.sections.4.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c759"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-759"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 v-if="isEnglish">
                                            {{ $t('helpWatchlist.title') }}
                                        </h1>
                                        <div
                                            id="c760"
                                            class="ce-box   box"
                                            data-qa="ce-760"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpWatchlist.sections.1.title') }}</h3>
                                                </div>
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/watchlist/handwerker-device.png"
                                                                    width="600"
                                                                    height="400"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <i18n-t
                                                        keypath="helpWatchlist.sections.1.ps.1.text"
                                                        tag="p"
                                                    >
                                                        <i>{{ $t('helpWatchlist.sections.1.ps.1.i') }}</i>
                                                    </i18n-t>
                                                    <div v-if="!isEnglish">
                                                        <p>{{ $t('helpWatchlist.sections.1.ps.2') }}</p>
                                                        <p>{{ $t('helpWatchlist.sections.1.ps.3') }}</p>
                                                        <ul class="list-unordered">
                                                            <i18n-t
                                                                keypath="helpWatchlist.sections.1.ul.lis.1.text"
                                                                tag="li"
                                                            >
                                                                <i>{{ $t('helpWatchlist.sections.1.ul.lis.1.i') }}</i>
                                                            </i18n-t>
                                                            <i18n-t
                                                                keypath="helpWatchlist.sections.1.ul.lis.2.text"
                                                                tag="li"
                                                            >
                                                                <i>{{ $t('helpWatchlist.sections.1.ul.lis.2.i') }}</i>
                                                            </i18n-t>
                                                        </ul>
                                                    </div>
                                                    <i18n-t
                                                        keypath="helpWatchlist.sections.1.ps.4.text"
                                                        tag="p"
                                                    >
                                                        <router-link
                                                            :to="{ name: 'risk-management' }"
                                                            title="Risikomanagement"
                                                            target="_blank"
                                                        >
                                                            {{ $t('helpWatchlist.sections.1.ps.4.linkText') }}
                                                        </router-link>
                                                    </i18n-t>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="!isEnglish"
                                            id="c4145"
                                            class="ce-box   box"
                                            data-qa="ce-4145"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpWatchlist.sections.2.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <i18n-t
                                                        keypath="helpWatchlist.sections.2.ps.1.text"
                                                        tag="p"
                                                    >
                                                        <b>{{ $t('helpWatchlist.sections.2.ps.1.b') }}</b>
                                                    </i18n-t>
                                                    <p>{{ $t('helpWatchlist.sections.2.ps.2') }}</p>
                                                    <i18n-t
                                                        keypath="helpWatchlist.sections.2.ps.3.text"
                                                        tag="p"
                                                    >
                                                        <i18n-t
                                                            keypath="helpWatchlist.sections.2.ps.3.b.text"
                                                            tag="b"
                                                        >
                                                            <i>{{ $t('helpWatchlist.sections.2.ps.3.b.i') }}</i>
                                                        </i18n-t>
                                                    </i18n-t>
                                                </div>
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/watchlist/Auskunfthistorie.png"
                                                                    width="1105"
                                                                    height="393"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="!isEnglish"
                                            id="c4143"
                                            class="ce-box   box"
                                            data-qa="ce-4143"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpWatchlist.sections.3.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <i18n-t
                                                            keypath="helpWatchlist.sections.3.ul.lis.1.text"
                                                            tag="li"
                                                        >
                                                            <b>{{ $t('helpWatchlist.sections.3.ul.lis.1.b') }}</b>
                                                        </i18n-t>
                                                        <i18n-t
                                                            keypath="helpWatchlist.sections.3.ul.lis.2.text"
                                                            tag="li"
                                                        >
                                                            <b>{{ $t('helpWatchlist.sections.3.ul.lis.2.bs.1') }}</b>
                                                            <b>{{ $t('helpWatchlist.sections.3.ul.lis.2.bs.2') }}</b>
                                                        </i18n-t>
                                                        <i18n-t
                                                            keypath="helpWatchlist.sections.3.ul.lis.3.text"
                                                            tag="li"
                                                        >
                                                            <b>{{ $t('helpWatchlist.sections.3.ul.lis.3.b') }}</b>
                                                        </i18n-t>
                                                    </ul>
                                                </div>
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/watchlist/Signal.png"
                                                                    width="1093"
                                                                    height="110"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c762"
                                            class=" clearfix grid-box grid-box--tabContainer box"
                                            data-qa="grid-box-762"
                                        >
                                            <div class="ce-header">
                                                <h3>{{ $t('helpWatchlist.sections.4.title') }}</h3>
                                            </div>
                                            <Tabs
                                                :id="id + '.anchor-links'"
                                                selected-tab="tab-0"
                                            >
                                                <Tab
                                                    id="tab-0"
                                                    :title="$t('helpWatchlist.sections.4.anchorLinks.1')"
                                                >
                                                    <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                        <div class="ce-textpic__bodytext">
                                                            <ul class="list-unordered">
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.1.ul.lis.1') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.1.ul.lis.2') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.1.ul.lis.3') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.1.ul.lis.4') }}</li>
                                                            </ul>
                                                            <router-link
                                                                v-if="!isEnglish"
                                                                :to="{ name: 'help-watchlist-signal-address' }"
                                                                class="internal-link"
                                                            >
                                                                {{ $t('helpWatchlist.sections.4.tabs.1.link') }}
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab
                                                    id="tab-1"
                                                    :title="$t('helpWatchlist.sections.4.anchorLinks.2')"
                                                >
                                                    <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                        <div class="ce-textpic__bodytext">
                                                            <ul class="list-unordered">
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.2.ul.lis.1') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.2.ul.lis.2') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.2.ul.lis.3') }}</li>
                                                            </ul>
                                                            <i18n-t
                                                                v-if="!isEnglish"
                                                                keypath="helpWatchlist.sections.4.tabs.2.p.text"
                                                                tag="p"
                                                            >
                                                                <a
                                                                    href="https://www.creditreform.de/ratingmap"
                                                                    title="Rating Map"
                                                                    target="_blank"
                                                                    class="external-link-new-window"
                                                                >
                                                                    {{ $t('helpWatchlist.sections.4.tabs.2.p.linkText') }}
                                                                </a>
                                                            </i18n-t>
                                                            <router-link
                                                                v-if="!isEnglish"
                                                                :to="{ name: 'help-watchlist-signal-solvency' }"
                                                                class="internal-link"
                                                            >
                                                                {{ $t('helpWatchlist.sections.4.tabs.2.link') }}
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab
                                                    id="tab-2"
                                                    :title="$t('helpWatchlist.sections.4.anchorLinks.3')"
                                                >
                                                    <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                        <div class="ce-textpic__bodytext">
                                                            <ul class="list-unordered">
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.1') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.2') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.3') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.4') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.5') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.6') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.7') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.8') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.9') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.10') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.11') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.12') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.13') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.14') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.15') }}</li>
                                                                <li>{{ $t('helpWatchlist.sections.4.tabs.3.ul.lis.16') }}</li>
                                                            </ul>
                                                            <router-link
                                                                v-if="!isEnglish"
                                                                :to="{ name: 'help-watchlist-monitoring' }"
                                                                class="internal-link"
                                                            >
                                                                {{ $t('helpWatchlist.sections.4.tabs.3.link') }}
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                        <div
                                            v-if="!isEnglish"
                                            id="c7325"
                                            class="ce-box   box"
                                            data-qa="ce-7325"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpWatchlist.sections.5.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>
                                                        {{ $t('helpWatchlist.sections.5.p') }}
                                                    </p>
                                                    <br>
                                                    <router-link
                                                        :to="{ name: 'contact' }"
                                                        title="Kontakt"
                                                    >
                                                        {{ $t('helpWatchlist.sections.5.link') }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="!isEnglish"
                                            id="c3547"
                                            class="ce-box   box"
                                            data-qa="ce-3547"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpWatchlist.sections.6.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('helpWatchlist.sections.6.p') }}</p>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="!isEnglish"
                                            id="c7313"
                                            class="ce-box   box"
                                            data-qa="ce-7313"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpWatchlist.sections.7.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('helpWatchlist.sections.7.p') }}</p>
                                                    <ul class="list-unordered">
                                                        <i18n-t
                                                            keypath="helpWatchlist.sections.7.ul.lis.1.text"
                                                            tag="li"
                                                        >
                                                            <b>{{ $t('helpWatchlist.sections.7.ul.lis.1.b') }}</b>
                                                        </i18n-t>
                                                        <i18n-t
                                                            keypath="helpWatchlist.sections.7.ul.lis.2.text"
                                                            tag="li"
                                                        >
                                                            <b>{{ $t('helpWatchlist.sections.7.ul.lis.2.b') }}</b>
                                                        </i18n-t>
                                                        <i18n-t
                                                            keypath="helpWatchlist.sections.7.ul.lis.3.text"
                                                            tag="li"
                                                        >
                                                            <b>{{ $t('helpWatchlist.sections.7.ul.lis.3.b') }}</b>
                                                        </i18n-t>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
@break-mobile: ~"screen and (max-width: 767px)";
.box {
    position: relative;
    padding: 1.5rem;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
    @media @break-mobile {
        padding: 0.5rem;
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--center {
    .ce-textpic__gallery {
        text-align: center;
    }
    .ce-textpic__media {
        padding: 20px;
        iframe {
            max-width: 100%;
            height: auto;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--below {
    .ce-textpic__gallery {
        float: none;
        max-width: 100%;
    }
    .ce-textpic__media {
        float: none;
        display: block;
        padding: 20px 0;
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

b, strong {
    color: var(--color-c-s3);
}
p {
    white-space: pre-wrap;
}
</style>
