<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'report' }">
                    {{ $t('report.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('productComparison.breadcrumb') }}</li>
        </Breadcrumb>

        <Hero
            :image="heroImage"
            :mobile-image="heroImageMobile"
        >
            <div class="container">
                <div class="row mb-large">
                    <div class="col-12">
                        <h1 class="p0 marketing">
                            <span>{{ $t('productComparison.hero.title') }}</span>
                        </h1>
                    </div>
                    <div class="col-12">
                        <h2>{{ $t('productComparison.hero.subtitle') }}</h2>
                    </div>
                </div>
            </div>
        </Hero>

        <div class="container content-spacer">
            <div class="row ">
                <div class="col-12 col-md-8 offset-md-2">
                    <h3>{{ $t('productComparison.title') }}</h3>
                    <p>{{ $t('productComparison.description') }}</p>

                    <ul class="list-unordered mt">
                        <li>
                            <a
                                :href="getFileUrlProductComparison('Inhalte_Bonitaetsprodukte_national_Meine_Creditreform.pdf')"
                                target="_blank"
                            >
                                Inhalte_Bonitaetsprodukte_national_Meine_Creditreform.pdf
                            </a>
                        </li>
                        <li>
                            <a
                                :href="getFileUrlProductComparison('Inhalte_Informationsprodukte_national_Meine_Creditreform.pdf')"
                                target="_blank"
                            >
                                Inhalte_Informationsprodukte_national_Meine_Creditreform.pdf
                            </a>
                        </li>
                        <li>
                            <a
                                :href="getFileUrlProductComparison('Inhalte_Bonitaetsprodukte_international_Meine_Creditreform.pdf')"
                                target="_blank"
                            >
                                Inhalte_Bonitaetsprodukte_international_Meine_Creditreform.pdf
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="content-spacer">
            <div
                class="bg-brand-light-grey box-spacing"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing mb mt ">
                                <h3>{{ $t('productComparison.footerBoxes.1.title') }}</h3>
                                <p class="mb-small">
                                    {{ $t('productComparison.footerBoxes.1.description') }}
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing mb mt ">
                                <h3>{{ $t('productComparison.footerBoxes.2.title') }}</h3>
                                <ul class="list-unordered">
                                    <li>
                                        <a
                                            :href="getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Ampelauskunft.pdf')"
                                            target="_blank"
                                        >
                                            {{ $t('productComparison.footerBoxes.2.filenames.1') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            :href="getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Kurzauskunft.pdf')"
                                            target="_blank"
                                        >
                                            {{ $t('productComparison.footerBoxes.2.filenames.2') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            :href="getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Kompaktauskunft.pdf')"
                                            target="_blank"
                                        >
                                            {{ $t('productComparison.footerBoxes.2.filenames.3') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            :href="getFileUrlUDS('musterauskuenfte/2024-07-03_MU_OE_DE_Wirtschaftsauskunft.pdf')"
                                            target="_blank"
                                        >
                                            {{ $t('productComparison.footerBoxes.2.filenames.4') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            :href="getFileUrlUDS('musterauskuenfte/2024-07-24_MU_OE_DE_Premiumauskunft.pdf')"
                                            target="_blank"
                                        >
                                            {{ $t('productComparison.footerBoxes.2.filenames.5') }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing mb mt ">
                                <h3>{{ $t('productComparison.footerBoxes.3.title') }}</h3>
                                <p class="mb-small">
                                    {{ $t('productComparison.footerBoxes.3.description') }}
                                </p>
                                <a
                                    href="https://consumer.boniversum.com/sso"
                                    target="_blank"
                                >
                                    {{ $t('productComparison.footerBoxes.3.linkText') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Hero from '@/components/Hero.vue'
import heroImage from '@/assets/img/bonitaetsauskunft/product-comparison/frau-mobile.png'
import heroImageMobile from '@/assets/img/bonitaetsauskunft/product-comparison/frau-mobile-klein.png'
import { getFileUrlUDS, getFileUrlProductComparison } from '@/helper/image'

const id = 'portal.mc.product-comparison'

</script>

<style lang="less" scoped>
@screen-md-min: 992px;
@screen-sm-max: (@screen-md-min - 1);
@snow:              #f2f2f2;
@soft-blue:         #61ace3;

.comparison-table {
    &__checkbox {
        &-container {
            padding: 1rem;
            width: 100%;
            height: 45px;
        }

        &-container--empty {
            background-color: transparent;
        }
    }

    &__products {
        position: relative;

        &-wrapper {
            overflow-x: auto;
            /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
            -webkit-overflow-scrolling: touch;
        }
        &-columns {
            display: table;
            list-style: none;
            margin: 0;
            padding: 0;
            margin-left: 120px;
            border-top: 1px solid var(--color-c-linegrey);
            @media (min-width: @screen-md-min) {
                margin-left: 210px;
            }
        }
    }

    &__features {
        position: absolute;
        z-index: 2;
        background-color: @snow;
        opacity: 0.88;
        font-weight: 700;

        .comparison-table__product-features-list {
            border-left: 1px solid var(--color-c-linegrey);
            border-right: 1px solid var(--color-c-linegrey);
            border-top: 1px solid var(--color-c-linegrey);
        }
    }

    &__product {
        display: table-cell;
        list-style: none;
        border-right: 1px solid var(--color-c-linegrey);

        &-info {
            padding: 0;
            height: 150px;
        }

        &-infoHeadline {
            display: inline-block;
            height: 85px;
            vertical-align: middle;
        }
        &-features-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        &-feature {
            width: 120px;
            border-bottom: 1px solid var(--color-c-linegrey);
            p {
                margin: 16px 0;
            }
            @media (min-width: @screen-md-min) {
                width: 210px;
            }
        }
    }

    &__navigation {
        list-style: none;
        &-prev,
        &-next {
            position: absolute;
            z-index: 2;
            top: 15px;
            right: 15px;
            width: 50px;
            height: 107px;
            opacity: 0.58;
            border-radius: 8px;
            background-color: var(--color-c-linegrey);
            @media (max-width:@screen-sm-max) {
                width: 25px;
                height: 53px;
                border-radius: 4px;
            }
            &:hover {
                background-color: @soft-blue;
            }
        }
        &-prev:after,
        &-next:after {
            content: "";
            display: block;
            background: transparent url(../img/pfeil-weiss@2x.png) no-repeat center;
            background-size: 30px 50px;
            width: 50px;
            height: 107px;
            position: absolute;
            top: 0;
            left: 0;
            @media (max-width:@screen-sm-max) {
                background-size: 15px 25px;
                width: 25px;
                height: 53px;
            }
        }
        &-next {
          left: 135px;
          right: auto;
          transform: rotate(180deg);
          @media (min-width: @screen-md-min) {
            left: 225px;
          }
        }
    }

}
</style>
