<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'
import { useI18n } from 'vue-i18n'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import Accordion from '@/components/Accordion/Accordion.vue'

const id = 'portal.mc.help.credit-information.feedback'

const { t } = useI18n()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tableHeaders = [
    t('helpFeedback.sections.2.table.headers.1'),
    t('helpFeedback.sections.2.table.headers.2'),
    t('helpFeedback.sections.2.table.headers.3'),
    t('helpFeedback.sections.2.table.headers.4'),
    t('helpFeedback.sections.2.table.headers.5')
]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tableRows = [
    [t('helpFeedback.sections.2.table.cells.1')],
    [t('helpFeedback.sections.2.table.cells.2')],
    [t('helpFeedback.sections.2.table.cells.3')],
    [t('helpFeedback.sections.2.table.cells.4')],
    [t('helpFeedback.sections.2.table.cells.5')],
]
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'credit-information' }">
                    {{ $t('help.creditReports.breadcrumbs.creditReport') }}
                </router-link>
            </li>
            <li>{{ $t('help.creditReports.breadcrumbs.feedback') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c2491"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-2491"
                    >
                        <div
                            id="toc2491"
                            class="toc fixedsticky"
                            data-bind="descendantsComplete: function() {window.bindWillow('Sticky');}"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2492"
                                        class="toc__"
                                        href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/#c2492"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2493"
                                                href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/#c2493"
                                                @click.prevent="scrollTo('#c2493')"
                                            >
                                                {{ $t('helpFeedback.sections.1.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2494"
                                                href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/#c2494"
                                                @click.prevent="scrollTo('#c2494')"
                                            >
                                                {{ $t('helpFeedback.sections.2.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2515"
                                                href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/#c2515"
                                                @click.prevent="scrollTo('#c2515')"
                                            >
                                                {{ $t('helpFeedback.sections.3.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2495"
                                                href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/#c2495"
                                                @click.prevent="scrollTo('#c2495')"
                                            >
                                                {{ $t('helpFeedback.sections.4.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2510"
                                                href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/#c2510"
                                            />
                                        </li>
                                    </ul>
                                </li>

                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2491"
                                        class="toc__"
                                        href="/hilfe/bonitaetsauskunft/lieferantenrueckfrage/#c2491"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c2492"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-2492"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c2493"
                                            class="ce-box   box"
                                            data-qa="ce-2493"
                                        >
                                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpFeedback.sections.1.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/bonitaetsauskunft/qualitaetsverbesserer.jpeg"
                                                                    width="800"
                                                                    height="600"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <i18n-t
                                                        keypath="helpFeedback.sections.1.ps.1"
                                                        tag="p"
                                                    >
                                                        <strong>{{ $t('helpFeedback.sections.1.strong') }}</strong>
                                                    </i18n-t>
                                                    <p>{{ $t('helpFeedback.sections.1.ps.2') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c2494"
                                            class="ce-box  box"
                                            data-qa="ce-2494"
                                        >
                                            <div class="ce-header">
                                                <h3>{{ $t('helpFeedback.sections.2.title') }}</h3>
                                            </div>
                                            <div class="table-responsive ">
                                                <table class="table col-12">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.headers.1') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.cells.1') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.headers.2') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.cells.2') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.headers.3') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.cells.3') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.headers.4') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.cells.4') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.headers.5') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('helpFeedback.sections.2.table.cells.5') }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            id="c2515"
                                            class="ce-box   box"
                                            data-qa="ce-2515"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpFeedback.sections.3.title') }}</h3>
                                                </div>
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/bonitaetsauskunft/liererantenrueckfrage/creditreform-analyst.png"
                                                                    width="400"
                                                                    height="300"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>
                                                        {{ $t('helpFeedback.sections.3.ps.1') }}
                                                    </p>
                                                    <p>
                                                        {{ $t('helpFeedback.sections.3.ps.2') }}
                                                    </p>
                                                    <p>{{ $t('helpFeedback.sections.3.ps.3') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c2495"
                                            class="ce-box   box"
                                            data-qa="ce-2495"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpFeedback.sections.4.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext" />
                                            </div>
                                        </div>
                                        <div
                                            id="c2510"
                                            class=" clearfix grid-box grid-box--accordionContainer box"
                                            data-qa="grid-box-2510"
                                        >
                                            <Accordion>
                                                <AccordionItem
                                                    :item-key="1"
                                                    :title="$t('helpFeedback.sections.4.accordion.items.1.panel')"
                                                >
                                                    <h3>{{ $t('helpFeedback.sections.4.accordion.items.1.title') }}</h3>
                                                    <p>{{ $t('helpFeedback.sections.4.accordion.items.1.text') }}</p>
                                                </AccordionItem>
                                                <AccordionItem
                                                    :item-key="2"
                                                    :title="$t('helpFeedback.sections.4.accordion.items.2.panel')"
                                                >
                                                    <h3>{{ $t('helpFeedback.sections.4.accordion.items.2.title') }}</h3>
                                                    <p>{{ $t('helpFeedback.sections.4.accordion.items.2.ps.1') }}</p>
                                                    <i18n-t
                                                        keypath="helpFeedback.sections.4.accordion.items.2.ps.2"
                                                        tag="p"
                                                    >
                                                        <i>{{ $t('helpFeedback.sections.4.accordion.items.2.i') }}</i>
                                                        <a
                                                            href="/hilfe/mein-profil/"
                                                            title="Opens internal link in current window"
                                                        >
                                                            {{ $t('helpFeedback.sections.4.accordion.items.2.link') }}
                                                        </a>
                                                    </i18n-t>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--left {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--left.ce-textpic--left {
    .ce-textpic__gallery {
        float: left;
    }
    .ce-textpic__media {
        float: left;
        padding-right: 20px;
        padding-left: 0;
    }
}
.ce-textpic--right.ce-textpic--left {
    .ce-textpic__gallery {
        float: left;
    }
    .ce-textpic__media {
        float: left;
        padding-right: 20px;
        padding-left: 0;
    }
}
.ce-textpic--left.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--left {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

strong {
    color: var(--color-c-s3);
}
i {
    color: var(--color-c-s1);
}
</style>
