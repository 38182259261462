<script setup lang="ts">
import { useUserStore } from '@/stores/user'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Teaser from '@/components/Utility/Teaser.vue'
import { useWatchlistStore } from '@/stores/watchlist'
import { useI18n } from 'vue-i18n'
import WatchlistItem from '@/components/Watchlist/WatchlistItem.vue'
import WatchlistSearchWrapper from '@/components/Watchlist/WatchlistSearchWrapper.vue'
import WatchlistInfoBox from '@/components/Watchlist/WatchlistInfoBox.vue'
import { differentContextActive, removeMemberNavigationSession } from '@/helper/services/memberNavigation'
import { ref } from 'vue'
import CompanySearch from '@/components/CompanySearch.vue'
import {
    placeNewWkvOfferShownAction,
    placeNewWkvContractsShownAction,
    placeNewWkvRejectsShownAction
} from '@/helper/webtrekk/watchlist'
import { transactionsLinks } from '@/data/typo3Links'

const userStore = useUserStore()
const watchlistStore = useWatchlistStore()
const { t } = useI18n()
const navigatingAsDifferentMember = ref(differentContextActive())
const id = 'portal.mc.watchlist'

function tracking() {
    let numberOfWkvOffers = 0
    let numberOfWkvContracts = 0
    let numberOfWkvRejects = 0

    watchlistStore.watchlist.watchlistEntries.forEach((entry) => {
        if (entry.wkvLink) {
            ++numberOfWkvOffers
        } else if (entry.wkvRuvStatus === 'WKV_RUV_ABGESCHLOSSEN') {
            ++numberOfWkvContracts
        } else if (entry.wkvRuvStatus === 'WKV_RUV_MOMENTAN_NICHT_ABSCHLIESSBAR') {
            ++numberOfWkvRejects
        }
    })

    if (numberOfWkvOffers) {
        placeNewWkvOfferShownAction(numberOfWkvOffers)
    }
    if (numberOfWkvContracts) {
        placeNewWkvContractsShownAction(numberOfWkvContracts)
    }
    if (numberOfWkvRejects) {
        placeNewWkvRejectsShownAction(numberOfWkvRejects)
    }
}

function clickInfoBoxMessageLink() {
    if (removeMemberNavigationSession()) {
        window.location.reload()
    }
}

tracking()
</script>

<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'report' }">
                    {{ $t('report.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ t('watchlist.breadcrumb') }}</li>
        </Breadcrumb>

        <WatchlistInfoBox />

        <WatchlistSearchWrapper v-if="!navigatingAsDifferentMember">
            <div class="bg-brand-light-grey box-spacing">
                <div class="container ">
                    <div class="row">
                        <div class="col-12">
                            <WatchlistItem
                                v-for="(item, index) in watchlistStore.watchlist.watchlistEntries"
                                :key="item.id"
                                :item="item"
                                :index="index"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </WatchlistSearchWrapper>
        <div
            v-else
            class="container"
        >
            <div class="row">
                <div class="col-12 mb">
                    <div
                        class="crefo-ui-alert info"
                        data-qa="infoBoxMessage-disabled-watchlist-text"
                    >
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="t('infoboxmessages.watchlist.text')" />
                        <a
                            href="#"
                            data-qa="infoBoxMessage-disabled-businessData-link"
                            @click="clickInfoBoxMessageLink"
                        >
                            {{ t('infoboxmessages.watchlist.link') }}
                        </a>
                    </div>
                </div>
                <div class="col-12">
                    <CompanySearch />
                </div>
            </div>
        </div>
        <div class="content-spacer">
            <div class="bg-brand-light-grey box-spacing">
                <div class="container ">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <Teaser
                                :title="t('watchlist.teaser1.title')"
                                :text="t('watchlist.teaser1.text')"
                                link="/hilfe/watchlist/"
                                :link-text="t('watchlist.teaser1.link')"
                            />
                        </div>
                        <div class="col-12 col-lg-4">
                            <Teaser
                                :title="t('watchlist.teaser2.title')"
                                :text="t('watchlist.teaser2.text')"
                                link="/"
                                :link-text="t('watchlist.teaser2.link')"
                            />
                        </div>
                        <div class="col-12 col-lg-4">
                            <Teaser
                                :title="t('watchlist.teaser3.title')"
                                :text="t('watchlist.teaser3.text')"
                                :link="`${transactionsLinks[$i18n.locale]}`"
                                :link-text="t('watchlist.teaser3.link')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
