<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.my-profile'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('helpMyProfile.myProfile') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c6052"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-6052"
                    >
                        <div
                            id="toc6052"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-6080"
                                        class="toc__"
                                        href="#c6080"
                                        @click.prevent="scrollTo('#c6080')"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6058"
                                                href="#c6058"
                                                @click.prevent="scrollTo('#c6058')"
                                            >
                                                {{ $t('helpMyProfile.13') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6076"
                                                href="#c6076"
                                                @click.prevent="scrollTo('#c6076')"
                                            >
                                                {{ $t('helpMyProfile.14') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6060"
                                                href="#c6060"
                                                @click.prevent="scrollTo('#c6060')"
                                            >
                                                {{ $t('helpMyProfile.15') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6138"
                                                href="#c6138"
                                                @click.prevent="scrollTo('#c6138')"
                                            >
                                                {{ $t('helpMyProfile.4') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-6052"
                                        class="toc__"
                                        href="#c6052"
                                        @click.prevent="scrollTo('#c6052')"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c6080"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-6080"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />

                                        <div
                                            id="c6058"
                                            class="ce-box   box"
                                            data-qa="ce-6058"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h1>
                                                        {{ $t('helpMyProfile.title') }}
                                                    </h1>
                                                </div>
                                            </div>

                                            <div
                                                id="c6076"
                                                class=" clearfix grid-box grid-box--accordionContainer box"
                                                data-qa="grid-box-6076"
                                            >
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpMyProfile.1') }}</h3>
                                                </div>
                                            </div>

                                            <div
                                                id="c6060"
                                                class="ce-box   box"
                                                data-qa="ce-6060"
                                            >
                                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                    <div class="ce-header">
                                                        <h4>{{ $t('helpMyProfile.2') }} </h4>
                                                    </div>

                                                    <div class="ce-textpic__bodytext">
                                                        <div class="services-box">
                                                            <p>
                                                                {{ $t('helpMyProfile.3') }}
                                                            </p>
                                                            <p>
                                                                <router-link :to="{ name: 'help-my-profile-company' }">
                                                                    {{ $t('helpMyProfile.4') }}
                                                                </router-link>
                                                            </p>
                                                        </div>
                                                        <div
                                                            id="c6076"
                                                            class=" clearfix grid-box grid-box--accordionContainer box"
                                                            data-qa="grid-box-6076"
                                                        >
                                                            <div class="ce-header">
                                                                <h3>{{ $t('helpMyProfile.5') }}</h3>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            {{ $t('helpMyProfile.6') }}
                                                        </p>
                                                        <div class="services-box">
                                                            <ul class="list-unordered">
                                                                <li>
                                                                    {{ $t('helpMyProfile.7') }}
                                                                </li>
                                                                <li>
                                                                    {{ $t('helpMyProfile.8') }}
                                                                </li>
                                                            </ul>
                                                            <p>
                                                                {{ $t('helpMyProfile.9') }}
                                                            </p>
                                                        </div>
                                                        <div class="services-box">
                                                            <div class="ce-header">
                                                                <h3>{{ $t('helpMyProfile.10') }} </h3>
                                                            </div>
                                                            <p><b>{{ $t('helpMyProfile.11') }}</b></p>
                                                            <p>{{ $t('helpMyProfile.12') }}</p>
                                                            <p>
                                                                <router-link :to="{ name: 'help-e-mails' }">
                                                                    {{ $t('helpMyProfile.4') }}
                                                                </router-link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding-bottom: 1.5rem;
    position: relative;

    &.box--sticky-container {
        height: 100%;

        @media (max-width: 991px) {
            height: auto !important;
        }
    }
}

.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}

.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;

    .toc__list,
    .toc__subList {
        margin: 0;
        list-style-type: none;
    }

    .toc__list {
        padding: 0;

        .toc__subList {
            padding: 0;
            font-size: .9em;

            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic {
    display: block;
    overflow: hidden;

    &.ce-textpic--right {
        .ce-textpic__gallery {
            float: right;

            .ce-textpic__media {
                float: right;
                padding-left: 20px;
            }
        }
    }

    &.ce-textpic--right {
        &.ce-textpic--intext .ce-textpic__media {
            max-width: 100%;
        }

        .ce-textpic__gallery {
            max-width: 40%;

            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }

            .ce-textpic__media {
                padding-bottom: 20px;

                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
            }
        }
    }

    &.ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;

            .ce-textpic__media {
                padding: 20px;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.services-box {
    margin-bottom: 32px;

    .list-unordered {
        margin-bottom: 16px;
    }
}
</style>
