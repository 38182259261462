<template>
    <div>
        <div
            v-if="_transaction && _transaction.involvementdataTransaction"
            :id="`transaction-${_transaction.id}`"
            :key="_transaction.id"
            class="results-list--item"
            :class="{ 'inactive': isProductInProgress(_transaction) }"
        >
            <div class="row">
                <div class="col-12">
                    <div class="bg-white">
                        <div class="row">
                            <div class="col-md-5 col-12">
                                <div class="row">
                                    <div
                                        v-if="!hideActions"
                                        class="col-1"
                                    >
                                        <div
                                            class="icon-favourite"
                                            data-qa="myTransactions-iconStar"
                                            @click="_toggleFavourite"
                                        >
                                            <font-awesome-icon
                                                :icon="`fa-light fa-star ${_transaction.favourite ? 'fa-solid' : ''}`"
                                                color="#009ee2"
                                            />
                                        </div>
                                        <div
                                            v-if="_transaction.reportId !== null && transactionsStore.deleteOptionsAreShown"
                                            class="select-delete"
                                        >
                                            <Checkbox
                                                :id="`select-delete-transaction-${_transaction.crefoTransactionId}`"
                                                v-model="_transaction.checkForDeleting"
                                                :name="`select-delete-transaction-${_transaction.crefoTransactionId}`"
                                                label=""
                                                :disabled="!erasable"
                                                data-qa="activities-deleting-entry-checkbox"
                                            />
                                        </div>
                                        <div
                                            v-else-if="_transaction.reportId !== null && !transactionsStore.deleteOptionsAreShown && !hideDeleteAction"
                                            class="icon-delete"
                                            data-qa="myTransactions-iconTrash"
                                            :class="{ 'disabled': !erasable }"
                                            @click="deleteTransaction"
                                        >
                                            <template v-if="erasable">
                                                <Tooltip position="bottom">
                                                    <font-awesome-icon
                                                        icon="fa-light fa-trash-can"
                                                        color="#009ee2"
                                                    />
                                                    <template #content>
                                                        <p>{{ $t('transaction.list.reports.delete.tooltop.erasable') }}</p>
                                                    </template>
                                                </Tooltip>
                                            </template>
                                            <template v-else>
                                                <Tooltip position="bottom">
                                                    <font-awesome-icon
                                                        icon="fa-light fa-trash-can"
                                                        color="#009ee2"
                                                    />
                                                    <template #content>
                                                        <p>{{ $t('transaction.list.reports.delete.tooltop.noterasable') }}</p>
                                                    </template>
                                                </Tooltip>
                                            </template>
                                        </div>
                                    </div>
                                    <div
                                        class="col-11"
                                        @click.prevent="openDetailsPage"
                                    >
                                        <span class="h4 transaction-title">
                                            <span data-qa="myTransactions-searchResultList-businessName">
                                                {{ displayName }}
                                            </span>
                                            <span
                                                v-if="_transaction.involvementdataTransaction.postalAddress !== null &&
                                                    _transaction.involvementdataTransaction.postalAddress.city"
                                                data-qa="myTransactions-searchResultList-postalAddress-city"
                                            >, {{ _transaction.involvementdataTransaction.postalAddress.city }}</span>
                                            <span
                                                v-if="_transaction.involvementdataTransaction.postalAddress !== null &&
                                                    _transaction.involvementdataTransaction.postalAddress.country &&
                                                    _transaction.involvementdataTransaction.postalAddress.country !== 'DE'"
                                                data-qa="myTransactions-searchResultList-postalAddress-country"
                                            >, {{ $t(`enums.countries.${_transaction.involvementdataTransaction.postalAddress.country}`) }}</span>
                                        </span>
                                        <div
                                            v-if="_transaction.involvementdataTransaction?.referenceNumber"
                                            class="text-small"
                                        >
                                            <span
                                                :class="{
                                                    'text-brand-grey': _transaction.reportId !== null,
                                                    'text-brand-dark-grey': _transaction.reportId === null
                                                }"
                                            >{{ $t('shared.reference') }}:</span>&nbsp;
                                            <span
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="myTransactions-searchResultList-referenceNumber"
                                            >{{ _transaction.involvementdataTransaction.referenceNumber }}</span>
                                        </div>
                                        <div
                                            v-if="featurePortfolioActive"
                                            class="text-small transaction_query_by"
                                        >
                                            <div class="icon baseline">
                                                <font-awesome-icon
                                                    icon="fa-solid fa-user"
                                                    color="#b5b5b5"
                                                />
                                            </div>
                                            <span
                                                :class="{
                                                    'text-brand-grey': _transaction.reportId !== null,
                                                    'text-brand-dark-grey': _transaction.reportId === null
                                                }"
                                            >{{ $t('transaction.list.transaction_getby') }}</span>&nbsp;
                                            <span
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="transaction_query_by"
                                            >{{ sbb(_transaction.owner) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-4 col-12"
                                @click.prevent="openDetailsPage"
                            >
                                <span
                                    class="h4 transaction-title"
                                    data-qa="myTransactions-searchResultList-familyTreeTransactionType"
                                >{{ $t(`shared.${_transaction.involvementdataTransaction.involvementdataProductType}`) }}</span>
                                <div
                                    v-if="isProductInProgress(_transaction)"
                                    class="text-small"
                                >
                                    <span class="text-brand-dark-grey">
                                        ({{ $t('transaction.list.report.not.ready.yet') }})
                                    </span>
                                </div>
                            </div>
                            <div
                                class="col-md-3 col-12 data p0"
                                @click.prevent="openDetailsPage"
                            >
                                <div class="text-small">
                                    <span class="text-brand-grey">{{ $t('transaction.show.orderNumber') }}</span>&nbsp;
                                    <span
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-productOrderId"
                                    >
                                        {{ _transaction.involvementdataTransaction.productOrderId }}
                                    </span>
                                </div>
                                <div
                                    v-if="_transaction.involvementdataTransaction.businessId"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">{{ businessIdLabel(_transaction.involvementdataTransaction.businessId) }}</span>&nbsp;
                                    <span
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-businessId"
                                    >
                                        {{ displayCrefoOrIdentNumber }}
                                    </span>
                                </div>
                                <div
                                    v-if="_transaction.involvementdataTransaction.creationTime"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">{{ $t('transaction.list.creationTime') }}</span>&nbsp;
                                    <span
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-creationTime"
                                    >
                                        {{ formatDate(_transaction.involvementdataTransaction.creationTime, 'L') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="deleteModal"
            :id="deleteModal.id"
            :title="deleteModal.title"
            :confirm-text="deleteModal.confirmText"
            :cancel-text="deleteModal.cancelText"
            :icon="deleteModal.icon"
            :data-qa="deleteModal.dataQa"
            @on-confirm="onDeleteModalConfirm"
            @on-close="deleteModal = undefined"
        >
            <p>{{ deleteModal.content }}</p>
        </Modal>
        <Modal
            v-if="notReadyModal"
            :id="notReadyModal.id"
            :title="notReadyModal.title"
            :confirm-text="notReadyModal.confirmText"
            :cancel-text="notReadyModal.cancelText"
            :icon="notReadyModal.icon"
            :data-qa="notReadyModal.dataQa"
            @on-confirm="notReadyModal = undefined"
            @on-close="notReadyModal = undefined"
        >
            <p>{{ notReadyModal.content }}</p>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import Modal from '@/components/Modal.vue'
import Tooltip from '@/components/Tooltip.vue'
import Checkbox from '@/components/Input/Checkbox.vue'

import { useTransactionsStore } from '@/stores/transactions'

import { accessToFeature } from '@/helper/services/features'
import {
    isProductInProgress,
    isReportTransactionErasable,
    toggleFavourite
} from '@/helper/services/transaction'

import { formatDate, sbb } from '@/filters'

import { adsInvolvementLinks } from '@/data/typo3Links'

import type { CbraTransaction } from '@/types/cbra/transactions'
import type { ModalProps } from '@/types/components/modal'

const transactionsStore = useTransactionsStore()
const { t, locale } = useI18n()

const props = defineProps<{
    transaction: CbraTransaction | undefined
    hideActions?: boolean
    hideDeleteAction?: boolean
}>()

const _transaction = ref<CbraTransaction | undefined>(props.transaction)

const deleteModal = ref<ModalProps>()
const notReadyModal = ref<ModalProps>()

const businessIdLabel = (id: string) => (id.charAt(0) === '0') ? t('transaction.list.businessIdDAL') : t('transaction.list.businessIdNonDAL')

const displayName = computed(() => {
    if (!_transaction.value) {
        return ''
    }
    if (_transaction.value.involvementdataTransaction!.businessName !== null) {
        return _transaction.value.involvementdataTransaction!.businessName
    }

    let name = ''
    if (_transaction.value.involvementdataTransaction!.givenName) {
        name += _transaction.value.involvementdataTransaction!.givenName + ' '
    }

    if (_transaction.value.involvementdataTransaction!.surname) {
        name += _transaction.value.involvementdataTransaction!.surname
    }

    return name
})

const displayCrefoOrIdentNumber = computed(() => {
    if (!_transaction.value) {
        return ''
    }
    
    return (_transaction.value.involvementdataTransaction!.businessId.charAt(0) !== '0') ? _transaction.value.involvementdataTransaction!.businessId : _transaction.value.involvementdataTransaction!.businessId.substring(4, _transaction.value.involvementdataTransaction!.businessId.length)
})

const featurePortfolioActive = computed(() => {
    return accessToFeature('portfolioViewActive')
})

const erasable = computed(() => {
    if (!_transaction.value) {
        return false
    }

    return isReportTransactionErasable(_transaction.value)
})

function _toggleFavourite() {
    if (!_transaction.value) return
    _transaction.value.favourite = !_transaction.value.favourite
    return toggleFavourite(_transaction.value)
}

const onDeleteModalConfirm = () => {
    if (!_transaction.value) return
    deleteModal.value = undefined
    transactionsStore.deleteTransactions([_transaction.value.crefoTransactionId.toString()])
}

function deleteTransaction() {
    if (!erasable.value) {
        return
    }
    deleteModal.value = {
        id: 'test',
        title: t('transaction.list.reports.delete.overlay.title'),
        content: t('transaction.list.reports.delete.overlay.question'),
        icon: 'warning',
        confirmText: t('transaction.list.reports.delete.overlay.confirm'),
        cancelText: t('transaction.list.reports.delete.overlay.cancel'),
        dataQa: 'delete-transaction'
    }
}

function openDetailsPage() {
    if (!_transaction.value) return
    if (isProductInProgress(_transaction.value)) {
        notReadyModal.value = {
            id: 'test',
            title: t('error.transaction.notReady'),
            content: t('error.transaction.notReady.description'),
            icon: 'warning',
            confirmText: t('shared.modals.ok')
        }
        return
    }
    location.assign(`${adsInvolvementLinks[locale.value]}?no_cache=1&transactionId=${_transaction.value.crefoTransactionId}`)
}
</script>
