<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'report' }">
                    {{ $t('report.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t("orderReport.breadcrumb") }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>{{ $t('orderReport.breadcrumb') }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="text-align--right">
                        {{ $t('advancedSearch.requiredInfo') }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="!isLoading.userPreferences && !isLoading.productAvailability && userPreferences"
        >
            <SearchForm
                :user-preferences="userPreferences"
                :search-preferences="searchPreferences"
                :product-availability="productAvailability"
                :is-order-view="true"
            />
        </div>
        <div class="content-spacer">
            <div class="bg-brand-light-grey box-spacing">
                <div class="container ">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing">
                                <h3>{{ $t('orderReport.boxes.box1.header') }}</h3>
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p v-html="$t('orderReport.boxes.box1.description')" />
                                <p>
                                    <a :href="$t('orderReport.boxes.box1.linkUrl')">
                                        {{ $t("orderReport.boxes.box1.linkText") }}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing  ">
                                <h3>
                                    {{ $t("orderReport.boxes.box2.header") }}
                                </h3>
                                <p>
                                    {{ $t("orderReport.boxes.box2.description") }}
                                </p>
                                <p v-if="$t('orderReport.boxes.box2.linkUrl')">
                                    <a :href="$t('orderReport.boxes.box2.linkUrl')">
                                        {{ $t("orderReport.boxes.box2.linkText") }}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing">
                                <h3>
                                    {{ $t("orderReport.boxes.box3.header") }}
                                </h3>
                                <p>
                                    {{ $t("orderReport.boxes.box3.description") }}
                                </p>
                                <p>
                                    <a :href="$t('orderReport.boxes.box3.linkUrl')">
                                        {{ $t("orderReport.boxes.box3.linkText") }}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'

import { useUserStore } from '@/stores/user'
import { onMounted, ref } from 'vue'
import { getCbraProductAvailability, getCbraSearchPreferences, getCbraUserPreferences } from '@/services'
import { CbraUserPreferences } from '@/types/cbra/userPreferences'
import SearchForm from '@/components/AdvancedSearch/SearchForm.vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { CbraProductAvailablityResponse } from '@/types/cbra/productAvailability'

const userStore = useUserStore()
const id = 'portal.mc.order-report'

const { locale } = useI18n()
const route = useRoute()

const businessId = ref(route.query.businessId?.toString())

let isLoading = ref({
    userPreferences: true,
    searchPreferences: true,
    productAvailability: true
})

let userPreferences = ref<CbraUserPreferences | undefined | null>(undefined)
let searchPreferences= ref<CbraSearchPreferences | undefined>(undefined)
let productAvailability = ref<CbraProductAvailablityResponse | undefined>(undefined)

const loadUserPreferences = async() => {
    isLoading.value.userPreferences = true

    try {
        const response = await getCbraUserPreferences()
        userPreferences.value = response.data
    } catch (error) {
        console.error(error)
    }
    isLoading.value.userPreferences = false
}

const loadSearchPreferences = async() => {
    try {
        isLoading.value.searchPreferences = true

        const response = await getCbraSearchPreferences()
        searchPreferences.value = response.data
    } catch (error) {
        console.error(error)
    }
    isLoading.value.searchPreferences = false
}

const loadProductAvailability = async() => {
    isLoading.value.productAvailability = true

    if (businessId.value && businessId.value.length > 2) {
        try {
            const response = await getCbraProductAvailability({ businessId: businessId.value, crId: userStore.user.cr_id, communicationLanguage: locale.value })
            productAvailability.value = response.data
        } catch (error) {
            console.error('error response', error)
        }
    }

    isLoading.value.productAvailability = false
}

onMounted(loadUserPreferences)
onMounted(loadSearchPreferences)
onMounted(loadProductAvailability)
</script>
