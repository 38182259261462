<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import Contact from '@/components/Teaser/Contact.vue'

const id = 'portal.mc.about-creditreform.my-creditreform'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'about-creditreform' }">
                    {{ $t('menu.servicemenu.about.title') }}
                </router-link>
            </li>
            <li>{{ $t('MyCreditreform.breadcrumbTitle') }}</li>
        </Breadcrumb>

        <div
            id="c1296"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-1296"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c1297"
                            class="ce-box   box"
                            data-qa="ce-1297"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('MyCreditreform.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext" />
                            </div>
                        </div>

                        <div
                            id="c1918"
                            class="ce-box   box"
                            data-qa="ce-1918"
                        >
                            <div class="ce-textpic ce-textpic--left ce-textpic--intext ce-textpic--nowrap">
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/widgets/laptop-dashboard.png"
                                                    width="800"
                                                    height="533"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>
                                        <strong>{{ $t('MyCreditreform.imageText.title') }}</strong>
                                    </p>
                                    <i18n-t
                                        keypath="MyCreditreform.imageText.text"
                                        tag="p"
                                    >
                                        <em class="blue-italic-text">
                                            {{ $t('MyCreditreform.imageText.italic') }}
                                        </em>
                                        <a
                                            v-if="$i18n.locale !== 'en-US'"
                                            href="https://www.creditreform.de/mitgliedschaft/creditreform-vor-ort.html"
                                            title="Opens internal link in current window"
                                            target="_blank"
                                            rel="noopener"
                                            class="external-link-new-window"
                                        >
                                            {{ $t('MyCreditreform.imageText.link') }}
                                        </a>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c1924"
            class=" clearfix grid-box grid-box--2cols box box--pearl"
            data-qa="grid-box-1924"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div
                            id="c1923"
                            class="ce-box   box"
                            data-qa="ce-1923"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                <div class="ce-header">
                                    <h3>{{ $t('MyCreditreform.debtCollection.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('MyCreditreform.debtCollection.text') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div
                            id="c1920"
                            class="ce-box   box"
                            data-qa="ce-1920"
                        >
                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('MyCreditreform.comparability.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('MyCreditreform.comparability.text') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c1584"
            class=" clearfix grid-box grid-box--2cols box"
            data-qa="grid-box-1584"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-xs-12 col-lg-6">
                        <div
                            id="c1585"
                            class="ce-box   box"
                            data-qa="ce-1585"
                        >
                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>Auf einen Blick</h3>
                                </div>

                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/icons/house-icon.svg"
                                                    width="32"
                                                    height="28"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>Gestalten Sie Ihre persönliche Startseite und behalten alles Wichtige im Blick.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            id="c1917"
                            class="ce-box   box"
                            data-qa="ce-1917"
                        >
                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>Ihre Daten in der Hand</h3>
                                </div>

                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/icons/profile-icon.svg"
                                                    width="34"
                                                    height="39"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>Prüfen Sie Ihre Unternehmensdaten und teilen Sie uns Änderungen oder Ergänzungen mit. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-lg-6">
                        <div
                            id="c1586"
                            class="ce-box   box"
                            data-qa="ce-1586"
                        >
                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>Kostenlose Kundenüberwachung</h3>
                                </div>

                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/icons/binoculars-icon.svg"
                                                    width="29"
                                                    height="13"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>Mit dem Watchlist-Service können Sie die Kundenüberwachung mühelos einrichten.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            id="c1916"
                            class="ce-box   box"
                            data-qa="ce-1916"
                        >
                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>Transparent und übersichtlich</h3>
                                </div>

                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/icons/folder-icon.svg"
                                                    width="23"
                                                    height="29"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>Alle Auskünfte und Inkassoaufträge können Sie an einer Stelle einsehen. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c1596"
            class=" clearfix grid-box grid-box--3cols box box--pearl"
            data-qa="grid-box-1596"
        >
            <div class="container container--crefo">
                <div class="row white-boxes-row">
                    <div class="col-12 col-md-4 white-box-col">
                        <div
                            id="c1591"
                            class="ce-box box box--white white-box"
                            data-qa="ce-1591"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('MyCreditreform.boxes.box1.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        v-if="$i18n.locale === 'en-US'"
                                        keypath="MyCreditreform.boxes.box3.text"
                                        tag="p"
                                    >
                                        <em class="blue-italic-text">{{ $t('MyCreditreform.boxes.box1.italic') }}</em>
                                    </i18n-t>
                                    <p v-else>
                                        {{ $t('MyCreditreform.boxes.box1.text') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 white-box-col">
                        <div
                            id="c1592"
                            class="ce-box  box box--white"
                            data-qa="ce-1592"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('MyCreditreform.boxes.box2.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('MyCreditreform.boxes.box2.text') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 white-box-col">
                        <div
                            id="c1594"
                            class="ce-box  box box--white"
                            data-qa="ce-1594"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('MyCreditreform.boxes.box3.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        keypath="MyCreditreform.boxes.box3.text"
                                        tag="p"
                                    >
                                        <em class="blue-italic-text">{{ $t('MyCreditreform.boxes.box3.italic') }}</em>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c2990"
            class=" clearfix grid-box grid-box--stageCenter box"
            data-qa="grid-box-2990"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div
                            id="c2988"
                            class="ce-box   box"
                            data-qa="ce-2988"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        keypath="MyCreditreform.tenReasonsBanner.text"
                                        tag="p"
                                    >
                                        <router-link
                                            to="/portal/mc/ueber-creditreform/10-gruende-fuer-creditreform"
                                            class="internal-link"
                                        >
                                            {{ $t('MyCreditreform.tenReasonsBanner.link') }}
                                        </router-link>
                                    </i18n-t>
                                </div>

                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-outer">
                                        <div class="ce-inner">
                                            <div class="ce-textpic__row">
                                                <div class="ce-textpic__column">
                                                    <div class="ce-textpic__media">
                                                        <router-link to="/portal/mc/ueber-creditreform/10-gruende-fuer-creditreform">
                                                            <img
                                                                src="@/assets/img/icons/10_reasons_for_creditreform_banner.png"
                                                                width="1260"
                                                                height="150"
                                                                alt="10 reasons for creditreform - icons banner"
                                                            >
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Contact />
    </div>
</template>

<style scoped>
.box--pearl {
    background-color: #edece5;
}

#c1296 {
    margin-top: 0;
}

.grid-box {
    display: block;
    margin: 3rem 0 2rem;
}

.box {
    padding: 1.5rem;
    position: relative;
}

.ce-textpic--left {
    &.ce-textpic--intext {
        .ce-textpic__gallery {
            float: left;
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
            .ce-textpic__media {
                max-width: 100%;
                float: left;
                padding: 0 20px 20px;
                @media (max-width: 1199px) {
                    padding: 0 20px 20px 0;
                    float: left;
                }
                .ce-textpic__img {
                    max-width: 100%;
                    width: 827px;
                    height: auto;
                    vertical-align: middle;
                    border: 0;
                }
            }
        }
    }
}

.ce-textpic--center {
    .ce-textpic__gallery {
        text-align: center;
        .ce-textpic__media {
            padding: 20px;
        }
    }
}

.box--white {
    background-color: #fff;
}
.white-boxes-row {
    .white-box-col {
        .white-box {
            height: 100%;
            .ce-textpic {
                height: inherit;
                .ce-textpic__bodytext {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: inherit;
                    &.text-only {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                    }
                    .white-box-link {
                        @media (max-width: 1199px) {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}

.ce-textpic--left.ce-textpic--nowrap .ce-textpic__bodytext {
    display: table;
}

.ce-textpic {
    display: block;
    overflow: hidden;
}
</style>
