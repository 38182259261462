<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

const id = 'portal.mc.help.credit-information'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('help.creditReports.breadcrumbs.creditReport') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c364"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-364"
                    >
                        <div
                            id="toc364"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-1207"
                                        class="toc__"
                                        href="/hilfe/bonitaetsauskunft/#c1207"
                                        @click.prevent="scrollTo('# cID')"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-1214"
                                                href="/hilfe/bonitaetsauskunft/#c1214"
                                                @click.prevent="scrollTo('#c1214')"
                                            >
                                                {{ $t('CreditReport.reports.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-379"
                                                href="/hilfe/bonitaetsauskunft/#c379"
                                                @click.prevent="scrollTo('#c379')"
                                            >
                                                {{ $t('CreditReport.reports.trafficLight.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-380"
                                                href="/hilfe/bonitaetsauskunft/#c380"
                                                @click.prevent="scrollTo('#c380')"
                                            >
                                                {{ $t('CreditReport.reports.brief.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-381"
                                                href="/hilfe/bonitaetsauskunft/#c381"
                                                @click.prevent="scrollTo('#c381')"
                                            >
                                                {{ $t('CreditReport.reports.compact.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-385"
                                                href="/hilfe/bonitaetsauskunft/#c385"
                                                @click.prevent="scrollTo('#c385')"
                                            >
                                                {{ $t('CreditReport.reports.commercial.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-391"
                                                href="/hilfe/bonitaetsauskunft/#c391"
                                                @click.prevent="scrollTo('#c391')"
                                            >
                                                {{ $t('CreditReport.reports.premium.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2193"
                                        class="toc__"
                                        href="/hilfe/bonitaetsauskunft/#c2193"
                                        @click.prevent="scrollTo('# cID')"
                                    />
                                    <ul class="toc__subList">
                                        <li
                                            class="toc__subListItem"
                                        >
                                            <a
                                                data-qa="toc-listItem-2194"
                                                href="/hilfe/bonitaetsauskunft/#c2194"
                                                @click.prevent="scrollTo('#c2194')"
                                            >
                                                {{ $t('CreditReport.research.title') }}
                                            </a>
                                        </li>
                                        <li
                                            class="toc__subListItem"
                                        >
                                            <a
                                                data-qa="toc-listItem-2556"
                                                href="/hilfe/bonitaetsauskunft/#c2556"
                                                @click.prevent="scrollTo('#c2556')"
                                            >
                                                {{ $t('CreditReport.upgradeConcept.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2029"
                                                href="/hilfe/bonitaetsauskunft/#c2029"
                                                @click.prevent="scrollTo('#c2029')"
                                            >
                                                {{ $t('CreditReport.feedback.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2559"
                                                href="/hilfe/bonitaetsauskunft/#c2559"
                                                @click.prevent="scrollTo('#c2559')"
                                            >
                                                {{ $t('CreditReport.dataQuality.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-364"
                                        class="toc__"
                                        href="/hilfe/bonitaetsauskunft/#c364"
                                        @click.prevent="scrollTo('# cID')"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c1207"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-1207"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />

                                        <div
                                            id="c1214"
                                            class="ce-box   box"
                                            data-qa="ce-1214"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--above">
                                                <div class="ce-header">
                                                    <h1>
                                                        {{ $t('CreditReport.reports.title') }}
                                                    </h1>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/bonitaetsauskunft/cp-uebersicht-auskuenfte.png"
                                                                    width="1000"
                                                                    height="400"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext" />
                                            </div>
                                        </div>

                                        <div
                                            id="c379"
                                            class="ce-box   box"
                                            data-qa="ce-379"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.reports.trafficLight.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <router-link
                                                                    :to="{ name: 'help-traffic-light-report' }"
                                                                >
                                                                    <img
                                                                        src="@/assets/img/help/bonitaetsauskunft/Ampelauskunft_star.png"
                                                                        width="665"
                                                                        height="349"
                                                                        alt=""
                                                                    >
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <h4>{{ $t('CreditReport.reports.trafficLight.subtitle') }}</h4>
                                                    <p>{{ $t('CreditReport.reports.trafficLight.text') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('CreditReport.reports.trafficLight.ul.li1') }}</li>
                                                        <li>{{ $t('CreditReport.reports.trafficLight.ul.li2') }}</li>
                                                        <li>{{ $t('CreditReport.reports.trafficLight.ul.li3') }}</li>
                                                        <li>{{ $t('CreditReport.reports.trafficLight.ul.li4') }}</li>
                                                        <li>{{ $t('CreditReport.reports.trafficLight.ul.li5') }}</li>
                                                    </ul>
                                                    <p>
                                                        <router-link
                                                            :to="{ name: 'help-traffic-light-report' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.reports.trafficLight.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c380"
                                            class="ce-box   box"
                                            data-qa="ce-380"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.reports.brief.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <router-link :to="{ name: 'help-brief-report' }">
                                                                    <img
                                                                        src="@/assets/img/help/bonitaetsauskunft/Kurzauskunft_star.png"
                                                                        width="665"
                                                                        height="349"
                                                                        alt=""
                                                                    >
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <h4>{{ $t('CreditReport.reports.brief.subtitle') }}</h4>
                                                    <p>{{ $t('CreditReport.reports.brief.text') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('CreditReport.reports.brief.ul.li1') }}</li>
                                                        <li>{{ $t('CreditReport.reports.brief.ul.li2') }}</li>
                                                        <li>{{ $t('CreditReport.reports.brief.ul.li3') }}</li>
                                                        <li>{{ $t('CreditReport.reports.brief.ul.li4') }}</li>
                                                        <li>{{ $t('CreditReport.reports.brief.ul.li5') }}</li>
                                                    </ul>
                                                    <p>
                                                        <router-link
                                                            :to="{ name: 'help-brief-report' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.reports.brief.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c381"
                                            class="ce-box   box"
                                            data-qa="ce-381"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.reports.compact.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <router-link :to="{ name: 'help-compact-report' }">
                                                                    <img
                                                                        src="@/assets/img/help/bonitaetsauskunft/Kompaktauskunft_star.png"
                                                                        width="665"
                                                                        height="349"
                                                                        alt=""
                                                                    >
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <h4>{{ $t('CreditReport.reports.compact.subtitle') }}</h4>
                                                    <p>{{ $t('CreditReport.reports.compact.text') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('CreditReport.reports.compact.ul.li1') }}</li>
                                                        <li>{{ $t('CreditReport.reports.compact.ul.li2') }}</li>
                                                        <li>{{ $t('CreditReport.reports.compact.ul.li3') }}</li>
                                                        <li>{{ $t('CreditReport.reports.compact.ul.li4') }}</li>
                                                    </ul>
                                                    <p>
                                                        <router-link
                                                            :to="{ name: 'help-compact-report' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.reports.compact.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c385"
                                            class="ce-box   box"
                                            data-qa="ce-385"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.reports.commercial.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <router-link :to="{ name: 'help-commercial-report' }">
                                                                    <img
                                                                        src="@/assets/img/help/bonitaetsauskunft/Wirtschaftsauskunft.png"
                                                                        width="665"
                                                                        height="349"
                                                                        alt=""
                                                                    >
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <h4>{{ $t('CreditReport.reports.commercial.subtitle') }}</h4>
                                                    <p>{{ $t('CreditReport.reports.commercial.text') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('CreditReport.reports.commercial.ul.li1') }}</li>
                                                        <li>{{ $t('CreditReport.reports.commercial.ul.li2') }}</li>
                                                        <li>{{ $t('CreditReport.reports.commercial.ul.li3') }}</li>
                                                        <li>{{ $t('CreditReport.reports.commercial.ul.li4') }}</li>
                                                        <li>{{ $t('CreditReport.reports.commercial.ul.li5') }}</li>
                                                        <li v-if="locale === 'en-US'">
                                                            {{ $t('CreditReport.reports.commercial.ul.li6.text') }}
                                                        </li>
                                                        <i18n-t
                                                            v-else
                                                            keypath="CreditReport.reports.commercial.ul.li6.text"
                                                            tag="li"
                                                        >
                                                            <a
                                                                href="/hilfe/watchlist/monitoring/"
                                                            >
                                                                {{ $t('CreditReport.reports.commercial.ul.li6.link') }}
                                                            </a>
                                                        </i18n-t>
                                                    </ul>
                                                    <p>
                                                        <router-link
                                                            :to="{ name: 'help-commercial-report' }"
                                                        >
                                                            {{ $t('CreditReport.reports.commercial.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c391"
                                            class="ce-box   box"
                                            data-qa="ce-391"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.reports.premium.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <router-link :to="{ name: 'help-premium-report' }">
                                                                    <img
                                                                        src="@/assets/img/help/bonitaetsauskunft/Premiumauskunft.png"
                                                                        width="665"
                                                                        height="349"
                                                                        alt=""
                                                                    >
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <h4>{{ $t('CreditReport.reports.premium.subtitle') }}</h4>
                                                    <p>{{ $t('CreditReport.reports.premium.text') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('CreditReport.reports.premium.ul.li1') }}</li>
                                                        <li>{{ $t('CreditReport.reports.premium.ul.li2') }}</li>
                                                        <li>{{ $t('CreditReport.reports.premium.ul.li3') }}</li>
                                                        <li v-if="locale === 'en-US'">
                                                            {{ $t('CreditReport.reports.premium.ul.li4.text') }}
                                                        </li>
                                                        <i18n-t
                                                            v-else
                                                            keypath="CreditReport.reports.premium.ul.li4.text"
                                                            tag="li"
                                                        >
                                                            <a
                                                                href="/hilfe/watchlist/monitoring/"
                                                                title="Opens internal link in current window"
                                                                class="internal-link"
                                                            >
                                                                {{ $t('CreditReport.reports.premium.ul.li4.link') }}
                                                            </a>
                                                        </i18n-t>
                                                    </ul>
                                                    <p>
                                                        <router-link
                                                            :to="{ name: 'help-premium-report' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.reports.premium.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="c2193"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-2193"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />

                                        <div
                                            id="c2194"
                                            class="ce-box   box"
                                            data-qa="ce-2194"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.research.title') }}</h3>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <i18n-t
                                                        keypath="CreditReport.research.text1"
                                                        tag="p"
                                                    >
                                                        <router-link
                                                            :to="{ name: 'order-report' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.research.link') }}
                                                        </router-link>
                                                    </i18n-t>
                                                    <p>{{ $t('CreditReport.research.text2') }}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c2556"
                                            class="ce-box   box"
                                            data-qa="ce-2556"
                                        >
                                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.upgradeConcept.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/bonitaetsauskunft/upgrade-konzept.gif"
                                                                    width="420"
                                                                    height="280"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <p>
                                                        {{ $t('CreditReport.upgradeConcept.text') }}
                                                        <router-link
                                                            :to="{ name: 'help-credit-information-upgrade' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.upgradeConcept.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c2029"
                                            class="ce-box   box"
                                            data-qa="ce-2029"
                                        >
                                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h1>
                                                        {{ $t('CreditReport.feedback.h1') }}
                                                    </h1>
                                                    <h3>{{ $t('CreditReport.feedback.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/bonitaetsauskunft/qualitaetsverbesserer.jpeg"
                                                                    width="800"
                                                                    height="600"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <p>
                                                        {{ $t('CreditReport.feedback.text') }}
                                                        <router-link
                                                            :to="{ name: 'help-credit-information-feedback' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.feedback.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c2559"
                                            class="ce-box   box"
                                            data-qa="ce-2559"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('CreditReport.dataQuality.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/bonitaetsauskunft/Fotolia_114184953_S.jpeg"
                                                                    width="849"
                                                                    height="566"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('CreditReport.dataQuality.text') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('CreditReport.dataQuality.ul.li1') }}</li>
                                                        <li>{{ $t('CreditReport.dataQuality.ul.li2') }}</li>
                                                        <li>{{ $t('CreditReport.dataQuality.ul.li3') }}</li>
                                                        <li>{{ $t('CreditReport.dataQuality.ul.li4') }}</li>
                                                        <li>{{ $t('CreditReport.dataQuality.ul.li5') }}</li>
                                                    </ul>
                                                    <p>
                                                        <router-link
                                                            :to="{ name: 'data-sources' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('CreditReport.dataQuality.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--left {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--left.ce-textpic--left {
    .ce-textpic__gallery {
        float: left;
    }
    .ce-textpic__media {
        float: left;
        padding-right: 20px;
        padding-left: 0;
    }
}
.ce-textpic--right.ce-textpic--left {
    .ce-textpic__gallery {
        float: left;
    }
    .ce-textpic__media {
        float: left;
        padding-right: 20px;
        padding-left: 0;
    }
}
.ce-textpic--left.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
.ce-textpic--left.ce-textpic--above {
    .ce-textpic__gallery {
        float: none;
        max-width: 100%;
    }
    .ce-textpic__media {
        float: none;
        display: block;
        padding: 20px 0;
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--above {
    .ce-textpic__gallery {
        float: none;
        max-width: 100%;
    }
    .ce-textpic__media {
        float: none;
        display: block;
        padding: 20px 0;
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--left {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

.box--pearl {
    background-color: #edece5;
}
</style>
