<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { getFileUrlInkasso } from '@/helper/image'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.debt-collection-ikaros'

</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('debtCollectionIkaros.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c2751"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-2751"
                    >
                        <div
                            id="toc2751"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2752"
                                        class="toc__"
                                        href="/hilfe/suche-in-meine-creditreform/#c2752"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2753"
                                                href="#"
                                                @click.prevent="scrollTo('#c9457')"
                                            >
                                                {{ $t('debtCollectionIkaros.sections.1.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2765"
                                                href="#"
                                                @click.prevent="scrollTo('#c9461')"
                                            >
                                                {{ $t('debtCollectionIkaros.sections.2.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2766"
                                                href="#"
                                                @click.prevent="scrollTo('#c9465')"
                                            >
                                                {{ $t('debtCollectionIkaros.sections.3.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-3549"
                                                href="#"
                                                @click.prevent="scrollTo('#c9469')"
                                            >
                                                {{ $t('debtCollectionIkaros.sections.4.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div id="c9457">
                        <h3>{{ $t('debtCollectionIkaros.sections.1.title') }}</h3>
                        <p>{{ $t('debtCollectionIkaros.sections.1.text') }}</p>
                    </div>
                    <div
                        id="c9461"
                        class="content-spacer"
                    >
                        <h3>{{ $t('debtCollectionIkaros.sections.2.title') }}</h3>

                        <p>{{ $t('debtCollectionIkaros.sections.2.text') }}</p>
                    </div>
                    <div
                        id="c9465"
                        class="content-spacer"
                    >
                        <h3>{{ $t('debtCollectionIkaros.sections.3.title') }}</h3>

                        <p>{{ $t('debtCollectionIkaros.sections.3.text') }}</p>
                    </div>
                    <div
                        id="c9469"
                        class="content-spacer"
                    >
                        <h3>{{ $t('debtCollectionIkaros.sections.4.title') }}</h3>

                        <p>{{ $t('debtCollectionIkaros.sections.4.text') }}</p>
                        <p>
                            <a :href="getFileUrlInkasso('2023-08-14_AA_Benutzerhandbuch_Inkasso4.0inMC_V1.18.pdf')">
                                ➜  {{ $t('debtCollectionIkaros.sections.4.linkText') }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

</style>
