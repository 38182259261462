<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('pageNotFound.header') }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="marketing mb">
                        <span>{{ $t('pageNotFound.header') }}</span>
                    </h1>
                    <p class="mb">
                        {{ $t('pageNotFound.message') }}
                    </p>
                    <a
                        href="/"
                        class="btn btn-default"
                    >
                        {{ $t('pageNotFound.buttonText') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.page-not-found'
</script>
