import { WatchlistSort } from '@/enums/watchlist'

export enum WatchlistStates {
    SUPPLEMENT_INACTIVE = 'SUPPLEMENT_INACTIVE',
    SUPPLEMENT_ACTIVE = 'SUPPLEMENT_ACTIVE',
    MONITORING_INACTIVE = 'MONITORING_INACTIVE',
    MONITORING_ACTIVE = 'MONITORING_ACTIVE',
    UNDECIDABLE = 'UNDECIDABLE',
    SIGNAL = 'SIGNAL',
    SIGNAL_ACTIVE = 'SIGNAL_ACTIVE',
    SIGNAL_INACTIVE = 'SIGNAL_INACTIVE',
}

export type WatchlistSearchOptions = {
    searchText: string
    pageSize: number
    pageStartIndex: number
    sort: string
    watchlistFilter: string[]
}

export type WatchlistEntry = {
    id: string
    businessId: string
    businessName: string
    city: string
    countryCode: string
    createdTimestamp: number
    eventEndDate: number | null
    lastEventTimestamp: number | null
    lastSignalReasonIdList: string[] | null
    lastSupplementReasonIdList: string[] | null
    memberId: string
    memberUserId: string
    monitoring: boolean
    monitoringEndDate: number | null
    observationStateEnum: WatchlistStates
    supplementEndDate: number | null
    transactionId: string
    wkvLink: string | null
    wkvRuvStatus: string
    zipCode: string
}

export type WatchlistEntryResponse = {
    listSize: number
    watchlistEntries: WatchlistEntry[]
}

interface Validations {
    watchlistEntry: WatchlistEntry
}
export type Watchlist = Array<Validations>

export type WatchListCookie = {
    sortMode: WatchlistSort
    filterModes: WatchListCookieFilterModes | null
    pagerPageSize: number
    filterPreference: boolean
}

// TODO: Validate if boolean | null is nessesary or if boolean is enough
export type WatchListCookieFilterModes = {
    observationType: string | null
    expiringObservations: boolean | null
    expiredObservations: boolean | null
}

type WatchlistValidation = {
    businessId: string
    supported: boolean
    watchlistEntry: WatchlistEntry
}

export type WatchlistValidateResponse = WatchlistValidation[]
