<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('data.privacy.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container mb">
            <div class="row">
                <div
                    id="dataCollectionStorage"
                    class="col-12 col-md-8"
                >
                    <div
                        id="storageOfData"
                        class="mb-large"
                    >
                        <h1>{{ $t('data.privacy.title') }}</h1>
                        <p>{{ $t('data.privacy.intro') }}</p>
                    </div>
                    <!-- Sections content -->
                    <div
                        class="section mb"
                    >
                        <h2>{{ $t('data.privacy.dataCollectionStorage.title') }}</h2>
                        <h3>{{ $t('data.privacy.dataCollectionStorage.dataStorage') }}</h3>
                        <p>{{ $t('data.privacy.dataCollectionStorage.dataStorageDescription') }}</p>
                    </div>

                    <div
                        id="onlineServicesAndProviders"
                        class="section mb"
                    >
                        <h3>{{ $t('data.privacy.onlineServicesProviders.title') }}</h3>
                        <p>{{ $t('data.privacy.onlineServicesProviders.webtrekkInfo') }}</p>
                        <p>{{ $t('data.privacy.onlineServicesProviders.dataCollection') }}</p>
                        <p>{{ $t('data.privacy.onlineServicesProviders.collectedData') }}</p>
                        <p>{{ $t('data.privacy.onlineServicesProviders.optOutInfo') }}</p>
                    </div>

                    <div
                        id="cookieUsage"
                        class="section mb"
                    >
                        <h3>{{ $t('data.privacy.cookieUsage.title') }}</h3>
                        <p>{{ $t('data.privacy.cookieUsage.description') }}</p>
                    </div>

                    <div
                        id="salesWingsCookies"
                        class="section mb-large"
                    >
                        <h3>{{ $t('data.privacy.salesWingsCookies.title') }}</h3>
                        <p>{{ $t('data.privacy.salesWingsCookies.description') }}</p>
                    </div>

                    <div
                        id="dataSecurity"
                        class="section"
                    >
                        <h2>{{ $t('data.privacy.dataSecurity.title') }}</h2>
                        <h3 id="sslEncryption">
                            {{ $t('data.privacy.dataSecurity.sslEncryption') }}
                        </h3>
                        <p>{{ $t('data.privacy.dataSecurity.sslDescription') }}</p>
                        <h3>{{ $t('data.privacy.dataSecurity.evCertificate') }}</h3>
                        <p id="extendedValidationCertificate">
                            {{ $t('data.privacy.dataSecurity.evCertificateDescription') }}
                        </p>
                    </div>
                </div>

                <!-- Sticky Sidebar -->
                <div class="col-12 col-md-4">
                    <div class="sticky-top">
                        <ul
                            class="list-unstyled"
                            style="list-style: none;"
                        >
                            <li
                                v-for="section in sections"
                                :key="section.id"
                                class="sidebar-links"
                            >
                                <a
                                    :href="`#${section.id}`"
                                    @click.prevent="scrollTo(`#${section.id}`)"
                                >
                                    {{ section.title }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Contact />
    </div>
</template>

<script setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Contact from '@/components/Teaser/Contact.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.data-privacy'

const sections = [
    { id: 'dataCollectionStorage', title: 'Datenschutz bei Creditreform' },
    { id: 'dataCollectionStorage', title: 'Erhebung und Speicherung von Daten in diesem Angebot' },
    { id: 'storageOfData', title: 'Speicherung von Daten' },
    { id: 'onlineServicesAndProviders', title: 'Online-Dienste und Online-Dienstleister' },
    { id: 'cookieUsage', title: 'Einsatz von Cookies' },
    { id: 'salesWingsCookies', title: 'SalesWings Cookies' },
    { id: 'dataSecurity', title: 'Datensicherheit' },
    { id: 'sslEncryption', title: 'SSL-Verschlüsselung' },
    { id: 'extendedValidationCertificate', title: 'Zertifikat mit erweiterter Prüfung' }
]
</script>

<style scoped>
.sidebar-links {
    padding: 0.5em 1em
}
</style>
