<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('help.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>{{ $t('help.title') }}</h1>
                    <router-link
                        :to="{ name: 'faq' }"
                        :title="$t('help.1')"
                    >
                        {{ $t('help.1') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'esg-services' }"
                        :title="$t('help.2')"
                    >
                        {{ $t('help.2') }}
                    </router-link>

                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'new-navigation' }"
                        :title="$t('help.3')"
                    >
                        {{ $t('help.3') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-inbox' }"
                        :title="$t('help.5')"
                    >
                        {{ $t('help.5') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'risk-management' }"
                        :title="$t('help.6')"
                    >
                        {{ $t('help.6') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'credit-information' }"
                        :title="$t('help.7')"
                    >
                        {{ $t('help.7') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-member-navigation' }"
                        :title="$t('help.8')"
                    >
                        {{ $t('help.8') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-member-admin' }"
                        :title="$t('help.9')"
                    >
                        {{ $t('help.9') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-wkv' }"
                        :title="$t('help.10')"
                    >
                        {{ $t('help.10') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'crefo-eva' }"
                        :title="$t('help.11')"
                    >
                        {{ $t('help.11') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-search' }"
                        :title="$t('help.12')"
                    >
                        {{ $t('help.12') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-debt-collection' }"
                        :title="$t('help.13')"
                    >
                        {{ $t('help.13') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-debt-collection-ikaros' }"
                        :title="$t('help.14')"
                    >
                        {{ $t('help.14') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-e-mails' }"
                        :title="$t('help.15')"
                    >
                        {{ $t('help.15') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-watchlist' }"
                        :title="$t('help.16')"
                    >
                        {{ $t('help.16') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-consumer' }"
                        :title="$t('help.17')"
                    >
                        {{ $t('help.17') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-processes-mails' }"
                        :title="$t('help.18')"
                    >
                        {{ $t('help.18') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-my-profile' }"
                        :title="$t('help.19')"
                    >
                        {{ $t('help.19') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'my-membership' }"
                        :title="$t('help.20')"
                    >
                        {{ $t('help.20') }}
                    </router-link>
                    <hr class="mt mb">
                    <router-link
                        :to="{ name: 'help-auskunftsexport' }"
                        :title="$t('help.21')"
                    >
                        {{ $t('help.21') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.help'

</script>
