<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.crefo-eva'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>CrefoEVA</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c7183"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-7183"
                    >
                        <div
                            id="toc7183"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-7185"
                                        class="toc__"
                                        href="/hilfe/crefoeva/#c7185"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7187"
                                                href="/hilfe/crefoeva/#c7187"
                                                @click.prevent="scrollTo('#c7187')"
                                            >
                                                Zahlungsausfälle vermeiden dank Absicherung
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7189"
                                                href="/hilfe/crefoeva/#c7189"
                                                @click.prevent="scrollTo('#c7189')"
                                            >
                                                Die Produktdetails im Überblick
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7191"
                                                href="/hilfe/crefoeva/#c7191"
                                                @click.prevent="scrollTo('#c7191')"
                                            >
                                                So funktioniert der Versicherungsschutz über CrefoEVA
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7193"
                                                href="/hilfe/crefoeva/#c7193"
                                                @click.prevent="scrollTo('#c7193')"
                                            >
                                                Ihre Vorteile mit CrefoEVA
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-7195"
                                        class="toc__"
                                        href="/hilfe/crefoeva/#c7195"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7199"
                                                href="/hilfe/crefoeva/#c7199"
                                            />
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7197"
                                                href="/hilfe/crefoeva/#c7197"
                                            />
                                        </li>
                                    </ul>
                                </li>

                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-7241"
                                        class="toc__"
                                        href="/hilfe/crefoeva/#c7241"
                                    />
                                    <ul class="toc__subList" />
                                </li>

                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-7201"
                                        class="toc__"
                                        href="/hilfe/crefoeva/#c7201"
                                    />
                                    <ul class="toc__subList" />
                                </li>

                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-7183"
                                        class="toc__"
                                        href="/hilfe/crefoeva/#c7183"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c7185"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-7185"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />

                                        <div
                                            id="c7187"
                                            class="ce-box   box"
                                            data-qa="ce-7187"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>Zahlungsausfälle vermeiden dank Absicherung </h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/crefoEVA/crefoeva-400x400.jpeg"
                                                                    width="400"
                                                                    height="400"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <p>Mit CrefoEVA bieten wir Ihnen eine flexible <b>Forderungsausfallversicherung für einzelne Geschäfte</b> an, die Sie exklusiv in <i>Meine Creditreform</i> abschließen können. Sollte Ihre Rechnung innerhalb von 40 Tagen nach Versicherungsende nicht beglichen worden sein, übergeben Sie Ihre Forderung an das Creditreform Inkasso. Bleibt das Inkasso erfolglos, tritt der <b>Versicherungsfall</b> ein.<br><br>Haben Sie sich schon immer eine unkomplizierte Ad hoc-Versicherung gewünscht? <br>Unsere Forderungsausfallversicherung CrefoEVA erfüllt genau diesen Wunsch. Sie können einzelne Geschäftsaufträge <b>einfach online versichern</b>. Damit werden Sie vor unbezahlten Rechnungen geschützt.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c7189"
                                            class="ce-box  box"
                                            data-qa="ce-7189"
                                        >
                                            <div class="ce-header">
                                                <h3>Die Produktdetails im Überblick </h3>
                                            </div>

                                            <div class="table-responsive ">
                                                <table class="table col-12">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Stufenweise einstellbare Versicherungslaufzeiten von 30, 60 oder 90 Tagen
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Individuell bestimmbare Versicherungssummen von 5.000 bis 20.000 Euro
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Auszahlung von 90 % der Forderung im Schadenfall
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div
                                            id="c7191"
                                            class="ce-box   box"
                                            data-qa="ce-7191"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3>So funktioniert der Versicherungsschutz über CrefoEVA </h3>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <ol class="list-ordered">
                                                        <li>Sie rufen die <b>Wirtschafts- oder Premiumauskunft</b> ab.</li><li>Wählen Sie die Option Forderungsausfallversicherung CrefoEva aus.</li><li>Geben Sie die gewünschte Versicherungssumme sowie Laufzeit im Beitragsrechner ein.</li><li>Der Beitrag wird errechnet. Bestätigen Sie den Kauf der Versicherung.</li><li>Sobald Sie die Rechnung von Atradius bezahlt haben, gilt der Versicherungsschutz.</li><li>Hat der Kunde nicht gezahlt? Wir übernehmen das Inkasso.</li><li>War das Inkasso nicht erfolgreich? Atradius erstattet Ihnen den Forderungsausfall.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="c7193"
                                            class="ce-box   box"
                                            data-qa="ce-7193"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>Ihre Vorteile mit CrefoEVA</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <figure class="ce-textpic__media ce-textpic__media--with-iframe">
                                                                <img
                                                                    title="Download: Produktblatt CrefoEVA"
                                                                    alt="Download: Produktblatt CrefoEVA"
                                                                    src="@/assets/img/help/crefoEVA/csm_050_64dc1a9217.png"
                                                                    width="100"
                                                                    height="100"
                                                                >

                                                                <figcaption>
                                                                    Download: Produktblatt
                                                                </figcaption>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="ce-textpic__bodytext">
                                                    <ul class="list-unordered">
                                                        <li>Unkomplizierter Abschluss ohne dauerhafte Bindung</li><li>Schnelle Entschädigung im Schadenfall</li><li>Alles aus einer Hand: Auskunft, Versicherung, Inkasso</li><li>Starke Lösung durch starke Partner – Creditreform als Marktführer im Bereich Wirtschaftsinformationen/Inkasso und die renommierte Atradius Kreditversicherung</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="c7195"
                        class=" clearfix grid-box grid-box--2cols box box--pearl"
                        data-qa="grid-box-7195"
                    >
                        <div class="container container--crefo">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div
                                        id="c7197"
                                        class="ce-box  box box--white"
                                        data-qa="ce-7197"
                                    >
                                        <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                            <div class="ce-textpic__bodytext">
                                                <blockquote>
                                                    <h4>Sie möchten eine Wirtschaftsauskunft abrufen?</h4>
                                                    <h4>
                                                        <a
                                                            href="/bonitaetsauskunft/bonitaet-einer-firma-pruefen/"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        > ➜ Sichern Sie Ihr Geschäft jetzt zusätzlich ab</a>
                                                    </h4>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div
                                        id="c7199"
                                        class="ce-box  box box--white"
                                        data-qa="ce-7199"
                                    >
                                        <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                            <div class="ce-textpic__bodytext">
                                                <blockquote>
                                                    <h4>Sie haben bereits eine Auskunft abgerufen? </h4>
                                                    <h4>
                                                        <router-link
                                                            :to="{ name: 'transactions' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            <span class="code" />➜ Auf die Wirtschaftsauskunft upgraden und noch absichern
                                                        </router-link>
                                                    </h4>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="c7241"
                        class="ce-box   box"
                        data-qa="ce-7241"
                    >
                        <div class="ce-textpic ce-textpic--center ce-textpic--above">
                            <div class="ce-textpic__bodytext">
                                <h4>Sie haben Fragen? Wenden Sie sich gerne an: </h4>
                                <ul class="list-unordered">
                                    <li><h4>Telefon: 02131/109-300</h4></li><li><h4>E-Mail: mc@creditreform.de</h4></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div
                        id="c7201"
                        class="ce-box  box box--pearl"
                        data-qa="ce-7201"
                    >
                        <div class="ce-textpic ce-textpic--center ce-textpic--above">
                            <div class="ce-textpic__bodytext">
                                <p>Wenn Sie mehr zum Thema CrefoEVA erfahren wollen, wenden Sie sich bitte gerne für konkrete Produktberatung an die Experten des Verbandes der Vereine Creditreform e.V., die über die entsprechende Vermittlererlaubnis verfügen. Den Kontakt zu den Experten des Verbands der Vereine Creditreform stellt Creditreform vor Ort gerne für Sie her.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic {
    display: block;
    overflow: hidden;
    &.ce-textpic--right {
        &.ce-textpic--intext .ce-textpic__media {
            max-width: 100%;
        }
        .ce-textpic__gallery {
            float: right;
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
            .ce-textpic__media {
                float: right;
                padding-left: 20px;
                padding-bottom: 20px;
                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
            }
        }
    }
    &.ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;
            .ce-textpic__media {
                padding: 20px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.box--pearl {
    background-color: #edece5;
}
.box--white {
    background-color: #fff;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
}
</style>
