<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="container">
        <div class="row mb">
            <div class="col-12">
                <LoadingRing v-if="isLoading" />
                <div
                    v-else
                    class="infobox-list-wrapper"
                >
                    <Alert
                        v-for="message in filteredMessages"
                        :key="message.uid"
                        @on-close="addClosedInfoboxId(message.uid)"
                    >
                        <span v-html="message.message" />
                    </Alert>
                    <Alert
                        v-if="
                            memberViewActive &&
                                !closedIdsState.includes(memberViewId)
                        "
                        @on-close="addClosedInfoboxId(memberViewId)"
                    >
                        <div
                            class="infoBoxMessage__data-info"
                            data-qa="infoBoxMessage-memberViewActive"
                        >
                            <div
                                class="infoBoxMessage__data-info-headline"
                                data-qa="infoBoxMessage-memberViewActive-headline"
                            >
                                {{
                                    $t(
                                        "selfcare.infoboxmessage.dataInfo.heading",
                                    )
                                }}
                            </div>
                            <div
                                class="infoBoxMessage__data-info-item"
                                data-qa="infoBoxMessage-memberViewActive-text"
                            >
                                {{
                                    $t(
                                        "selfcare.infoboxmessage.memberNavigation.reportAct4SbNumber.text",
                                    )
                                }}
                            </div>
                            <div
                                class="infoBoxMessage__data-info-item"
                                data-qa="infoBoxMessage-memberViewActive-additional_text"
                            >
                                {{
                                    $t(
                                        "selfcare.infoboxmessage.memberNavigation.reportAct4SbNumber.additional_text",
                                    )
                                }}
                            </div>
                        </div>
                    </Alert>

                    <Alert
                        v-if="hasLinks && !closedIdsState.includes(hasLinksId)"
                        @on-close="addClosedInfoboxId(hasLinksId)"
                    >
                        <p>
                            <b>{{
                                $t("selfcare.infoboxmessage.dataInfo.heading")
                            }}</b>
                        </p>

                        <div v-if="numberOfUnreadMessages > 0">
                            <p>
                                <a
                                    href="/portal/posteingang"
                                    data-qa="infobox-link-inbox"
                                >
                                    <span>{{ numberOfUnreadMessages }} </span>
                                    {{ numberOfUnreadMessagesText }}
                                </a>
                            </p>
                        </div>

                        <div
                            v-if="
                                numberOfChangedWatchlistEntriesSinceLastLogin >
                                    0
                            "
                        >
                            <p>
                                <a
                                    :href="watchlistPageUrl"
                                    data-qa="infobox-link-watchlist"
                                >
                                    <span>{{
                                        numberOfChangedWatchlistEntriesSinceLastLogin
                                    }}
                                    </span>
                                    {{ watchlistText }}
                                </a>
                            </p>
                        </div>

                        <div v-if="numberOfOpenSupplierInquiries > 0">
                            <p>
                                <a
                                    href="/portal/lieferantenrueckfrage/lieferantenrueckfrage-uebersicht"
                                    data-qa="infobox-link-supplierInquiries"
                                >
                                    <span>{{ numberOfOpenSupplierInquiries }}
                                    </span>
                                    {{ supplierinquiryText }}
                                </a>
                            </p>
                        </div>

                        <div v-if="numberOfExpiringObservations > 0">
                            <p>
                                <a
                                    :href="transactionsPageURL"
                                    data-qa="infobox-link-expiringObservations"
                                >
                                    <span>{{
                                        numberOfExpiringObservations
                                    }}</span>
                                    {{ expiringObservationText }}
                                </a>
                            </p>
                        </div>
                    </Alert>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import Alert from '@/components/Alert.vue'
import { checkMemberView } from '@/helper/services/memberNavigation'
import { SupplierInquiryStatus } from '@/helper/services/supplierInquiry'
import storage from '@/helper/storage'
import { CONSTS } from '@/helper/services/transaction'
import type { Typo3InfoboxMessage } from '@/types/infoboxMessage'
import { WatchlistSearchOptions } from '@/types/cbra/watchlist'

import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import LoadingRing from '../LoadingRing.vue'
import { InkassoType } from '@/enums/inkasso'
import { WatchlistFilter, WatchlistSort } from '@/enums/watchlist'
import { watchlistLinks, transactionsLinks } from '@/data/typo3Links'

import {
    isIkarosAvailableForMember,
    getUnreadMessagesCount,
    getCbraWatchlistentries,
    getCbraSupplierInquiries,
    getCbraTransactions,
} from '@/services'

import { tempMessages } from './InfoboxTempMessage'

const userStore = useUserStore()

const { t, locale } = useI18n()

const isLoading = ref(false)
const memberViewActive = ref(false)
const isIkaros = ref<boolean>(false)
const numberOfUnreadMessages = ref(0)
const numberOfOpenSupplierInquiries = ref(0)
const numberOfChangedWatchlistEntriesSinceLastLogin = ref(0)
const numberOfExpiringObservations = ref(0)
const hasLinksId = ref('8ccfa7f5-fb4e-47bb-aae6-7d807769ba38')
const memberViewId = ref('c47f68fb-494b-4fff-9b63-d9426c85987f')
const closedIdsState = ref([])

// Mocked data, can be deleted when real data is available in TYPO3
// const allMessages: Typo3InfoboxMessage[] = [
//     {
//         uid: 1,
//         languageId: 0,
//         message: "<p>test1</p>",
//         starttime: "0",
//         endtime: "0",
//         recurring: 0,
//         ikaros: 0,
//     },
//     {
//         uid: 4,
//         languageId: 1,
//         message: "<p>testEng</p>",
//         starttime: "0",
//         endtime: "0",
//         recurring: 0,
//         ikaros: 0,
//     },
//     {
//         uid: 7,
//         languageId: 2,
//         message: "<p>testFR</p>",
//         starttime: "0",
//         endtime: "0",
//         recurring: 0,
//         ikaros: 0,
//     },
//     {
//         uid: 13,
//         languageId: 0,
//         message: "<p>Test</p>",
//         starttime: "0",
//         endtime: "0",
//         recurring: 1,
//         ikaros: 1,
//     },
//     {
//         uid: 16,
//         languageId: 0,
//         message: '<p>Challo <span class="text-danger">Marc</span></p>',
//         starttime: "0",
//         endtime: "0",
//         recurring: 0,
//         ikaros: 2,
//     },
// ];

const filteredMessages = computed(() => {
    const tempM = tempMessages.filter(
        (item) => !closedIdsState.value.includes(item.uid),
    )
    return tempM.filter((message) => messageIsVisible(message.uid))
})

const numberOfUnreadMessagesText = computed(() => {
    return numberOfUnreadMessages.value > 0
        ? t('activity.info.unread.messages.plural')
        : t('activity.info.unread.messages.singular')
})

const watchlistText = computed(() => {
    return numberOfChangedWatchlistEntriesSinceLastLogin.value > 0
        ? t('watchlist.info.unread.messages.plural')
        : t('watchlist.info.unread.messages.singular')
})

const supplierinquiryText = computed(() => {
    return numberOfOpenSupplierInquiries.value > 0
        ? t('supplierinquiry.infomessage.open.plural')
        : t('supplierinquiry.infomessage.open.singular')
})

const expiringObservationText = computed(() => {
    return numberOfExpiringObservations.value > 0
        ? t('activity.info.expiringObservations.plural')
        : t('activity.info.expiringObservations.singular')
})

const hasLinks = computed(
    () =>
        numberOfUnreadMessages.value > 0 ||
        numberOfChangedWatchlistEntriesSinceLastLogin.value > 0 ||
        numberOfOpenSupplierInquiries.value > 0 ||
        numberOfExpiringObservations.value > 0,
)

const watchlistPageUrl = computed(() => watchlistLinks[locale.value])
const transactionsPageURL = computed(
    () =>
        `${transactionsLinks[locale.value]}?sortMode=OBSERVATION_END_TIME_ASC&filterMode=REPORT&filterParameters=%7B%22observationType%22%3A%22ALL_OBSERVATION%22%2C%22observationExpirationType%22%3A%22EXPIRING_OBSERVATIONS%22%7D`,
)

async function getNumberOfChangedEntriesSinceLastLogin() {
    const search: WatchlistSearchOptions = {
        searchText: '',
        pageSize: 1,
        pageStartIndex: 0,
        sort: WatchlistSort.EVENT_TIMESTAMP_DESC,
        watchlistFilter: [WatchlistFilter.SINCE_LAST_LOGIN],
    }
    const { data } = await getCbraWatchlistentries(search)
        .catch((error) => {
            console.error(error)
            return { data: { listSize: 0 } }
        })
    return data.listSize
}

async function getExpiringObservationsCount() {
    const search = {
        pageSize: 2,
        pageStartIndex: 0,
        sort: CONSTS.SORT.OBSERVATION_END_TIME_DESC,
        transactionType: 'REPORT',
        favourites: false,
        observationType: CONSTS.REPORT_OBSERVATION_TYPE.ALL_OBSERVATION,
        observationExpirationType:
            CONSTS.REPORT_OBSERVATION_EXPIRE_TYPE.EXPIRING_OBSERVATIONS,
    }
    const { data } = await getCbraTransactions(search)
    return data.sizeTransactions
}

const messageIsVisible = (uid: number | string) => {
    const message = tempMessages.filter((item) => item.uid === uid)
    if (!messageHasLanguageId(message[0])) return false
    const messageIsVisible =
        !isLoading.value &&
        ((!isIkaros.value && message[0]?.ikaros !== 1) ||
            (isIkaros.value && message[0]?.ikaros !== 0))
    return messageIsVisible
}

const messageHasLanguageId = (message: Typo3InfoboxMessage): Boolean => {
    const messageLanguageIdMatch =
        (locale.value.startsWith('de') && message.languageId === 0) ||
        (locale.value.startsWith('en') && message.languageId === 1) ||
        (locale.value.startsWith('fr') && message.languageId === 2)
    return messageLanguageIdMatch
}

onMounted(async() => {
    // temporarly disabled
    // await getInfoboxMessages();

    isLoading.value = true
    await fetchIkaros()
    await initData()
    removeClosedInfoboxId(hasLinksId.value)
    removeClosedInfoboxId(memberViewId.value)
    removeRecurringIds()
    isLoading.value = false
})

async function fetchIkaros() {
    try {
        const ikarosMemberResponse = await isIkarosAvailableForMember()
        isIkaros.value =
            ikarosMemberResponse.data.inkassoType === InkassoType.IKAROS
    } catch (error) {
        console.error(error)
    }
}

async function initData() {
    if (localStorage.getItem('closedInfoboxes')) {
        closedIdsState.value = JSON.parse(
            localStorage.getItem('closedInfoboxes'),
        )
    }

    if (checkMemberView(userStore.cbraUser.crMembernumber)) {
        const unreadMessagesCountResp = await getUnreadMessagesCount(
            userStore.cbraUser.crMembernumber,
        )
        numberOfUnreadMessages.value = unreadMessagesCountResp.data
        numberOfChangedWatchlistEntriesSinceLastLogin.value =
            await getNumberOfChangedEntriesSinceLastLogin()

        const supplierinquiryResp = await getCbraSupplierInquiries({
            status: SupplierInquiryStatus.OPEN,
            pageSize: 3,
        })
        numberOfOpenSupplierInquiries.value =
            supplierinquiryResp.data.totalCount
        numberOfExpiringObservations.value =
            await getExpiringObservationsCount()
        return
    }
    memberViewActive.value = true
}

function removeRecurringIds() {
    const recurringIds: Array<string | number> = tempMessages
        .filter((message) => message.recurring === 1)
        .map((item) => item.uid)

    recurringIds.push(memberViewId.value, hasLinksId.value)
    recurringIds.forEach((id) => {
        removeClosedInfoboxId(id)
    })
}

watch(
    closedIdsState,
    (newValue) => {
        storage.set('closedInfoboxes', newValue, true)
    },
    { deep: true },
)

const addClosedInfoboxId = (id) => {
    if (closedIdsState.value.includes(id)) return
    closedIdsState.value.push(id)
}

const removeClosedInfoboxId = (id: string | number): void => {
    const index = closedIdsState.value.indexOf(id)
    if (index > -1) {
        closedIdsState.value.splice(index, 1)
    }
}
</script>

<style scoped lang="less">
.infobox-message {
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}

.infoBoxMessage__data-info-headline {
    font-weight: 700;
    margin-bottom: 15px;
}
</style>
