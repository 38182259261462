<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('gdpr.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>{{ $t('gdpr.breadcrumb') }}</h1>
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="col-12"
                    v-html="$t('gdpr.all_html')"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.eu-dsgvo'
</script>

