<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'
import { xmlContent } from './xmlContent'
import { csvContent } from './csvContent'
const id = 'portal.mc.help.auskunftsexport'

function downloadFile(format:'xml' | 'csv' = 'xml') {
    const content = format === 'xml' ? xmlContent : csvContent
    const type = format === 'xml' ? 'application/xml' : 'text/csv;charset=utf-8;'
    const blob = new Blob([content], { type: type })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = `example.${format}`
    document.body.appendChild(a)

    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(url)
}

</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('Auskunftsexport.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div
                        id="c5850"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-5850"
                    >
                        <div
                            id="toc5850"
                            class="toc fixedsticky"
                            data-bind="descendantsComplete: function() {window.bindWillow('Sticky');}"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-5862"
                                        class="toc__"
                                        href="/hilfe/auskunftsexport/#c5862"
                                        @click.prevent="scrollTo('#c5862')"
                                    >
                                        {{ $t('Auskunftsexport.title') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9">
                    <div
                        id="c5862"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-5862"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1>{{ $t('Auskunftsexport.title') }}</h1>
                                        <div
                                            id="c5858"
                                            class="ce-box   box"
                                            data-qa="ce-5858"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('Auskunftsexport.section1.title') }}</h3>
                                                </div>
                                                <!-- eslint-disable vue/no-v-html -->
                                                <p v-html="$t('Auskunftsexport.section1.text')" />

                                                <div class="ce-header">
                                                    <h3>{{ $t('Auskunftsexport.section2.title') }}</h3>
                                                </div>
                                                <p v-html="$t('Auskunftsexport.section2.text')" />

                                                <button
                                                    class="btn btn-default btn-xsd"
                                                    data-qa="auskunftsexport-example-file-button"
                                                    @click.prevent="downloadFile('csv')"
                                                >
                                                    {{ $t('Auskunftsexport.ExampleFileButton', {format: 'CSV'}) }}
                                                </button>

                                                <div class="ce-header">
                                                    <h3>{{ $t('Auskunftsexport.section3.title') }}</h3>
                                                </div>
                                                <p v-html="$t('Auskunftsexport.section3.text.p.1')" />
                                                <div class="section3-list">
                                                    <div v-html="$t('Auskunftsexport.section3.text.p.2')" />
                                                </div>
                                                <p v-html="$t('Auskunftsexport.section3.text.p.3')" />
                                                <a
                                                    class="btn btn-default btn-xsd"
                                                    data-qa="auskunftsexport-xsd-link"
                                                    href="/api/auskunftsexport/xsd/auskunftsexport_1_0.xsd"
                                                    download
                                                >
                                                    {{ $t('Auskunftsexport.XSDButton') }}
                                                </a><br><br>
                                                <button
                                                    class="btn btn-default btn-xsd"
                                                    data-qa="auskunftsexport-example-file-button"
                                                    @click.prevent="downloadFile()"
                                                >
                                                    {{ $t('Auskunftsexport.ExampleFileButton', {format: 'XML'}) }}
                                                </button>
                                                <div class="ce-header">
                                                    <h3>{{ $t('Auskunftsexport.section4.title') }}</h3>
                                                </div>
                                                <p v-html="$t('Auskunftsexport.section4.text')" />
                                                <!-- eslint-enable -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">

.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-header {
    margin-top: 40px;
}
</style>
