<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('contact.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>{{ $t('contact.header') }}</h1>
                </div>
            </div>
        </div>
        <form
            ref="formElement"
            :class="{'has-errors': !isValid }"
            @submit="onSubmit"
        >
            <div class="bg-brand-light-grey box-spacing">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-8 offset-md-2">
                            <Select
                                id="subject"
                                v-model="subject"
                                name="subject"
                                required
                                :label="$t('contact.subject')"
                                :options="subjects"
                                data-qa="contact-subject"
                                :disabled="submitSuccess"
                                :searchable="false"
                            />
                        </div>
                        <div
                            v-if="roleParam === 'Inkasso'"
                            class="col-12 col-md-8 offset-md-2"
                        >
                            <InputText
                                :model-value="debtorParam.toString()"
                                :label="$t('contact.debtor')"
                                name="debtor"
                                data-qa="contact-debtor"
                                :readonly="true"
                            />
                            <Textarea
                                id="contactAdditionalText"
                                v-model="additionalText"
                                :label="$t('contact.text')"
                                name="additionalText"
                                data-qa="contact-additionalText"
                                hidden
                            />
                        </div>
                        <div class="col-12 col-md-8 offset-md-2">
                            <Textarea
                                v-model="text"
                                :label="$t('contact.text')"
                                name="text"
                                data-qa="contact-text"
                                :placeholder="$t('contact.yourmessage')"
                                :max-length="5000"
                                :rows="8"
                                required
                                :disabled="submitSuccess"
                            />
                        </div>
                        <div class="col-12 col-md-8 offset-md-2">
                            <p>  <span data-qa="message-charCount">{{ charCountMessage }}</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mt">
                <div class="row">
                    <div class="col-12 text-align--center">
                        <button
                            v-if="!submitSuccess"
                            class="btn btn-default"
                            :disabled="submitButtonDisabled"
                            data-qa="contact-btn"
                        >
                            {{ $t('shared.sendForm') }}
                        </button>
                        <p
                            v-if="submitSuccess"
                            class="text-info"
                        >
                            {{ $t('error.MailService.success') }}
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <Modal
        v-if="errorAlert"
        :id="errorAlert.id"
        :title="errorAlert.title"
        :confirm-text="$t('shared.modals.ok')"
        :error-context="errorAlert.errorContext"
        :icon="errorAlert.icon"
        @on-close="errorAlert = undefined"
        @on-confirm="errorAlert = undefined"
    >
        <p>{{ errorAlert.content }}</p>
    </Modal>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import { computed, ref, onMounted, watch } from 'vue'
import Select from '@/components/Input/Select.vue'
import Textarea from '@/components/Input/Textarea.vue'
import { useRoute } from 'vue-router'
import { sendCbraMail } from '@/services'
import { toTypedSchema } from '@vee-validate/zod'
import { z } from 'zod'
import { useForm, useIsFormValid } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { REQUIRED_STRING_VALIDATION } from '@/helper/zodSchema'
import Modal from '@/components/Modal.vue'
import { ModalProps } from '@/types/components/modal'
import InputText from '@/components/Input/InputText.vue'

enum Role {
    ALLGEMEIN = 'Allgemein',
    VERTRIEB = 'Vertrieb',
    INKASSO = 'Inkasso',
    WIRTSCHAFTSINFORMATION = 'Wirtschaftsinformation',
}

const id = 'portal.mc.contact'

const route = useRoute()
const { t } = useI18n()

const roleParam = route.query['role']
const debtorParam = route.query['debtor'] || ''
const filenumberParam = route.query['filenumber'] || ''

const additionalTextValue = `${t('contact.dividerInkasso')}

${t('contact.debtor')}: ${debtorParam}
${t('contact.filenumber')}: ${filenumberParam}

${t('contact.noteInkasso')}`

const subjectsAndReceivers = [
    {
        subject: t('contact.subjects.membership'),
        role: Role.VERTRIEB
    },
    {
        subject: t('contact.subjects.meine_bonitaet'),
        role: Role.VERTRIEB
    },
    {
        subject: t('contact.subjects.ubo'),
        role: Role.WIRTSCHAFTSINFORMATION
    },
    {
        subject: t('contact.subjects.myesg'),
        role: Role.WIRTSCHAFTSINFORMATION
    },
    {
        subject: t('contact.subjects.myCompany'),
        role: Role.WIRTSCHAFTSINFORMATION
    },
    {
        subject: t('contact.subjects.consumer'),
        role: Role.VERTRIEB
    },
    {
        subject: t('contact.subjects.credentials'),
        role: Role.VERTRIEB
    },
    {
        subject: t('contact.subjects.question'),
        role: Role.WIRTSCHAFTSINFORMATION
    },
    {
        subject: t('contact.subjects.inkasso'),
        role: Role.INKASSO
    },
    {
        subject: t('contact.subjects.watchlist'),
        role: Role.WIRTSCHAFTSINFORMATION
    },
    {
        subject: t('contact.subjects.creditReport'),
        role: Role.WIRTSCHAFTSINFORMATION
    },
    {
        subject: t('contact.subjects.register'),
        role: Role.VERTRIEB
    },
    {
        subject: t('contact.subjects.pricing'),
        role: Role.VERTRIEB
    },
    {
        subject: t('contact.subjects.factoring'),
        role: Role.VERTRIEB
    },
    {
        subject: t('contact.subjects.other'),
        role: Role.ALLGEMEIN
    }
]

const subjects = subjectsAndReceivers.map((element) => {
    return {
        label: element.subject,
        code: element.subject
    }
})

onMounted(initPreselect)

const preselectedSubject = route.query['preselect_subject']
const role = ref(roleParam ? roleParam.toString() : '')
const subject = ref(roleParam ? findSubjectByRole(roleParam.toString()) : '')
const additionalText = ref(additionalTextValue)
const text = ref('')
const submitSuccess = ref(false)
const errorAlert = ref<ModalProps>()

watch(subject , (newValue) => {
    role.value = subjectsAndReceivers.find((element) => element.subject === newValue)?.role ?? ''
})

const formValidationSchema = toTypedSchema(
    z.object({
        subject: REQUIRED_STRING_VALIDATION(t),
        text: REQUIRED_STRING_VALIDATION(t),
        additionalText: z.string().optional()
    }),
)

const { handleSubmit, isSubmitting } = useForm({
    validationSchema: formValidationSchema
})

const isValid = useIsFormValid()

const submitButtonDisabled = computed(() => {
    return !isValid.value || isSubmitting.value
})

const charCountMessage = computed(() => `${t('collectionorder.new.still')} ${(5000 - text.value.length)} ${t('collectionorder.new.charsLeft')}`)

const onSubmit = handleSubmit(async(data) => {
    try {
        const payload = {
            contactCategory: role.value,
            subject: data.subject,
            text: data.text + (typeof data.additionalText === 'string' ? '\n\n' + data.additionalText.trim() : '')
        }
        await sendCbraMail(payload)
        submitSuccess.value = true
        errorAlert.value = {
            id: 'success',
            title: t('error.sweetalert.info'),
            content: t('error.MailService.success'),
            icon: 'info'
        }
    } catch (error) {
        errorAlert.value = {
            id: 'failed',
            title: t('error.sweetalert.error'),
            content: t('error.MailService.sendMail.description'),
            icon: 'error'
        }
    }
})

function initPreselect() {
    // Preselect Zugangsdaten, wenn rights als get mitgegeben wird
    if (preselectedSubject === 'rights') {
        subjects.map((item) => {
            // todo-sk: wenn französisch kommt, dann muss hier noch nachgebessert werden
            if (item.label === 'Zugangsdaten' || item.label === 'my login') {
                subject.value = item.code
            }
        })
    } else if (typeof preselectedSubject === 'string' && preselectedSubject.length) {
        const possiblePreselectedSubjects = [
            { preselectedSubject: 'myesg', subject: t('contact.subjects.myesg') },
            { preselectedSubject: 'mb', subject: t('contact.subjects.meine_bonitaet') },
            { preselectedSubject: 'ubo', subject: t('contact.subjects.ubo') }
        ]
        const matchingPreselectedSubject = possiblePreselectedSubjects
            .find(config => config.preselectedSubject === preselectedSubject)

        if (matchingPreselectedSubject) {
            const matchingSubject = subjects.find(item => item.label === matchingPreselectedSubject.subject)
            if (matchingSubject) {
                subject.value = matchingSubject.code
            }
        }
    } else {
        if (typeof document.referrer === 'string' && document.referrer.length) {
            if (document.referrer.indexOf(window.location.origin) === 0) {
                const referrers = [
                    { path: '/portal/myesg', subject: t('contact.subjects.myesg') },
                    { path: '/portal/meine-bonitaet', subject: t('contact.subjects.meine_bonitaet') },
                    { path: '/portal/ubo', subject: t('contact.subjects.ubo') }
                ]
                const referrerPath = document.referrer.substring(document.referrer.indexOf('meine.creditreform.de/') + 21)
                const matchingReferrer = referrers.find(referrer => referrerPath.indexOf(referrer.path) === 0)
                if (matchingReferrer) {
                    const matchingSubject = subjects.find(item => item.label === matchingReferrer.subject)
                    if (matchingSubject) {
                        subject.value = matchingSubject.code
                    }
                }
            }
        }
    }
}
function findSubjectByRole(role: string) {
    return subjectsAndReceivers.find((element) => element.role === role)?.subject ?? ''
}

</script>

<style scoped lang="less">

.text-info {
    color: var(--color-c-p1)
}

</style>
