// Temporary file used as a workaround while the Typo3 API for Crefo Web is inaccessible.
// TODO: Remove this file once the Typo3 API for Crefo Web becomes accessible.
import type { Typo3InfoboxMessage } from '@/types/infoboxMessage'
import { defineComponent } from 'vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default defineComponent({
    components: { Breadcrumb }
})

export const tempMessages: Typo3InfoboxMessage[] = []
