<template>
    <div :class="'scs-' + scsName">
        <router-view />
        <Modal
            v-if="errorContext"
            id="app-error"
            :error-context="errorContext"
            title="Fehler"
            icon="error"
            :confirm-text="$t('shared.modals.ok')"
            @on-close="clearError"
            @on-confirm="clearError"
        >
            <p>
                Es gab einen Fehler beim Laden des Meine Creditreform Frontends
            </p>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
// import { useRouter, useRoute } from 'vue-router'
import axios from '@/services/tracing-axios'

import Modal from '@/components/Modal.vue'
import { changeLocale } from '@/i18n'
import { useUserStore } from '@/stores/user'
import { postCbraCheckLogin } from './services'
import { showError } from './helper/services/error'
import { isAxiosError } from 'axios'
// import { ROLES, hasUserRole } from './helper/services/user'
// import { differentContextActive, getMemberNavigationSession, getMembersForMe, prepareMemberListForMemberNavigation, removeMemberNavigationSession, removeReportAct4SbNumberSession, setReportAct4SbNumberSession } from './helper/services/memberNavigation'
// import { useMembersStore } from './stores/members'

const scsName = import.meta.env.VITE_APP_PACKAGE_NAME
const errorContext = ref<null | ErrorContext>(null)
const userStore = useUserStore()
const { ssoChecked } = storeToRefs(userStore)
// const membersStore = useMembersStore()
// const router = useRouter()
// const route = useRoute()

/**
 * Don't remove any existing code here (unless you're 100% sure what you're doing)!
 */
axios.interceptors.request.use(
    async(config: any) => {
        if (window.keycloak?.token) {
            let tokenExpires = Math.round(
                window.keycloak.tokenParsed.exp +
                    window.keycloak.timeSkew -
                    new Date().getTime() / 1000,
            )
            if (tokenExpires < 10) {
                console.log(
                    '[axios] Token expires soon. Updating token before sending request',
                )
                await window.updateKeycloakToken()
            } else {
                console.log(
                    '[axios] Token still valid. No need to refresh before sending request',
                )
            }
            config.headers.Authorization = `Bearer ${window.keycloak.token}`
        }

        if (sessionStorage.getItem('actForMemberId')) {
            config.headers['X-Act-For-Member-Id']! =
                sessionStorage.getItem('actForMemberId')
        } else {
            delete config.headers['X-Act-For-Member-Id']
        }

        return config
    },
    (error) => Promise.reject(error),
)

if (!window.keycloak?.token) {
    checkTokenDelayed()
} else {
    checkToken()
}
initEventListeners()

onMounted(async() => {
    // not needed for testing local dev stuff
    /* c8 ignore start */
    if (import.meta.env.DEV) {
        console.log(`SCS ${import.meta.env.VITE_APP_PACKAGE_NAME}`)

        if (
            localStorage.getItem('axiosmock') &&
            localStorage.getItem('axiosmock') !== 'false'
        ) {
            const modules = import.meta.glob('./services/*-mock.ts')
            for (const path in modules) {
                modules[path]().then(() => {})
            }
        }
    }
    /* c8 ignore end */

    window.addEventListener('languageChanged', setLanguage, false)
    setLanguage()
})

// onMounted(checkPermissionForMemberNavigation)

onBeforeUnmount(() => {
    window.removeEventListener('languageChanged', setLanguage)
})

function resetInfoboxes() {
    localStorage.removeItem('closedInfoboxes')
}

function saveSession() {
    const sessionId = localStorage.getItem('sessionId')
    localStorage.setItem('sessionId', window.keycloak.sessionId)
    if (sessionId != window.keycloak.sessionId) resetInfoboxes()

    console.log(sessionId, window.keycloak.sessionId)
}

function setLanguage() {
    let clientLanguage = 'de-DE'

    if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
        let langCode = document.cookie
            .split('CAS_PREFERRED_LANGUAGE=')[1]
            .split(';')[0]
        if (langCode && langCode.length && /^[a-zA-Z_]{2,5}$/.test(langCode)) {
            clientLanguage = langCode.replace('_', '-')
        }
    }

    window.cp = window.cp || {}
    window.cp.language = clientLanguage.match(/([\w]{2})$/g)![0].toUpperCase()

    changeLocale(clientLanguage)
    userStore.onLanguageChange(clientLanguage)
}

async function checkToken() {
    if (!window.keycloak?.token) {
        return false
    }
    userStore.onValidTokenExists(window.keycloak)
    userStore.getCbraUser()

    return true
}

function checkTokenDelayed(timeout = 100) {
    setTimeout(async() => {
        const hasToken = await checkToken()
        const newTimeout = timeout * 2
        if (!hasToken && newTimeout <= 30000) {
            checkTokenDelayed(timeout * 2)
        }
    }, timeout)
}

function initEventListeners() {
    window.addEventListener(
        'loggedIn',
        () => {
            // Authenticate the user within this microfrontend
            // when a login event was dispatched from another microfrontend
            checkToken()
            saveSession()
        },
        false,
    )

    window.addEventListener(
        'loggedOut',
        () => {
            resetInfoboxes()
            localStorage.removeItem('sessionId')
        },
        false,
    )

    window.addEventListener(
        'actForMemberChanged',
        () => {
            let actForMemberId = sessionStorage.getItem('actForMemberId')
            let actForMemberName = sessionStorage.getItem('actForMemberName')

            if (
                !actForMemberId ||
                !actForMemberName ||
                actForMemberId === userStore.user.cr_membernumber
            ) {
                userStore.setActForMember({
                    actForMemberId: undefined,
                    actForMemberName: '',
                })
            } else {
                userStore.setActForMember({
                    actForMemberId: actForMemberId,
                    actForMemberName: actForMemberName,
                })
            }
        },
        false,
    )

    window.addEventListener(
        'keycloakTokenUpdated',
        () => {
            // Update the state and the request interceptor
            // on keycloak token updates
            console.log('keycloakTokenUpdated')
            userStore.onKeycloakTokenUpdateSuccess(window.keycloak)
        },
        false,
    )
}

function clearError() {
    errorContext.value = null
}

// async function checkPermissionForMemberNavigation() {
//     const permission = hasUserRole(ROLES.UNTERNEHMENSNAVIGATION)
//     if (permission) {
//         const response = await getMembersForMe(true)
//         membersStore.cbraMember.memberId = response?.memberId || ''
//         if (response && response.members) {
//             const callFromSUMainMember = response.members?.mainMember?.callFromSUMainMember || false
//             const preparedMembers = prepareMemberListForMemberNavigation(response.members, callFromSUMainMember)
//             if (preparedMembers.length > 0) {
//                 membersStore.memberList = preparedMembers
//                 if (differentContextActive()) {
//                     preparedMembers.map((member) => {
//                         if (member.memberId === getMemberNavigationSession()) {
//                             if (member.reportAct4SbNumber) {
//                                 setReportAct4SbNumberSession(member.reportAct4SbNumber)
//                             } else {
//                                 removeReportAct4SbNumberSession()
//                             }
//                         }
//                     })
//                 }
//             }
//         }
//     } else {
//         if (getMemberNavigationSession()) {
//             removeMemberNavigationSession()
//             window.location.href = '/?revoked=true'
//         }
//     }
// }

onMounted(() => {
    const intervalId = setInterval(async() => {
        if (window.keycloak?.tokenParsed) {
            clearInterval(intervalId)
            await checkSso()
        }
    }, 1000)
})

async function checkSso() {
    if (
        window.keycloak?.tokenParsed &&
        !window.keycloak.tokenParsed.cr_mapping
    ) {
        console.log('No user mapping found. Checking login status with CBRA')
    } else {
        console.log('check sso: else case')
        try {
            console.log('Inside try check sso')
            await postCbraCheckLogin(
                window.keycloak?.tokenParsed,
                userStore.dateLocale.split('-')[0],
            )
        } catch (error) {
            console.log('Inside try catch check sso')
            console.error(error)
            if (isAxiosError(error)) {
                showError({
                    topic: 'UserService',
                    func: 'checkLogin',
                    xhr: error,
                })
            }
        }
    }
    await userStore.getUserPreferences()
    // keep as last action
    ssoChecked.value = true
}

defineExpose({ checkToken, clearError })
</script>

<style scoped lang="less">
:deep(.results-list--item) {
    font-size: 17px;

    .bg-white {
        padding: 20px;
    }

    .h4 {
        display: inline-block;
        margin-bottom: 0;
    }

    .transaction-title {
        font-size: 110%;
        margin-bottom: 8px;
    }

    .text-small {
        letter-spacing: 0.1px;
    }

    &.inactive {
        opacity: 0.5;
    }

    &:hover {
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
    }

    &:not(.inactive):hover {
        cursor: pointer;
    }

    .select-delete {
        .crefo-input [type="checkbox"]:checked + label:before,
        .crefo-input [type="checkbox"]:not(:checked) + label:before {
            width: 20px;
            height: 20px;
        }
        .crefo-input [type="checkbox"]:checked + label:after,
        .crefo-input [type="checkbox"]:not(:checked) + label:after {
            top: 4px;
            font-size: 16px;
        }
    }

    .data {
        div.text-small {
            margin-bottom: 2px;
        }

        .text-brand-grey {
            min-width: 120px;
            display: inline-block;
        }

        span[data-qa="myTransactions-searchResultList-monitoringEndTime"]
            > span:first-of-type {
            min-width: 123px; // needs 3px more to align with extra whitespace
            display: inline-block;
        }
    }

    .icon-delete {
        margin-top: 20px;
        padding-left: 2px;

        &.disabled {
            cursor: not-allowed;

            svg {
                opacity: 0.4;
            }
        }
    }

    .transaction_query_by {
        display: flex;
        margin-top: 5px;

        .icon {
            display: inline-flex;
            align-self: center;
        }

        .icon.baseline svg {
            position: relative;
        }

        svg {
            margin-right: 8px;
            height: 12px;
        }
    }

    .last-modification-time {
        color: var(--color-warning);
    }
}

:deep(.crefo-ui-alert) a {
    color: #fff;
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
</style>
