<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'transactions' }">
                    {{ $t('transactions.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('detailPerson.breadcrumb') }}</li>
        </Breadcrumb>

        <article>
            <div
                id="consumer-report-detail-page"
                class="business-data-transaction consumer-data-transaction"
            >
                <div
                    v-if="showLoadingSpinner"
                    class="contentMain bg-brand-light-grey"
                    style="height: 100vh"
                >
                    <div class="container ">
                        <div class="row flexbox flexbox-sm--column flexbox--column">
                            <div class="col-12 col-md-12">
                                <LoadingRing />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container ">
                    <div class="contentHead">
                        <div class="row">
                            <div class="col-12 col-md-7">
                                <div class="h1 contentHead__title">
                                    <font-awesome-icon
                                        :icon=" favourite ? 'fa-solid fa-star': 'fa-light fa-star' "
                                        size="sm"
                                        data-qa="consumer-report-favourite"
                                        class="cursor-pointer mr"
                                        @click="toggleFavouriteTransaction"
                                    />
                                    <span data-qa="consumer-report-header">{{ getName() }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-5 contentHead__metaData text-align-right">
                                <DownloadLink
                                    v-if="reportId && !isExpired"
                                    :url="ProductService.getConsumerReportPdf(reportId, transactionId)"
                                    data-qa="consumer-report-download-button"
                                    classes="btn btn-default"
                                >
                                    <font-awesome-icon
                                        icon="fa-sharp fa-regular fa-download"
                                        size="lg"
                                    />
                                    {{ $t('shared.download.report') }}
                                </DownloadLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="showAgeTeaser && !isExpired"
                    class="container "
                >
                    <div
                        class="crefo-ui-alert info"
                        data-qa="old-consumer-report-teaser"
                    >
                        <span class="legitimateInterest-container--short">{{ $t('transaction.consumer.old.report.teaser', {date: formatDate(creationTime, 'L')}) }}</span><br>
                        <a
                            :href="reportsLandingpageConsumerLinks[locale] + getOrderConsumerUri()"
                            data-qa="consumer-report-recheck-link"
                        >
                            {{ $t('transaction.consumer.report.recheck') }}
                        </a>
                    </div>
                </div>
                <div
                    v-if="isExpired"
                    class="container "
                >
                    <div
                        class="crefo-ui-alert info"
                        data-qa="expired-consumer-report-teaser"
                    >
                        <span class="legitimateInterest-container--short">{{ $t('transaction.consumer.expired.report.teaser', {date: formatDate(creationTime,'L')}) }}</span><br>
                        <a
                            :href="'/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen' + getOrderConsumerUri()"
                            data-qa="consumer-report-recheck-link"
                        >
                            {{ $t('transaction.consumer.report.recheck') }}

                        </a>
                    </div>
                </div>

                <div class="contentMain bg-brand-light-grey box-spacing">
                    <div class="container  business-header">
                        <div class="row">
                            <div
                                class="col-12 col-lg-4 mb"
                                data-qa="header-box-order-data"
                            >
                                <div class="box-shadow-xy small-box-spacing bg-white h-100">
                                    <h4>{{ $t('transaction.consumer.report.orderData') }}</h4>
                                    <div class="box--product-data__container">
                                        <div class="box--product-data__container--flex">
                                            <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('transaction.show.product') }}</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="consumer-report-product-type"
                                            >{{ reportProductType }}</span>
                                        </div>
                                        <div class="box--product-data__container--flex">
                                            <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('transaction.show.fetchDate') }}</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="consumer-report-order-date"
                                            >{{ formatDate(creationTime, 'L') }}</span>
                                        </div>
                                        <div class="box--product-data__container--flex">
                                            <span
                                                v-if="referenceNumber"
                                                class="box--header-data__label box--header-data__label--width-increased"
                                            >{{ $t('shared.reference') }}</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="consumer-report-reference-number"
                                            >{{ referenceNumber }}</span>
                                        </div>
                                        <div class="box--product-data__container--flex">
                                            <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('transaction.show.orderNumber') }}</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="consumer-report-order-number"
                                            >{{ productOrderId }}</span>
                                        </div>
                                        <div class="box--product-data__container--flex">
                                            <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('transaction.list.transaction_getby') }}</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="consumer-report-owner"
                                            >{{ owner }}</span>
                                        </div>
                                    </div>

                                    <div class="mt clearfix d-flex">
                                        <span class="box--header-data__label box--header-data__label--width-increased">
                                            {{ $t('shared.myNote') }}
                                        </span>
                                        <div
                                            class="box--header-data__value mr"
                                        >
                                            <span
                                                v-if="!editMode"
                                                data-qa="consumer-report-note-Text"
                                                v-html="helpers.nl2br(myNote)"
                                            />
                                            <Textarea
                                                v-if="editMode"
                                                v-model="myNote"
                                                label=""
                                                name="additionalInfos"
                                                data-qa="consumer-report-note-input"
                                                label-hidden
                                            />
                                        </div>
                                        <span
                                            v-if="!editMode"
                                            data-qa="consumer-report-note-button"
                                            class="business-data-transaction__editButton"
                                            @click="startEditMode"
                                        >
                                            <font-awesome-icon
                                                icon="fa-light fa-pencil"
                                            />
                                        </span>
                                        <span
                                            v-if="editMode"
                                            data-qa="consumer-report-note-button"
                                            class="business-data-transaction__editButton"
                                            @click="saveMyNote"
                                        >
                                            <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                                        </span>
                                    </div>

                                    <div
                                        v-if="!validation"
                                        class="validationMessage font-color--red"
                                        data-qa="consumer-report-validation-message"
                                    >
                                        {{ $t('transaction.report.validation.message') }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 col-lg-8 mb box--business-data"
                                data-qa="header-box-consumer-data"
                            >
                                <div class="box-shadow-xy small-box-spacing bg-white h-100">
                                    <h3>{{ $t('transaction.consumer.report.requestData') }}</h3>
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div class="box--business-data__address-wrapper d-flex gap">
                                                <font-awesome-icon
                                                    icon="fa-solid fa-location-dot"
                                                    class="location-icon"
                                                />
                                                <address class="box--business-data--address">
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-address-street"
                                                    >{{ street }}&nbsp;</span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-address-housenumber"
                                                    >{{ getHouseNumber() }}</span>
                                                    <br>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-address-zip-code"
                                                    >{{ zipCode }}&nbsp;</span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-address-city"
                                                    >{{ city }}</span>
                                                    <br>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-address-country"
                                                    >{{ country }}</span>
                                                </address>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="vspace--2">
                                                <div>
                                                    <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('shared.givenName') }}</span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-given-name"
                                                    >{{ givenName }}</span>
                                                </div>
                                                <div>
                                                    <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('shared.surname') }}</span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-surname"
                                                    >{{ surname }}</span>
                                                </div>
                                                <div>
                                                    <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('shared.birthName') }}</span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-birth-name"
                                                    >{{ birthName }}</span>
                                                </div>
                                                <div>
                                                    <span class="box--header-data__label box--header-data__label--width-increased">{{ $t('shared.birthDate') }}</span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="consumer-report-birth-date"
                                                    >{{ formatDate(birthDate, 'L') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        @on-close="modal.onClose"
        @on-confirm="modal.onConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Textarea from '@/components/Input/Textarea.vue'
import LoadingRing from '@/components/LoadingRing.vue'
import Modal from '@/components/Modal.vue'
import { reportsLandingpageConsumerLinks } from '@/data/typo3Links'
import { formatDate } from '@/filters'
import { countryByCode, getCountryByName } from '@/helper/countryCodes'
import helpers from '@/helper/misc'
import { showError } from '@/helper/services/error'
import { ProductService } from '@/helper/services/product'
import { TransactionService, saveNoteForTransaction, toggleFavourite } from '@/helper/services/transaction'
import { ConsumerReportWebTrekkService } from '@/helper/webtrekk/consumer'
import { CbraConsumerTransaction, CbraTransaction } from '@/types/cbra/transactions'
import { ModalProps } from '@/types/components/modal'
import { isAxiosError } from 'axios'
import dayjs from 'dayjs'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import DownloadLink from '@/components/DownloadLink.vue'

const id = 'portal.mc.detail-person'

const route = useRoute()
const { t, locale } = useI18n()

const transactionId = ref(route.query.transactionId ? String(route.query.transactionId) : '')
const reportId = ref(route.query.reportId ? String(route.query.reportId) : '')
const showPdfError = route.query.generatingPDF || false
const errorHeadline = ref()
const errorNote = ref()
const isExpired = ref(false)
const jsonData = ref()

// TransactionData
const crefoTransactionId = ref()
const favourite = ref(false)
const transactionType = ref()
const myNote = ref('')
const validation = computed(() => myNote.value.length <= 200)
const upgradeable = ref(false)
const owner = ref()

// ConsumerData
const productOrderId = ref()
const productType = ref()
const reportProductType = ref()
const referenceNumber = ref()
const creationTime = ref()
const salutation = ref()
const surname = ref()
const givenName = ref()
const birthName = ref()
const birthDate = ref()
const street = ref()
const houseNumber = ref()
const houseNumberSuffix = ref()
const zipCode = ref()
const city = ref()
const country = ref()
const showAgeTeaser = ref(false)

const editMode = ref(false)
const showLoadingSpinner = ref(false)

const modal = ref<ModalProps>()

onMounted(loadTransaction)
onMounted(() => {
    if (showPdfError) {
        const contactPageUrl = '/kontakt'
        errorHeadline.value = t('reportperson.error.getProductPdfFailed.headline')
        errorNote.value = t('reportperson.error.getProductPdfFailed.note', { link: '<a href="' + contactPageUrl + '">' + t('reportperson.error.contactName') + '</a>' })
        modal.value = {
            id: 'consumer-report-pdf-error',
            title: errorHeadline.value,
            content: errorNote.value,
            icon: 'error',
            dataQa: 'consumer-report-pdf-error',
            confirmText: t('shared.modals.ok'),
            onClose: () => modal.value = undefined,
            onConfirm: () => modal.value = undefined

        }
        ConsumerReportWebTrekkService.placeNewConsumerReportDownloadFailed(getWebTrekkProductName() !== '' ? getWebTrekkProductName() : 'unknown', errorHeadline.value)
    } else {
        ConsumerReportWebTrekkService.placeNewConsumerReportView(getWebTrekkProductName())
    }
})

function getHouseNumber() {
    return houseNumber.value + (houseNumberSuffix.value ? ' ' + houseNumberSuffix.value : '')
}

function getName() {
    return (givenName.value ? givenName.value + ' ' : '') + surname.value
}

function saveMyNote() {
    if (validation.value) {
        editMode.value = false
        saveNoteForTransaction(myNote.value, transactionId.value)
    }
}

function startEditMode() {
    editMode.value = true
}

function toggleFavouriteTransaction() {
    favourite.value = !favourite.value
    const transactionData = {
        crefoTransactionId: Number(transactionId.value),
        favourite: favourite.value
    }
    return toggleFavourite(transactionData)
}

function getOrderConsumerUri() {
    let prefix = '?'
    let uri = ''
    const countryOption = getCountryByName(t, country.value)
    let countryCode = ''
    if (countryOption) {
        countryCode = countryOption.code
    }
    const params = [
        { name: 'salutation', value: salutation.value === 'HERR' ? 'MR' : salutation.value === 'FRAU' ? 'MS' : 'MR_MS' },
        { name: 'surname', value: surname.value || '' },
        { name: 'firstname', value: givenName.value || '' },
        { name: 'birthname', value: birthName.value || '' },
        { name: 'birthdate', value: birthDate.value ? dayjs(birthDate.value).format('L') : '' },
        { name: 'street', value: street.value || '' },
        { name: 'housenumber', value: getHouseNumber() || '' },
        { name: 'zipCode', value: zipCode.value || '' },
        { name: 'country', value: countryCode || '' },
        { name: 'city', value: city.value || '' },
    ]

    uri = prefix
    params.forEach((param, idx) => {
        if (idx > 0) { uri += '&' }
        uri += param.name + '=' + encodeURIComponent(param.value)
    })
    return uri
}

async function loadTransaction() {
    if (transactionId.value) {
        showLoadingSpinner.value = true

        try {
            const { data } = await TransactionService.getConsumerTransaction(transactionId.value)
            updateTransactionData(data)
            if (data.consumerTransaction) {
                updateCosnumerData(data.consumerTransaction)
            }
            getConsumerTransactionJSON()
        } catch (error) {
            if (isAxiosError(error)) {
                const modalprops = showError({ topic: 'TransactionService', xhr: error })
                if (modalprops) {
                    modalprops.onConfirm = () => modal.value = undefined
                    modalprops.onClose = () => modal.value = undefined
                    modal.value = modalprops
                }
            }
        }
        showLoadingSpinner.value = false
    }
}

function updateTransactionData(jsonData: CbraTransaction) {
    transactionId.value = String(jsonData.crefoTransactionId)
    crefoTransactionId.value = jsonData.crefoTransactionId
    reportId.value = jsonData.reportId
    creationTime.value = jsonData.consumerTransaction?.creationTime
    favourite.value = jsonData.favourite
    transactionType.value = jsonData.transactionType
    myNote.value = jsonData.note ?? ''
    upgradeable.value = jsonData.upgradeable
    owner.value = jsonData.owner
}

function updateCosnumerData(jsonData: CbraConsumerTransaction) {
    productOrderId.value = jsonData.productOrderId
    productType.value = jsonData.type
    reportProductType.value = jsonData.reportProductType
    if (reportProductType.value) {
        reportProductType.value = t('reportperson.form.product.' + reportProductType.value)
    }
    referenceNumber.value = jsonData.referenceNumber
    salutation.value = jsonData?.salutation
    surname.value = jsonData.surname
    givenName.value = jsonData.givenName
    birthName.value = jsonData.birthName
    birthDate.value = jsonData.birthDate
    street.value = jsonData.postalAddress?.street
    houseNumber.value = jsonData.postalAddress?.houseNumber
    houseNumberSuffix.value = jsonData.postalAddress?.houseNumberSuffix
    zipCode.value = jsonData.postalAddress?.zipCode
    city.value = jsonData.postalAddress?.city
    if (jsonData.postalAddress && jsonData.postalAddress.country) {
        const countryOption = countryByCode(jsonData.postalAddress.country)
        jsonData.postalAddress.country = countryOption?.i18nKey ? t(countryOption.i18nKey) : jsonData.postalAddress.country
    }
    country.value = jsonData.postalAddress?.country
    showAgeTeaser.value = jsonData.showAgeTeaser
}

async function getConsumerTransactionJSON() {
    try {
        const { data } = await ProductService.getConsumerReportJSON(reportId.value)
        jsonData.value = data
    } catch (error) {
        if (isAxiosError(error)) {
            if (error.response?.status === 404) isExpired.value = true
        }
    }
}

function getWebTrekkProductName() {
    const productType = reportProductType.value
    if (typeof productType !== 'string' || productType.length === 0) {
        return ''
    } else {
        return t('reportperson.form.product.' + productType + '.webTrekk')
    }
}

</script>

<style scoped lang="less">
@perfect-white: #ffffff;
@cerulean: #008dd9;
@screen-sm-max: 991px;
@lipstick: #e05469;
@light-navy: #183f6d;
@lime-dark-green: #35b235;

.business-data-transaction, .family-tree-transaction {
  min-height: 400px;

  @media (max-width: @screen-sm-max) {
    .box--sm-space {
      .col-12:nth-child(n+2) {
        margin-top: 20px;
      }
    }
  }

  &__editButton {
    color: @cerulean;
    border-radius: 5px;
    border: 1px solid @cerulean;
    min-width: 25px;
    height: 25px;
    display: block;
    padding: 2px 0 0 5px;
    cursor: pointer;
    margin-left: auto;

    &:hover {
      color: @perfect-white;
      background-color: @cerulean;
    }
  }
  .business-header {
    h3 {
      margin-bottom: 1.5rem;
    }

    .box--business-data,
    .box--business-data {
      h3 {
        padding-right: 4.5rem;
      }
    }

    .box--product-data {

      &__container {
        border-bottom: 1px solid #cdd4db;
        margin-bottom: 17px;
        padding-bottom: 1rem;

        &--flex {
          display: flex;
        }
      }

      &__monitoring-info {
        .box--product-data__label {
          width: 91px;
        }

        .box--product-header__value {
          margin-left: 92px;
          &--normal {
            font-weight: normal;
          }
        }

        .icon {
          margin-top: 0;
        }
      }
    }

    .box--header-data {
      &__label {
        line-height: 1.5rem;
        width: 120px;
        display: inline-block;
        overflow: auto;

        &--width-increased {
          width: 130px;
          min-width: 130px;
        }
      }
      &__value.font-color--red {
        color: @lipstick;
      }

      &__value {
        line-height: 1.5rem;
        width: auto;
        max-width: 100%;
        overflow: auto;
        color: @light-navy;
        font-weight: 600;
        display: inline-block;
        word-break: break-all;
        &--important {
          color: @lipstick;
        }
        &--ellipsis {
          /* display: inline-flex;
          white-space: nowrap;
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
          } */
          white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }
      }
    }
  }

  .font-color--red {
    color: @lipstick;
  }

  .font-color--green {
    color: @lime-dark-green;
  }

  .box--business-data {

    .box--business-data__address-wrapper {
      address {
        display: inline-block;
        line-height: 2.5rem;
      }

      .icon--place {
        margin-top: 5px;
      }
    }
  }

  .product-type-wrapper {
    .icon {
      float: left;
    }

    div {
      margin-left: 62px;
    }
  }

  .box--product-data > h4 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .box--product-data .box--product-data__container {
    padding-bottom: 1.8rem;

    h4 {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.location-icon {
    margin-top: 5px;
}

</style>
