export type ReportProduct = {
    label: string
    code: string
    i18nKey: string
    isAvailableOnlyForDALCountries?: boolean
}

const reportProducts: Array<ReportProduct> = [
    {
        label: '',
        code: 'PREMIUM_REPORT',
        i18nKey: 'enums.reportProducts.PREMIUM_REPORT',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'COMMERCIAL_REPORT',
        i18nKey: 'enums.reportProducts.COMMERCIAL_REPORT'
    },
    {
        label: '',
        code: 'COMPACT_REPORT',
        i18nKey: 'enums.reportProducts.COMPACT_REPORT'
    },
    {
        label: '',
        code: 'BRIEF_REPORT',
        i18nKey: 'enums.reportProducts.BRIEF_REPORT'
    },
    {
        label: '',
        code: 'TRAFFIC_LIGHT_REPORT',
        i18nKey: 'enums.reportProducts.TRAFFIC_LIGHT_REPORT',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'RISIKO_CHECK',
        i18nKey: 'enums.reportProducts.RISIKO_CHECK',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'ESG_BASIS_REPORT',
        i18nKey: 'enums.reportProducts.ESG_BASIS_REPORT',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'COMPANY_REPORT_COMPACT',
        i18nKey: 'enums.reportProducts.COMPANY_REPORT_COMPACT'
    },
    {
        label: '',
        code: 'COMPANY_REPORT_PREMIUM',
        i18nKey: 'enums.reportProducts.COMPANY_REPORT_PREMIUM',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'FAMILYTREE_PLUS',
        i18nKey: 'enums.reportProducts.FAMILYTREE_PLUS',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'CREFO_IDENT',
        i18nKey: 'enums.reportProducts.CREFO_IDENT',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'REPORT_WITHOUT_SUPPLEMENT',
        i18nKey: 'enums.reportProducts.REPORT_WITHOUT_SUPPLEMENT',
        isAvailableOnlyForDALCountries: true
    },
    {
        label: '',
        code: 'COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT',
        i18nKey: 'enums.reportProducts.COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT'
    },
    {
        label: '',
        code: 'COMPANY_REPORT_PREMIUM_MONITORING_WITHOUT_REPORT',
        i18nKey: 'enums.reportProducts.COMPANY_REPORT_PREMIUM_MONITORING_WITHOUT_REPORT',
        isAvailableOnlyForDALCountries: true
    }
]

export default reportProducts
