<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useI18n } from 'vue-i18n'
import Tab from '@/components/Tabs/Tab.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import { computed } from 'vue'

const { locale } = useI18n()
const isEnglish = computed(() => {
    return locale.value === 'en-US'
})

const id = 'portal.mc.about-creditreform.data-sources'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'about-creditreform' }">
                    {{ $t('menu.servicemenu.about.title') }}
                </router-link>
            </li>
            <li>{{ $t('help.creditReports.breadcrumbs.dataSources') }}</li>
        </Breadcrumb>

        <div
            v-if="!isEnglish"
            id="c564"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-564"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c611"
                            class="ce-box   box"
                            data-qa="ce-611"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--above">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.1.title') }}</h3>
                                </div>
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/about-creditreform/eisberg_komplett.png"
                                                    width="2037"
                                                    height="1633"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ce-textpic__bodytext" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c578"
            class=" clearfix grid-box grid-box--stageLeft box box--pearl"
            data-qa="grid-box-578"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c565"
                            class="ce-box   box"
                            data-qa="ce-565"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.2.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>
                                        {{ $t('DataSources.sections.2.ps.1') }}
                                    </p>
                                    <p>{{ $t('DataSources.sections.2.ps.2') }}</p>
                                    <ul
                                        v-if="!isEnglish"
                                        class="list-unordered"
                                    >
                                        <li>{{ $t('DataSources.sections.2.ul.lis.1') }}</li>
                                        <li>{{ $t('DataSources.sections.2.ul.lis.2') }}</li>
                                        <li>{{ $t('DataSources.sections.2.ul.lis.3') }}</li>
                                    </ul>
                                    <p>{{ $t('DataSources.sections.2.ps.3') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c610"
            class=" clearfix grid-box grid-box--stageLeft box tabs-div"
            data-qa="grid-box-610"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <Tabs
                            :id="id + '.anchor-links'"
                            class="mt-large"
                            selected-tab="tab-0"
                        >
                            <Tab
                                id="tab-0"
                                :title="$t('DataSources.sections.3.tabs.1.title')"
                            >
                                <p>{{ $t('DataSources.sections.3.tabs.1.text') }}</p>
                            </Tab>
                            <Tab
                                id="tab-1"
                                :title="$t('DataSources.sections.3.tabs.2.title')"
                            >
                                <p>{{ $t('DataSources.sections.3.tabs.2.ps.1') }}</p>
                                <p>{{ $t('DataSources.sections.3.tabs.2.ps.2') }}</p>
                            </Tab>
                            <Tab
                                id="tab-2"
                                :title="$t('DataSources.sections.3.tabs.3.title')"
                            >
                                <p>{{ $t('DataSources.sections.3.tabs.3.ps.1') }}</p>
                                <p>{{ $t('DataSources.sections.3.tabs.3.ps.2') }}</p>
                            </Tab>
                            <Tab
                                id="tab-3"
                                :title="$t('DataSources.sections.3.tabs.4.title')"
                            >
                                <p>{{ $t('DataSources.sections.3.tabs.4.ps.1') }}</p>
                                <p>{{ $t('DataSources.sections.3.tabs.4.ps.2') }}</p>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c568"
            class=" clearfix grid-box grid-box--stageLeft box box--pearl"
            data-qa="grid-box-568"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c585"
                            class="ce-box   box"
                            data-qa="ce-585"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.4.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('DataSources.sections.4.ps.1') }}</p>
                                    <i18n-t
                                        tag="p"
                                        keypath="DataSources.sections.4.ps.2"
                                    >
                                        <i>{{ $t('DataSources.sections.4.i') }}</i>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c566"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-566"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c584"
                            class="ce-box   box"
                            data-qa="ce-584"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.5.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>
                                        {{ $t('DataSources.sections.5.ps.1') }}
                                    </p>
                                    <p>
                                        {{ $t('DataSources.sections.5.ps.2') }}
                                    </p>
                                    <p>{{ $t('DataSources.sections.5.ps.3') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c562"
            class=" clearfix grid-box grid-box--stageLeft box box--pearl"
            data-qa="grid-box-562"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c563"
                            class="ce-box   box"
                            data-qa="ce-563"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.6.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('DataSources.sections.6.text') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c899"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-899"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c898"
                            class="ce-box   box"
                            data-qa="ce-898"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.7.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        tag="p"
                                        keypath="DataSources.sections.7.text"
                                    >
                                        <router-link
                                            :to="{ name: 'reasons-for-creditreform' }"
                                            title="10 Gründe für Creditreform"
                                            class="internal-link"
                                        >
                                            {{ $t('DataSources.sections.7.link') }}
                                        </router-link>!
                                    </i18n-t>
                                </div>
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-outer">
                                        <div class="ce-inner">
                                            <div class="ce-textpic__row">
                                                <div class="ce-textpic__column">
                                                    <div class="ce-textpic__media">
                                                        <router-link :to="{ name: 'reasons-for-creditreform' }">
                                                            <img
                                                                src="@/assets/img/icons/10_reasons_for_creditreform_banner.png"
                                                                width="1260"
                                                                height="150"
                                                                alt=""
                                                            >
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="c900"
            class=" clearfix grid-box grid-box--3cols box box--pearl"
            data-qa="grid-box-900"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div
                            id="c901"
                            class="ce-box  box box--white"
                            data-qa="ce-901"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.8.boxes.1.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        tag="p"
                                        keypath="DataSources.sections.8.boxes.1.ps.1"
                                    >
                                        <b>{{ $t('DataSources.sections.8.boxes.1.bs.1') }}</b>
                                    </i18n-t>
                                    <i18n-t
                                        tag="p"
                                        keypath="DataSources.sections.8.boxes.1.ps.2"
                                    >
                                        <b>{{ $t('DataSources.sections.8.boxes.1.bs.2') }}</b>
                                    </i18n-t>
                                    <i18n-t
                                        tag="p"
                                        keypath="DataSources.sections.8.boxes.1.ps.3"
                                    >
                                        <b>{{ $t('DataSources.sections.8.boxes.1.bs.3') }}</b>
                                    </i18n-t>
                                    <i18n-t
                                        tag="p"
                                        keypath="DataSources.sections.8.boxes.1.ps.4"
                                    >
                                        <b>{{ $t('DataSources.sections.8.boxes.1.bs.4') }}</b>
                                    </i18n-t>
                                    <i18n-t
                                        tag="p"
                                        keypath="DataSources.sections.8.boxes.1.ps.5"
                                    >
                                        <b>{{ $t('DataSources.sections.8.boxes.1.bs.5') }}</b>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div
                            id="c902"
                            class="ce-box  box box--white"
                            data-qa="ce-902"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.8.boxes.2.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('DataSources.sections.8.boxes.2.text') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div
                            id="c903"
                            class="ce-box  box box--white"
                            data-qa="ce-903"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.8.boxes.3.title') }}</h3>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('DataSources.sections.8.boxes.3.text') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!isEnglish"
            id="c622"
            class=" clearfix grid-box grid-box--stageLeft box box--white"
            data-qa="grid-box-622"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c621"
                            class="ce-box  box box--white"
                            data-qa="ce-621"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('DataSources.sections.9.title') }}</h3>
                                </div>
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/about-creditreform/qualitaetsverbesserer.jpeg"
                                                    width="800"
                                                    height="600"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ce-textpic__bodytext">
                                    <p>
                                        {{ $t('DataSources.sections.9.ps.1') }}
                                    </p>
                                    <p>
                                        {{ $t('DataSources.sections.9.ps.2') }}
                                    </p>
                                    <p>{{ $t('DataSources.sections.9.ps.3') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
}
.grid-box {
    display: block;
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--center {
    .ce-textpic__gallery {
        text-align: center;
    }
    .ce-textpic__media {
        padding: 20px;
        iframe {
            max-width: 100%;
            height: auto;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--above {
    .ce-textpic__gallery {
        float: none;
        max-width: 100%;
    }
    .ce-textpic__media {
        float: none;
        display: block;
        padding: 20px 0;
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

.box--pearl {
    background-color: #edece5;
}
.box--white {
    background-color: #fff;
}

b {
    color: var(--color-c-s3);
}

.tabs-div {
    padding-top: 82.5px;
}
</style>
