<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>{{ $t('activateAccess.breadcrumb') }}</li>
        </Breadcrumb>

        <Hero
            :image="HeroImage"
            :mobile-image="MobileImage"
        >
            <div class="row mb-large">
                <div class="col-12 col-md-12 col-xl-12">
                    <h1 class="p0 marketing">
                        <span>{{ $t('activateAccess.title') }}</span>
                    </h1>
                </div>
                <div class="col-12 col-md-12 col-xl-12">
                    <h2 class="p0">
                        {{ $t('activateAccess.subline') }}
                    </h2>
                </div>
                <div class="col-12 col-md-12 col-xl-12 mb-large">
                    <button
                        data-qa="ce-header-"
                        class="btn btn-default"
                        @click.prevent="redirectToMemberRegistration"
                    >
                        {{ $t('activateAccess.buttonText') }}
                    </button>
                </div>
            </div>
        </Hero>

        <div class="container content-spacer">
            <div class="row mb">
                <div class="col-12 col-md-8 offset-md-2">
                    <h3>{{ $t("activateAccess.headline") }}</h3>
                    <p class="mb-small">
                        {{ $t("activateAccess.text") }}
                    </p>
                    <div class="offset-md-1">
                        <ul class="list-unordered">
                            <li>{{ $t("activateAccess.firstItem") }}</li>
                            <li>{{ $t("activateAccess.secondItem") }}</li>
                            <li>{{ $t("activateAccess.thirdItem") }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <Register />

        <div class="container mt-4">
            <div class="row mb">
                <div class="col-12 col-md-3 offset-md-2">
                    <div class="card">
                        <picture>
                            <img
                                src="@/assets/img/laptop-dashboard.png"
                                alt=""
                            >
                        </picture>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title">
                                {{ $t("activateAccess.footerHeadline") }}
                            </h3>
                            <p class="card-text">
                                <em>
                                    {{ $t("activateAccess.myCrefo") }}
                                </em>
                                {{ $t("activateAccess.footerText") }}
                            </p>
                            <a href="https://www.creditreform.de/mitgliedschaft/mitglied-werden">{{ $t("activateAccess.footerLocal") }}</a>
                            <span>{{ $t("activateAccess.footerTextEnd") }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8 offset-md-2 mt">
                    <span>{{ $t("activateAccess.iconText") }}</span>
                    <a href="/ueber-creditreform/10-gruende-fuer-creditreform/">{{ $t("activateAccess.footerTen") }}</a>
                    <span>{{ $t("activateAccess.iconTextEnd") }}</span>
                </div>
                <div class="col-12 mb">
                    <a href="/ueber-creditreform/10-gruende-fuer-creditreform/">
                        <picture>
                            <img
                                src="@/assets/img/banner_gruende.png"
                                alt=""
                                class="img-fluid mt offset-md-2 col-md-8 "
                                style=""
                            >
                        </picture>
                    </a>
                </div>
            </div>
        </div>

        <Contact />
    </div>
</template>

<script setup>
import { onMounted, onUpdated, watch } from 'vue'
// import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'

import Breadcrumb from '@/components/Breadcrumb.vue'
import Hero from '@/components/Hero.vue'
import Contact from '@/components/Teaser/Contact.vue'
import Register from '@/components/Teaser/Register.vue'

import HeroImage from '@/assets/img/csm_startseite-frau-umsteigen_e622f2a2e8.jpeg'
import MobileImage from '@/assets/img/frau-mobile-klein.png'

// const router = useRouter()
const userStore = useUserStore()

const selfcareBaseUrl = import.meta.env.VITE_APP_USER_MGMT_BASEURL

const redirectToMemberRegistration = () => {
    location.assign(`${selfcareBaseUrl}/registrieren/mitglied?prk=MC`)
}

const redirectAuthenticatedUserToDashboard = () => {
    if (userStore.authenticated) {
        // router.push({ name: 'dashboard' })
        location.assign('/?login=1')
    }
}

onMounted(redirectAuthenticatedUserToDashboard)
onUpdated(redirectAuthenticatedUserToDashboard)

watch(
    () => userStore.authenticated,
    () => {
        redirectAuthenticatedUserToDashboard()
    }
)

const id = 'portal.mc.activate-access'
</script>
