<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.risk-management'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb>
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('helpRiskManagement.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c6140"
                        class="box--sticky-container"
                        data-qa="ce-6140"
                    >
                        <div
                            id="toc6140"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-6168"
                                        class="toc__"
                                        href="/hilfe/effektives-risikomanagement/#c6168"
                                        @click.prevent="scrollTo('#c6168')"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6146"
                                                href="/hilfe/effektives-risikomanagement/#c6146"
                                                @click.prevent="scrollTo('#c6146')"
                                            >
                                                {{ $t('helpRiskManagement.sections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6148"
                                                href="/hilfe/effektives-risikomanagement/#c6148"
                                                @click.prevent="scrollTo('#c6148')"
                                            >
                                                {{ $t('helpRiskManagement.sections.2.title') }}

                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6150"
                                                href="/hilfe/effektives-risikomanagement/#c6150"
                                                @click.prevent="scrollTo('#c6150')"
                                            >
                                                {{ $t('helpRiskManagement.sections.3.title') }}

                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6218"
                                                href="/hilfe/effektives-risikomanagement/#c6218"
                                                @click.prevent="scrollTo('#c6218')"
                                            />
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6152"
                                                href="/hilfe/effektives-risikomanagement/#c6152"
                                                @click.prevent="scrollTo('#c6152')"
                                            >
                                                {{ $t('helpRiskManagement.sections.4.title') }}

                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-6220"
                                                href="/hilfe/effektives-risikomanagement/#c6220"
                                                @click.prevent="scrollTo('#c6220')"
                                            >
                                                {{ $t('helpRiskManagement.sections.5.title') }}

                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-6140"
                                        class="toc__"
                                        href="/hilfe/effektives-risikomanagement/#c6140"
                                        @click.prevent="scrollTo('#c6140')"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c6168"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-6168"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <div
                                            id="c6146"
                                            class="ce-box   box"
                                            data-qa="ce-6146"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3> {{ $t('helpRiskManagement.sections.1.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/risk-management/Frau_Buero_Fenster.png"
                                                                    width="305"
                                                                    height="305"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-html="$t('helpRiskManagement.sections.1.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c6148"
                                            class="ce-box   box"
                                            data-qa="ce-6148"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3> {{ $t('helpRiskManagement.sections.2.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpRiskManagement.sections.2.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c6150"
                                            class="ce-box   box"
                                            data-qa="ce-6150"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3> {{ $t('helpRiskManagement.sections.3.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/risk-management/Branchen-Region.png"
                                                                    width="1400"
                                                                    height="868"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-html="$t('helpRiskManagement.sections.3.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c6218"
                                            class="ce-box   box"
                                            data-qa="ce-6218"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/risk-management/risikoueberblick_portfolioanalyse.png"
                                                                            width="1400"
                                                                            height="426"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-html="$t('helpRiskManagement.sections.3.text2')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c6152"
                                            class="ce-box   box"
                                            data-qa="ce-6152"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3> {{ $t('helpRiskManagement.sections.4.title') }}</h3>
                                                </div>
                                                <div v-html="$t('helpRiskManagement.sections.4.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c6220"
                                            class="ce-box   box"
                                            data-qa="ce-6220"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3> {{ $t('helpRiskManagement.sections.5.title') }}</h3>
                                                </div>
                                                <div v-html="$t('helpRiskManagement.sections.5.text')" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic {
    display: block;
    overflow: hidden;
    &.ce-textpic--right {
        .ce-textpic__gallery {
            float: right;
            .ce-textpic__media {
                float: right;
                padding-left: 20px;
            }
        }
    }
    &.ce-textpic--right {
        &.ce-textpic--intext .ce-textpic__media {
            max-width: 100%;
        }
        .ce-textpic__gallery {
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
            .ce-textpic__media {
                padding-bottom: 20px;
                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
            }
        }
    }
    &.ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;
            .ce-textpic__media {
                padding: 20px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>
